import { getItem } from '../../service/LocalStorage';
import jwtDecode from 'jwt-decode';
import ShowMedia from "../media/showMedia";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getHourMinuteOfNow } from "../../service/Date";
import { useContext, useEffect, useState } from 'react';
import LightMode from '../../context/LightMode';
import axios from 'axios';
import TextWithUrlDetection from '../../component/text/textWithUrlDetection';

const {REACT_APP_API_URL} = process.env;

export default function ShowMessage({message}) {
    const {t} = useTranslation();
    const { isLightMode, setIsLightMode } = useContext(LightMode);
    const [srcCheckImg, setSrcCheckImg] = useState("/assets/images/check.svg");
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    let userToken = getItem('userToken');
    let currentUserId = null;
    if(userToken) {
        currentUserId = jwtDecode(userToken).user_id;
    }

    let showAvatar = false;
    let liClassName = "chat-right";
    let divChatTextClassName = "chat-text chat-text-right";

    if(currentUserId != message.user.id) {
        showAvatar = true;
        liClassName = "chat-left";
        divChatTextClassName = "chat-text chat-text-left";
    }

    useEffect(() => {     
        if(isLightMode) {
            setSrcCheckImg("/assets/images/check-dark.svg");
        } else {
            setSrcCheckImg("/assets/images/check.svg");
        }
    }, [isLightMode]);

    const confirmDeleteMessage = async () => {
        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        axios.delete(`${REACT_APP_API_URL}/api/messages/${message.id}/delete`, config)
            .then (response => {
                if(response.status == 204) {
                    setIsDeleted(true);
                }
            }).catch(err => {

            })
    }

    return (
        <>
        {isDeleted ? null : (
            <>
            {showAvatar ? (
                <div className="chat-avatar">
                        <Link to={`/profile/${message.user.username}`} >
                            <img 
                                src={message.user.publicPathCropedProfilePicture} 
                                alt={message.user.username} 
                                className="profile-img"
                            />
                        </Link>
                    </div>
            ) : null}
            <div className={divChatTextClassName}>
                <span className="span-chat-content text_wrap"><TextWithUrlDetection text={message.content} /></span>                         
                <br/>
                {message.media.map((media, i) => (        
                    <div key={`chat_msg_media_${media.id}`}>
                        <ShowMedia 
                            media={media}
                            path={media.publicPath}
                            styleObject={{maxWidth: "300px", marginTop: "10px"}}
                        />
                    </div>
                ))}
                <br/>
                <span className="span-chat-text">
                {deleteMessage ? (
                    <>
                        {showAvatar ? null : (
                            <>
                                <a  style={{cursor: "pointer", color: "#f24570", fontWeight: "bold"}} onClick={() => confirmDeleteMessage()}>&nbsp;{t('confirm')}&nbsp;</a>
                                <a  style={{cursor: "pointer", fontWeight: "bold"}} onClick={() => setDeleteMessage(false)} >&nbsp;{t('cancel')}&nbsp;</a>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        &nbsp;
                        {showAvatar ? null : (
                            <>
                                {message.isRead || message.isLastAsRead ? (
                                    <img src="/assets/images/double-check.svg" alt="send" style={{width:"20px"}} />
                                ) : (                                
                                    <img src={srcCheckImg} alt="send" style={{width:"15px"}} />
                                )}
                            </>
                        )}
                        &nbsp;

                        {message.creationDateInArray.str_val === "now" ? (getHourMinuteOfNow()) : (
                            <>{message.creationDateInArray.int_val}&nbsp;{t(message.creationDateInArray.str_val)}</>
                        )}

                        {showAvatar ? null : (
                            <>
                                <a style={{cursor: "pointer"}} onClick={() => setDeleteMessage(true)} >&nbsp;<i className="icofont-trash"></i></a>
                            </>
                        )}
                    </>
                )}
                </span>

            </div>
            </>
        )}
        </>
    );
    
}
 