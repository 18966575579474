import { useNavigate } from "react-router-dom";

const {REACT_APP_API_URL} = process.env;

function Pagination({ 
    term,
    currentPageName,
    totalPages,
    currentPage,
    setUsersCollection,
    gender,
    ageFrom,
    ageTo,
    country,
    city,
    orderField,
    emptyCollection,
    setSpinner,
    apiUrl,
    apiHeaders,
    cityName, 
    languages
}) {
    const navigate = useNavigate();

    let range = [];
    for(let page = currentPage - 1; page <= currentPage + 1; page++)
        range.push(page);
    range = range.filter(page=> page > 0 && page <= totalPages);
    if(range[0]!=1){
    if(range[1]!=2){
        range.unshift('...');
    }
    range.unshift(1);
    }

    if(range[range.length-1] != totalPages){
      if(range[range.length-2]!= totalPages-1){
        range.push('...');
      }  
      range.push(totalPages);
    }

    const navToPage = async (newVal) => {
        let path = "";
        if(currentPageName && currentPageName === "searchAllMembers") {
            path = `term=${term}&page=${newVal}`;
            navigate(`/search-all-members?${path}`);
        } else {
            path = `gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&city=${city}&cityName=${cityName}&order_field=${orderField}&page=${newVal}${languages}`;
            navigate(`/members-classic-mode?${path}`);
        }
        
        setUsersCollection(emptyCollection);
        setSpinner(1);
        try {
            const response = await fetch(`${REACT_APP_API_URL}${apiUrl}?${path}`, 
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            const data = await response.json();

            setSpinner(0);
            setUsersCollection(data);
        } catch (err) {} 
      };


    return (
        <>
            {totalPages > 1 ? (
                <div className="paginations">
                    <ul className="lab-ul d-flex flex-wrap justify-content-center mb-1">
                        {range.map((value, key) => (                
                            ( value === '...' ? (
                                <li key={`li_pagination_${key}`}>
                                    <a key={`a_pagination_${key}`} >{value}</a>
                                </li>
                            ) : (
                                (currentPage === value ? (
                                        <li key={`li_pagination_${key}`}>
                                            <a key={`a_pagination_${key}`} style={{background: "#f24570"}}>{value}</a>
                                        </li>
                                    ) : (
                                        <li key={`li_pagination_${key}`} onClick={() => {navToPage(value);}} style={{cursor: "pointer"}}>
                                            <a key={`a_pagination_${key}`} >{value}</a>
                                        </li>
                                    )
                                )
                            
                            ))
                        ))}
                    </ul>            
                </div>
            ) : null}
        </>
    );
}
 
export default Pagination;