import FormPostActivity from './activity/formPostActivity';
import { useCallback, useRef, useState } from 'react';
import { getItem } from '../../service/LocalStorage';
import jwtDecode from 'jwt-decode';
import ActivityBody from './activity/activityBody';
import { useTranslation } from 'react-i18next';
import { mergeAndRemovingDuplicate } from "../../service/ArrayMerge";
import ActivityShowLikes from './activity/showLikes';
import SignalActivity from './activity/signal';
import CarouselReels from '../reel/carousel';
import TevifSpinner from '../../component/spinner/tevifSpinner';
import CommentShowLikes from './activity/showCommentLikes';

const {REACT_APP_API_URL} = process.env;

const ACTIVITIES_LIMIT = 3; 

function Activity({
    userProfile, 
    activities, 
    setActivities, 
    setMedias,
    isOwner,
    isAuthenticated,
    currentPage
}) {
    let userToken = getItem('userToken');
    let currentUserIdentifier = null
    if(userToken) {
        currentUserIdentifier = jwtDecode(userToken).username;
    }

    const [hasMore, setHasMore] = useState(true);
    const [spinner,setSpinner] = useState(false);
    const [page, setPage] = useState(2);

    const [btnPhoto, setBtnPhoto] = useState("");
    const [btnVideo, setBtnVideo] = useState("");
    const [btnText, setBtnText] = useState("");
    const [btnFollow, setBtnFollow] = useState(0);
    
    const updateSelectedBtn = (type) => { 
        setSpinner(true);
        setActivities([]);
        setPage(2);
        setHasMore(true);
        let data = {
            "text": btnText, 
            "video": btnVideo,
            "photo": btnPhoto,
            "follow": btnFollow
        };

        if(type === "photo") {
            if(btnPhoto) {
                data.photo = "";
                setBtnPhoto("");
            } else {
                setBtnPhoto("photo");
                data.photo = "photo";
            }
        }
        
        if(type === "video") {
            if(btnVideo) {
                data.video = "";
                setBtnVideo("");
            } else {
                setBtnVideo("video");
                data.video = "video";
            }
        }

        if(type === "text") {
            if(btnText) {
                data.text = "";
                setBtnText("");
            } else {
                setBtnText("text");
                data.text = "text";
            }
        }

        if(type === "follow") {
            if(btnFollow) {
                data.follow = 0;
                setBtnFollow(0);
            } else {
                setBtnFollow(1);
                data.follow = 1;
            }
        }
        
        let url = `${REACT_APP_API_URL}/api/activities/news?limit=${ACTIVITIES_LIMIT}&mediaTypes[]=${data.photo}&mediaTypes[]=${data.video}&mediaTypes[]=${data.text}&followers=${data.follow}`;
        
        fetch(url, {
            method: "GET",
            headers: { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            }
        })
        .then(response => response.json())
        .then((result) => {
            if(result.items.length > 0) {
                setActivities((oldActivities) => [...oldActivities, ...result.items]);
            }

            if(result.items.length < ACTIVITIES_LIMIT) {
                setHasMore(false);
            }

            setSpinner(false);
        });
    }

    const getActivities = () => {
        setSpinner(true);
        let url = "";
        if(currentPage === "newActivity") {
            url = `${REACT_APP_API_URL}/api/activities/news?limit=${ACTIVITIES_LIMIT}&page=${page}&mediaTypes[]=${btnPhoto}&mediaTypes[]=${btnVideo}&mediaTypes[]=${btnText}&followers=${btnFollow}`;
        } else {
            url = `${REACT_APP_API_URL}/api/activities/list?userId=${userProfile.id}&limit=${ACTIVITIES_LIMIT}&page=${page}`;
            if(currentUserIdentifier) {
                url += `&currentUserId=`+currentUserIdentifier
            }
        }

        fetch(url, {
                method: "GET",
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                }
            })
            .then(response => response.json())
            .then((result) => {
                if(result.items.length > 0) {
                    
                    let newData  = mergeAndRemovingDuplicate(activities, result.items);
                    setActivities(newData);
                    //setActivities((oldActivities) => [...oldActivities, ...result.items]);
                    setPage(page + 1);
                }

                if(result.items.length < ACTIVITIES_LIMIT) {
                    setHasMore(false);
                }

                setSpinner(false);
            })
        ;
    }

    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (spinner) {
            return
        };
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
            getActivities();
          }
        })
        if (node) observer.current.observe(node)
      }, [spinner, hasMore, btnPhoto, btnVideo, btnText, page, btnFollow]);



    const [idActivityShowLikes, setIdActivityShowLikes] = useState(null);
    const [idCommentShowLikes, setIdCommentShowLikes] = useState(null);
    
    const {t} = useTranslation();

    return (
        <>        
            <div className="tab-content activity-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                    aria-labelledby="pills-personal-tab">
                    {(isOwner || currentPage === "newActivity") ? (
                        <FormPostActivity 
                            user={userProfile}
                            activities={activities}
                            setActivities={setActivities}
                            setMedias={setMedias}
                        />
                    ) : null}
                </div>
            </div>

            {currentPage === "newActivity" ? (
                <ul className="nav nav-pills activity-bg-color mb-3" id="pills-tab" role="tablist" style={{borderRadius:"10px"}}>
                    <li className="nav-item" role="presentation">
                        <button className={btnVideo === "video" ? "nav-link active" : "nav-link"}  onClick={() => {updateSelectedBtn("video")}}>
                            <i className="icofont-ui-video-play"></i>&nbsp;
                            {t('videos')}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={btnPhoto === "photo" ? "nav-link active" : "nav-link"} onClick={() => {updateSelectedBtn("photo")}}>
                            <i className="icofont-image"></i>&nbsp;
                            {t('photos')}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={btnText === "text" ? "nav-link active" : "nav-link"}  onClick={() => {updateSelectedBtn("text")}}>
                            <i className="icofont-file-text"></i>&nbsp;
                            {t('texts')}</button>
                    </li>
                    <li className="nav-item">
                        <button className={btnFollow === 1 ? "nav-link active" : "nav-link"} onClick={() => {updateSelectedBtn("follow")}}>
                            <i className="icofont-users"></i>&nbsp;
                            {t('follow')}</button>
                    </li>
                </ul>
            ) : null}

            {/* Carousel reels */}
            {currentPage === "newActivity" ? (
                <div className="tab-content activity-content" id="pills-tabContent" style={{marginBottom: "15px"}}>
                    <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                        aria-labelledby="pills-personal-tab">
                            <CarouselReels currentUser={userProfile} />
                    </div>
                </div>
            ) : null}
            {/* Carousel reels */}

            <div className="tab-content activity-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                    aria-labelledby="pills-personal-tab">               
                    {!isAuthenticated ? (
                        <div className="load-btn">
                                <p>{t('sorry_you_have_to_authenticate_to_see_this_part')}</p>
                            </div>
                    ): (
                        <>
                        {activities && activities.length > 0 ? (
                            activities.map((activity, index) => {           
                                if(activities.length === index + 1) {
                                    return (
                                    <div ref={lastElementRef} className="post-item mb-20" key={`${activity.id}-${index}`} style={{borderRadius:"10px"}}>
                                        <SignalActivity 
                                            activityId={activity.id} 
                                            currentUserIdentifier={currentUserIdentifier}
                                            activityUserIdentifier={activity.user.username}
                                            activities={activities}
                                            setActivities={setActivities}
                                        />
                                        <ActivityBody 
                                            activity={activity} 
                                            activities={activities} 
                                            setActivities={setActivities} 
                                            setIdActivityShowLikes={setIdActivityShowLikes}
                                            setIdCommentShowLikes={setIdCommentShowLikes}
                                        />
                                    </div>)
                                } else {
                                    return (
                                        <div className="post-item mb-20" key={`${activity.id}-${index}`} style={{borderRadius:"10px"}}>
                                            <SignalActivity 
                                                activityId={activity.id} 
                                                currentUserIdentifier={currentUserIdentifier}
                                                activityUserIdentifier={activity.user.username}
                                                activities={activities}
                                                setActivities={setActivities}
                                            />
                                            <ActivityBody 
                                                activity={activity} 
                                                activities={activities} 
                                                setActivities={setActivities} 
                                                setIdActivityShowLikes={setIdActivityShowLikes} 
                                                setIdCommentShowLikes={setIdCommentShowLikes}
                                            />
                                        </div>  
                                    )
                                }
                            })
                            ):(
                            <>
                                {isAuthenticated && !spinner ? (
                                    <div className="load-btn">
                                        <p>{t('no_data_to_display')}</p>
                                    </div>
                                ): null }
                            </>
                        )}
                        </>
                    )}    
                    {spinner ? (
                        <TevifSpinner/>
                    ): null}
                </div>
            </div>
            <ActivityShowLikes
                idActivityShowLikes={idActivityShowLikes}
                setIdActivityShowLikes={setIdActivityShowLikes}
            />

            <CommentShowLikes
                idCommentShowLikes={idCommentShowLikes}
                setIdCommentShowLikes={setIdCommentShowLikes}
            />
        </>
    );
}

export default Activity;