import { PushNotifications } from '@capacitor/push-notifications';
import { useContext, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { getDeviceInfo} from '../../service/Device';
import { getHeadersApiListingUsers } from '../../service/User';
import Auth from '../../context/Auth';
import { getStatus, requestPermission } from '../../service/AppTrackingTransparency';


const {REACT_APP_API_URL} = process.env;

function PushNotificationsComponent() {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');  
  const apiHeaders = getHeadersApiListingUsers();
  const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  useEffect(() => {      
    requestPermission();  
    if(isAuthenticated) {  
      if(isPushNotificationsAvailable) {
          PushNotifications.requestPermissions().then((result) => {
            if (result.receive === 'granted') {
              PushNotifications.register();
            }
          });
          
          const statusPermission = getStatus();
          statusPermission.then((permissionData) => {
            if(permissionData.status === "notiosapp" || permissionData.status != "denied") {
              PushNotifications.addListener('registration', (token) => {
                let deviceInfo = getDeviceInfo();
                deviceInfo.then((value) => {
                  fetch(`${REACT_APP_API_URL}/api/devices`, {
                    method: "POST",
                    body: JSON.stringify({
                      identifier: value.id,
                      info: value.info,
                      languageCode: value.languageCode,
                      languageTag: value.languageTag,
                      token: token.value
                    }),
                    headers: apiHeaders,
                    }).then((response) => (response.json()))            
                });
              });
            } else {
              PushNotifications.addListener('registration', (token) => {
                fetch(`${REACT_APP_API_URL}/api/devices`, {
                  method: "POST",
                  body: JSON.stringify({
                    identifier: `ios_${timestamp}`,
                    info: `{permission: "no"}`,
                    languageCode: "en",
                    languageTag: "en-US",
                    token: token.value
                  }),
                  headers: apiHeaders,
                  }).then((response) => (response.json())) 
              });
            }
          });
  
          /*PushNotifications.addListener('pushNotificationReceived', (notification) => {
            
          });
  
          
          PushNotifications.addListener('pushNotificationActionPerformed', (res) => {
            if(res && res.notification && res.notification.data && res.notification.data.click_action) {
              //navigate(res.notification.data.click_action);
            }
          });*/
          
  
          return () => {
            PushNotifications.removeAllListeners();
          };
      }
    }
  }, [isAuthenticated]);
};

export default PushNotificationsComponent;
