export function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
}

export function validateNumber(chaine) {
    var regex = /^\d+$/;
    return regex.test(chaine);
}

export function validateUsername(username) {
    var reg = /^[a-zA-Z0-9-_@. ]*$/;
    return reg.test(username);
}


export function getProfileName(username, firstName, lastName, truncate) {
    function truncateString(str, length, truncate) {
        if (str.length <= length || !truncate) {
            return str;
        }
        return str.substring(0, length - 3) + "...";
    }

    if (firstName || lastName) {
        const fullName = `${firstName} ${lastName}`.trim();
        return truncateString(fullName, 13, truncate);
    }

    if (username.includes('@')) {
        const emailPrefix = username.split('@')[0];
        return truncateString(emailPrefix, 13, truncate);
    }

    return truncateString(username, 13, truncate);
}

export function truncString(str, length) {    
    if (str.length <= length) {
        return str;
    }
    return str.substring(0, length - 3) + "...";
}
