import { useContext, useEffect, useState } from "react";
import Auth from "../../context/Auth";
import { getItem,setItem } from "../../service/LocalStorage";
import { Geolocation } from '@capacitor/geolocation';

import { getHeadersApiListingUsers } from "../../service/User";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const {REACT_APP_API_URL} = process.env;

const syncUserPosition = 300000;

function PositionUser({currentPage}) {
    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const [hasLocationPermission, setHasLocationPermission] = useState(getItem('hasLocationPermission') ?? "false");
    const apiHeaders = getHeadersApiListingUsers();
    const apiUrl = isAuthenticated ? "/api/google-maps-location" : "/api/public/google-maps-location";

    const [positionLastUpdate, setPositionLastUpdate] = useState(getItem('positionLastUpdate') ?? Date.now() - syncUserPosition);

    const getUserLocation = async () => {
        let timestampNow = Date.now();
        let diffTimestamp = timestampNow - positionLastUpdate;

        if(diffTimestamp > syncUserPosition) {
            const position = await Geolocation.getCurrentPosition({ 
                enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 
            });
            
            try {
                if(hasLocationPermission !== 'true') {
                    setHasLocationPermission('true');
                    setItem('hasLocationPermission', 'true');
                }

                setItem('positionLat', position.coords.latitude);
                setItem('positionLng', position.coords.longitude);
                                
                fetch(`${REACT_APP_API_URL}${apiUrl}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "lat": position.coords.latitude,
                        "lng": position.coords.longitude
                    }),
                    headers: apiHeaders,
                    }).then(
                    (response) => (response.json())
                    ).then(async (response)=> {
                        if(response.country) {
                            setItem('positionCity', response.id);
                            setItem('positionCityName', response.name);
                            setItem('positionCityNameAr', response.nameAr);
                            setItem('positionCityNameRu', response.nameRu);

                            
                            setItem('positionCountry', response.country.id);
                            setItem('positionLastUpdate', timestampNow);
                        }
                })
            } catch (err) {
                setHasLocationPermission(false);
                setItem('hasLocationPermission', false);
            }
        }
    }

    useEffect(() => {            
        if(hasLocationPermission === 'true') {
            getUserLocation();
        }

    }, [hasLocationPermission]);

    const {t} = useTranslation();
    return (
        <>
        {hasLocationPermission !== 'true' && currentPage !== "news-activities" ? 
            <div className="form-group" style={{textAlign: "center"}}>
            <span>{t('to_improve_our_research_performance_please_share_your_location_with_us')}</span>   <br />
            <a className="btn lab-btn" style={{cursor:"pointer",  textAlign: "center", backgroundColor: "#f24570", maxWidth: "310px"}} onClick={getUserLocation}><b> {t('i_share_my_position')} <i className="icofont-location-pin"></i></b></a>
            <hr/>
        </div>
            : null}
        </>
    );
}
 
export default PositionUser;