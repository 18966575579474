import { useCallback, useEffect, useRef, useState } from "react";
import FilterSearch from "../../../component/sidebar/filtersearch";
import 'react-photo-view/dist/react-photo-view.css';
import { getItem } from "../../../service/LocalStorage";
import { Link } from "react-router-dom";
import UserData from "../search/userData";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../../../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

function ViewProfile({user}) {
    const userToken = getItem('userToken');
    const [items,setItems] = useState([]);
    const [page,setPage] = useState(2);
    const [spinner,setSpinner] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [firstCall, setFirstCall] = useState(false);

    const getViewProfile = () => {
        setSpinner(true);
        fetch(`${REACT_APP_API_URL}/api/view-profile/list?page=${page}`, {
                method: "GET",
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                }
            })
            .then(response => response.json())
            .then((result) => {
                if(result.items.length > 0) {
                    setItems((oldItems) => [...oldItems, ...result.items]);
                    setPage(page + 1);
                }

                if(result.items.length < 20) {
                    setHasMore(false);
                }

                setSpinner(false);
            })
        ;
    }

    useEffect(() => {  
        async function getInitialViewProfile() {
            setSpinner(true);                    
            let config = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };
    
            try {
                const userResponse = await fetch(`${REACT_APP_API_URL}/api/view-profile/list?page=1`, {
                    method: "GET",
                    headers: config
                })
                    


                if(userResponse.status === 403) {
                    setSpinner(false);
                } else {
                    const data = await userResponse.json();
                    setSpinner(false);
                    setItems(data.items);
                }            
            } catch (err) {
            } 
        }

        setFirstCall(true);

        if(firstCall) {
            getInitialViewProfile();
        }
        
      }, [firstCall])


    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (spinner) {
            return
        };
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
            getViewProfile();
          }
        })
        if (node) observer.current.observe(node)
      }, [spinner, hasMore, page]);
    
      const {t} = useTranslation();

    return (
        <div className="tab-pane fade" id="viewprofile" role="tabpanel" aria-labelledby="nav-viewprofile-tab">
            <div>
                <div className="row">
                    <div className="col-xl-8">
                        <article>
                            <div className="row g-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 justify-content-center">
                                {items.map((val, i) => {
                                    if(items.length === i + 1) {
                                        return (
                                            <Link to={`/profile/${val.userSource.username}`} key={`user_profile_${val.id}`}>
                                                <div ref={lastElementRef} className="col" key={i}>
                                                    <div className="lab-item member-item style-1">
                                                        <div className="lab-inner">
                                                            <div className="lab-thumb" style={{height:"200px"}}>
                                                                <img 
                                                                    src={val.userSource.publicPathCropedProfilePicture} 
                                                                    alt={val.userSource.username}
                                                                />
                                                            </div>
                                                            <div className="lab-content">
                                                                <h6><i className="active green" style={{background: val.userSource.colorActive}}></i>&nbsp;{val.userSource.username}{val.userSource.verified ? (<i className="icofont-check" title="Verify Profile"></i>) : null}</h6>
                                                                <UserData user={val.userSource}/>
                                                                <p>{t('last_visit_date')}: {val.lastViewAt}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                    )} else {
                                        return (
                                            <Link to={`/profile/${val.userSource.username}`} key={`user_profile_${val.id}`}>
                                                <div className="col" key={i}>
                                                    <div className="lab-item member-item style-1">
                                                        <div className="lab-inner">
                                                            <div className="lab-thumb" style={{height:"200px"}}>
                                                                <img 
                                                                    src={val.userSource.publicPathCropedProfilePicture} 
                                                                    alt={val.userSource.username}
                                                                />
                                                            </div>
                                                            <div className="lab-content">
                                                                <h6>
                                                                    <i className="active green" style={{background: val.userSource.colorActive}}></i>
                                                                    &nbsp;{val.userSource.username}
                                                                    {val.userSource.verified ? (<i className="icofont-check" title="Verify Profile"></i>) : null}
                                                                </h6>
                                                                <UserData user={val.userSource}/>
                                                                <p>{t('last_visit_date')}: {val.lastViewAt}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                })}
                            </div>
                            {spinner ? (<TevifSpinner/>): null}
                        </article>
                    </div>

                    <div className="col-xl-4">
                        <aside className="mt-5 mt-xl-0">
                            <FilterSearch user={user} />
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewProfile;