import { truncString } from "./String";

export async function getCountriesApi(currentLanguage) {
    const vipItems = [
        {
            "key": "b_84",
            "id": 84,
            "name": "Belgium",
            "code": "BE",
            "iso3": "BEL",
            "nameAr": "بلجيكا",
            "nameRu": "Бельгия",
            "nameFr": "Belgique"
        },
        {
            "key": "f_22",
            "id": 22,
            "name": "France",
            "code": "FR",
            "iso3": "FRA",
            "nameAr": "فرنسا",
            "nameRu": "Франция",
            "nameFr": null
        },
        {
            "key": "g_43",
            "id": 43,
            "name": "Germany",
            "code": "DE",
            "iso3": "DEU",
            "nameAr": "ألمانيا",
            "nameRu": "Германия",
            "nameFr": "Allemagne"
        },
        {
            "key": "i_59",
            "id": 59,
            "name": "Italy",
            "code": "IT",
            "iso3": "ITA",
            "nameAr": "إيطاليا",
            "nameRu": "Италия",
            "nameFr": "Italie"
        },
        {
            "key": "n_93",
            "id": 93,
            "name": "Netherlands",
            "code": "NL",
            "iso3": "NLD",
            "nameAr": "هولندا",
            "nameRu": "Нидерланды",
            "nameFr": "Pays-Bas"
        },
        {
            "key": "r_13",
            "id": 13,
            "name": "Russia",
            "code": "RU",
            "iso3": "RUS",
            "nameAr": "روسيا",
            "nameRu": "Россия",
            "nameFr": "Russie"
        },
        {
            "key": "s_34",
            "id": 34,
            "name": "Spain",
            "code": "ES",
            "iso3": "ESP",
            "nameAr": "إسبانيا",
            "nameRu": "Испания",
            "nameFr": "Espagne"
        },
        {
            "key": "u_56",
            "id": 56,
            "name": "Ukraine",
            "code": "UA",
            "iso3": "UKR",
            "nameAr": "أوكرانيا",
            "nameRu": "Украина",
            "nameFr": null
        },
        {
            "key": "uk_21",
            "id": 21,
            "name": "United Kingdom",
            "code": "GB",
            "iso3": "GBR",
            "nameAr": "المملكة المتحدة",
            "nameRu": "Великобритания",
            "nameFr": "Royaume-Uni"
        }
    ];

    const items = [
        {
            "id": 45,
            "name": "Afghanistan",
            "code": "AF",
            "iso3": "AFG",
            "nameAr": "أفغانستان",
            "nameRu": "Афганистан",
            "nameFr": null
        },
        {
            "id": 149,
            "name": "Albania",
            "code": "AL",
            "iso3": "ALB",
            "nameAr": "ألبانيا",
            "nameRu": "Албания",
            "nameFr": "Albanie"
        },
        {
            "id": 48,
            "name": "Algeria",
            "code": "DZ",
            "iso3": "DZA",
            "nameAr": "الجزائر",
            "nameRu": "Алжир",
            "nameFr": "Algérie"
        },
        {
            "id": 207,
            "name": "American Samoa",
            "code": "AS",
            "iso3": "ASM",
            "nameAr": "ساموا الأمريكية",
            "nameRu": "американское Самоа",
            "nameFr": "Samoa américaines"
        },
        {
            "id": 198,
            "name": "Andorra",
            "code": "AD",
            "iso3": "AND",
            "nameAr": "أندورا",
            "nameRu": "Андорра",
            "nameFr": "Andorre"
        },
        {
            "id": 25,
            "name": "Angola",
            "code": "AO",
            "iso3": "AGO",
            "nameAr": "أنغولا",
            "nameRu": "Ангола",
            "nameFr": null
        },
        {
            "id": 230,
            "name": "Anguilla",
            "code": "AI",
            "iso3": "AIA",
            "nameAr": "أنغيلا",
            "nameRu": "Ангилья",
            "nameFr": null
        },
        {
            "id": 199,
            "name": "Antigua and Barbuda",
            "code": "AG",
            "iso3": "ATG",
            "nameAr": "أنتيغوا وبربودا",
            "nameRu": "Антигуа и Барбуда",
            "nameFr": "Antigua-et-Barbuda"
        },
        {
            "id": 14,
            "name": "Argentina",
            "code": "AR",
            "iso3": "ARG",
            "nameAr": "الأرجنتين",
            "nameRu": "Аргентина",
            "nameFr": "Argentine"
        },
        {
            "id": 112,
            "name": "Armenia",
            "code": "AM",
            "iso3": "ARM",
            "nameAr": "أرمينيا",
            "nameRu": "Армения",
            "nameFr": "Arménie"
        },
        {
            "id": 190,
            "name": "Aruba",
            "code": "AW",
            "iso3": "ABW",
            "nameAr": "أروبا",
            "nameRu": "Аруба",
            "nameFr": null
        },
        {
            "id": 42,
            "name": "Australia",
            "code": "AU",
            "iso3": "AUS",
            "nameAr": "أستراليا",
            "nameRu": "Австралия",
            "nameFr": "Australie"
        },
        {
            "id": 76,
            "name": "Austria",
            "code": "AT",
            "iso3": "AUT",
            "nameAr": "النمسا",
            "nameRu": "Австрия",
            "nameFr": "Autriche"
        },
        {
            "id": 70,
            "name": "Azerbaijan",
            "code": "AZ",
            "iso3": "AZE",
            "nameAr": "أذربيجان",
            "nameRu": "Азербайджан",
            "nameFr": "Azerbaïdjan"
        },
        {
            "id": 146,
            "name": "Bahrain",
            "code": "BH",
            "iso3": "BHR",
            "nameAr": "البحرين",
            "nameRu": "Бахрейн",
            "nameFr": "Bahreïn"
        },
        {
            "id": 11,
            "name": "Bangladesh",
            "code": "BD",
            "iso3": "BGD",
            "nameAr": "بنغلاديش",
            "nameRu": "Бангладеш",
            "nameFr": null
        },
        {
            "id": 176,
            "name": "Barbados",
            "code": "BB",
            "iso3": "BRB",
            "nameAr": "بربادوس",
            "nameRu": "Барбадос",
            "nameFr": "Barbade"
        },
        {
            "id": 75,
            "name": "Belarus",
            "code": "BY",
            "iso3": "BLR",
            "nameAr": "بيلاروسيا",
            "nameRu": "Беларусь",
            "nameFr": "Biélorussie"
        },
        {
            "id": 84,
            "name": "Belgium",
            "code": "BE",
            "iso3": "BEL",
            "nameAr": "بلجيكا",
            "nameRu": "Бельгия",
            "nameFr": "Belgique"
        },
        {
            "id": 202,
            "name": "Belize",
            "code": "BZ",
            "iso3": "BLZ",
            "nameAr": "بليز",
            "nameRu": "Белиз",
            "nameFr": "Bélize"
        },
        {
            "id": 131,
            "name": "Benin",
            "code": "BJ",
            "iso3": "BEN",
            "nameAr": "بنين",
            "nameRu": "Бенин",
            "nameFr": "Bénin"
        },
        {
            "id": 219,
            "name": "Bermuda",
            "code": "BM",
            "iso3": "BMU",
            "nameAr": "برمودا",
            "nameRu": "Бермуды",
            "nameFr": "Bermudes"
        },
        {
            "id": 178,
            "name": "Bhutan",
            "code": "BT",
            "iso3": "BTN",
            "nameAr": "بوتان",
            "nameRu": "Бутан",
            "nameFr": "Bhoutan"
        },
        {
            "id": 51,
            "name": "Bolivia",
            "code": "BO",
            "iso3": "BOL",
            "nameAr": "بوليفيا",
            "nameRu": "Боливия",
            "nameFr": "Bolivie"
        },
        {
            "id": 239,
            "name": "Bonaire, Sint Eustatius, and Saba",
            "code": "BQ",
            "iso3": "BES",
            "nameAr": "بونير وسانت يوستاتيوس وسابا",
            "nameRu": "Бонайре, Синт-Эстатиус и Саба",
            "nameFr": "Bonaire, Saint-Eustache et Saba"
        },
        {
            "id": 150,
            "name": "Bosnia and Herzegovina",
            "code": "BA",
            "iso3": "BIH",
            "nameAr": "البوسنة والهرسك",
            "nameRu": "Босния и Герцеговина",
            "nameFr": "Bosnie Herzégovine"
        },
        {
            "id": 158,
            "name": "Botswana",
            "code": "BW",
            "iso3": "BWA",
            "nameAr": "بوتسوانا",
            "nameRu": "Ботсвана",
            "nameFr": null
        },
        {
            "id": 6,
            "name": "Brazil",
            "code": "BR",
            "iso3": "BRA",
            "nameAr": "البرازيل",
            "nameRu": "Бразилия",
            "nameFr": "Brésil"
        },
        {
            "id": 206,
            "name": "British Virgin Islands",
            "code": "VG",
            "iso3": "VGB",
            "nameAr": "جزر فيرجن البريطانية",
            "nameRu": "Британские Виргинские острова",
            "nameFr": "Îles Vierges britanniques"
        },
        {
            "id": 186,
            "name": "Brunei",
            "code": "BN",
            "iso3": "BRN",
            "nameAr": "بروناي",
            "nameRu": "Бруней",
            "nameFr": "Brunéi"
        },
        {
            "id": 90,
            "name": "Bulgaria",
            "code": "BG",
            "iso3": "BGR",
            "nameAr": "بلغاريا",
            "nameRu": "Болгария",
            "nameFr": "Bulgarie"
        },
        {
            "id": 68,
            "name": "Burkina Faso",
            "code": "BF",
            "iso3": "BFA",
            "nameAr": "بوركينا فاسو",
            "nameRu": "Буркина-Фасо",
            "nameFr": null
        },
        {
            "id": 110,
            "name": "Burundi",
            "code": "BI",
            "iso3": "BDI",
            "nameAr": "بوروندي",
            "nameRu": "Бурунди",
            "nameFr": null
        },
        {
            "id": 175,
            "name": "Cabo Verde",
            "code": "CV",
            "iso3": "CPV",
            "nameAr": "كابو فيردي",
            "nameRu": "Кабо-Верде",
            "nameFr": "Cap-Vert"
        },
        {
            "id": 73,
            "name": "Cambodia",
            "code": "KH",
            "iso3": "KHM",
            "nameAr": "كمبوديا",
            "nameRu": "Камбоджа",
            "nameFr": "Cambodge"
        },
        {
            "id": 37,
            "name": "Cameroon",
            "code": "CM",
            "iso3": "CMR",
            "nameAr": "الكاميرون",
            "nameRu": "Камерун",
            "nameFr": "Cameroun"
        },
        {
            "id": 39,
            "name": "Canada",
            "code": "CA",
            "iso3": "CAN",
            "nameAr": "كندا",
            "nameRu": "Канада",
            "nameFr": null
        },
        {
            "id": 195,
            "name": "Cayman Islands",
            "code": "KY",
            "iso3": "CYM",
            "nameAr": "جزر كايمان",
            "nameRu": "Каймановы острова",
            "nameFr": "Îles Caïmans"
        },
        {
            "id": 125,
            "name": "Central African Republic",
            "code": "CF",
            "iso3": "CAF",
            "nameAr": "جمهورية افريقيا الوسطى",
            "nameRu": "Центрально-Африканская Республика",
            "nameFr": "République centrafricaine"
        },
        {
            "id": 109,
            "name": "Chad",
            "code": "TD",
            "iso3": "TCD",
            "nameAr": "تشاد",
            "nameRu": "Чад",
            "nameFr": "Tchad"
        },
        {
            "id": 33,
            "name": "Chile",
            "code": "CL",
            "iso3": "CHL",
            "nameAr": "تشيلي",
            "nameRu": "Чили",
            "nameFr": "Chili"
        },
        {
            "id": 4,
            "name": "China",
            "code": "CN",
            "iso3": "CHN",
            "nameAr": "الصين",
            "nameRu": "Китай",
            "nameFr": "Chine"
        },
        {
            "id": 235,
            "name": "Christmas Island",
            "code": "CX",
            "iso3": "CXR",
            "nameAr": "جزيرة الكريسماس",
            "nameRu": "Остров Рождества",
            "nameFr": "L'île de noël"
        },
        {
            "id": 28,
            "name": "Colombia",
            "code": "CO",
            "iso3": "COL",
            "nameAr": "كولومبيا",
            "nameRu": "Колумбия",
            "nameFr": "Colombie"
        },
        {
            "id": 177,
            "name": "Comoros",
            "code": "KM",
            "iso3": "COM",
            "nameAr": "جزر القمر",
            "nameRu": "Коморы",
            "nameFr": "Comores"
        },
        {
            "id": 83,
            "name": "Congo (Brazzaville)",
            "code": "CG",
            "iso3": "COG",
            "nameAr": "الكونغو (برازافيل)",
            "nameRu": "Конго (Браззавиль)",
            "nameFr": "Congo-Brazzaville"
        },
        {
            "id": 20,
            "name": "Congo (Kinshasa)",
            "code": "CD",
            "iso3": "COD",
            "nameAr": "الكونغو (كينشاسا)",
            "nameRu": "Конго (Киншаса)",
            "nameFr": null
        },
        {
            "id": 213,
            "name": "Cook Islands",
            "code": "CK",
            "iso3": "COK",
            "nameAr": "جزر كوك",
            "nameRu": "Острова Кука",
            "nameFr": "les Îles Cook"
        },
        {
            "id": 91,
            "name": "Costa Rica",
            "code": "CR",
            "iso3": "CRI",
            "nameAr": "كوستا ريكا",
            "nameRu": "Коста-Рика",
            "nameFr": null
        },
        {
            "id": 41,
            "name": "Côte d'Ivoire",
            "code": "CI",
            "iso3": "CIV",
            "nameAr": "كوت ديفوار",
            "nameRu": "Берег Слоновой Кости",
            "nameFr": "Côte d'Ivoire"
        },
        {
            "id": 129,
            "name": "Croatia",
            "code": "HR",
            "iso3": "HRV",
            "nameAr": "كرواتيا",
            "nameRu": "Хорватия",
            "nameFr": "Croatie"
        },
        {
            "id": 72,
            "name": "Cuba",
            "code": "CU",
            "iso3": "CUB",
            "nameAr": "كوبا",
            "nameRu": "Куба",
            "nameFr": null
        },
        {
            "id": 170,
            "name": "Curaçao",
            "code": "CW",
            "iso3": "CUW",
            "nameAr": "كوراساو",
            "nameRu": "Кюрасао",
            "nameFr": "Curacao"
        },
        {
            "id": 152,
            "name": "Cyprus",
            "code": "CY",
            "iso3": "CYP",
            "nameAr": "قبرص",
            "nameRu": "Кипр",
            "nameFr": "Chypre"
        },
        {
            "id": 101,
            "name": "Czechia",
            "code": "CZ",
            "iso3": "CZE",
            "nameAr": "التشيك",
            "nameRu": "Чехия",
            "nameFr": "Tchéquie"
        },
        {
            "id": 99,
            "name": "Denmark",
            "code": "DK",
            "iso3": "DNK",
            "nameAr": "الدنمارك",
            "nameRu": "Дания",
            "nameFr": "Danemark"
        },
        {
            "id": 136,
            "name": "Djibouti",
            "code": "DJ",
            "iso3": "DJI",
            "nameAr": "جيبوتي",
            "nameRu": "Джибути",
            "nameFr": null
        },
        {
            "id": 203,
            "name": "Dominica",
            "code": "DM",
            "iso3": "DMA",
            "nameAr": "دومينيكا",
            "nameRu": "Доминика",
            "nameFr": "Dominique"
        },
        {
            "id": 65,
            "name": "Dominican Republic",
            "code": "DO",
            "iso3": "DOM",
            "nameAr": "جمهورية الدومينيكان",
            "nameRu": "Доминиканская Республика",
            "nameFr": "République dominicaine"
        },
        {
            "id": 61,
            "name": "Ecuador",
            "code": "EC",
            "iso3": "ECU",
            "nameAr": "الاكوادور",
            "nameRu": "Эквадор",
            "nameFr": "Équateur"
        },
        {
            "id": 9,
            "name": "Egypt",
            "code": "EG",
            "iso3": "EGY",
            "nameAr": "مصر",
            "nameRu": "Египет",
            "nameFr": "Egypte"
        },
        {
            "id": 139,
            "name": "El Salvador",
            "code": "SV",
            "iso3": "SLV",
            "nameAr": "السلفادور",
            "nameRu": "Сальвадор",
            "nameFr": "Le Salvador"
        },
        {
            "id": 163,
            "name": "Equatorial Guinea",
            "code": "GQ",
            "iso3": "GNQ",
            "nameAr": "غينيا الإستوائية",
            "nameRu": "Экваториальная Гвинея",
            "nameFr": "Guinée Équatoriale"
        },
        {
            "id": 121,
            "name": "Eritrea",
            "code": "ER",
            "iso3": "ERI",
            "nameAr": "إريتريا",
            "nameRu": "Эритрея",
            "nameFr": "Érythrée"
        },
        {
            "id": 147,
            "name": "Estonia",
            "code": "EE",
            "iso3": "EST",
            "nameAr": "إستونيا",
            "nameRu": "Эстония",
            "nameFr": "Estonie"
        },
        {
            "id": 53,
            "name": "Ethiopia",
            "code": "ET",
            "iso3": "ETH",
            "nameAr": "أثيوبيا",
            "nameRu": "Эфиопия",
            "nameFr": "Ethiopie"
        },
        {
            "id": 218,
            "name": "Falkland Islands (Islas Malvinas)",
            "code": "FK",
            "iso3": "FLK",
            "nameAr": "جزر فوكلاند (جزر مالفيناس)",
            "nameRu": "Фолклендские острова (Мальвинские острова)",
            "nameFr": "Îles Falkland (Islas Malvinas)"
        },
        {
            "id": 205,
            "name": "Faroe Islands",
            "code": "FO",
            "iso3": "FRO",
            "nameAr": "جزر فاروس",
            "nameRu": "Фарерские острова",
            "nameFr": "Îles Féroé"
        },
        {
            "id": 210,
            "name": "Federated States of Micronesia",
            "code": "FM",
            "iso3": "FSM",
            "nameAr": "ولايات ميكرونيزيا الموحدة",
            "nameRu": "Федеративные Штаты Микронезии",
            "nameFr": "États fédérés de Micronésie"
        },
        {
            "id": 164,
            "name": "Fiji",
            "code": "FJ",
            "iso3": "FJI",
            "nameAr": "فيجي",
            "nameRu": "Фиджи",
            "nameFr": "Fidji"
        },
        {
            "id": 103,
            "name": "Finland",
            "code": "FI",
            "iso3": "FIN",
            "nameAr": "فنلندا",
            "nameRu": "Финляндия",
            "nameFr": "Finlande"
        },
        {
            "id": 22,
            "name": "France",
            "code": "FR",
            "iso3": "FRA",
            "nameAr": "فرنسا",
            "nameRu": "Франция",
            "nameFr": null
        },
        {
            "id": 183,
            "name": "French Guiana",
            "code": "GF",
            "iso3": "GUF",
            "nameAr": "غيانا الفرنسية",
            "nameRu": "Французская Гвиана",
            "nameFr": "Guyane Française"
        },
        {
            "id": 174,
            "name": "French Polynesia",
            "code": "PF",
            "iso3": "PYF",
            "nameAr": "بولينيزيا الفرنسية",
            "nameRu": "Французская Полинезия",
            "nameFr": "Polynésie française"
        },
        {
            "id": 130,
            "name": "Gabon",
            "code": "GA",
            "iso3": "GAB",
            "nameAr": "الجابون",
            "nameRu": "Габон",
            "nameFr": null
        },
        {
            "id": 137,
            "name": "Gaza Strip",
            "code": "XG",
            "iso3": "XGZ",
            "nameAr": "قطاع غزة",
            "nameRu": "сектор Газа",
            "nameFr": "bande de Gaza"
        },
        {
            "id": 108,
            "name": "Georgia",
            "code": "GE",
            "iso3": "GEO",
            "nameAr": "جورجيا",
            "nameRu": "Грузия",
            "nameFr": "Géorgie"
        },
        {
            "id": 43,
            "name": "Germany",
            "code": "DE",
            "iso3": "DEU",
            "nameAr": "ألمانيا",
            "nameRu": "Германия",
            "nameFr": "Allemagne"
        },
        {
            "id": 49,
            "name": "Ghana",
            "code": "GH",
            "iso3": "GHA",
            "nameAr": "غانا",
            "nameRu": "Гана",
            "nameFr": null
        },
        {
            "id": 162,
            "name": "Gibraltar",
            "code": "GI",
            "iso3": "GIB",
            "nameAr": "جبل طارق",
            "nameRu": "Гибралтар",
            "nameFr": null
        },
        {
            "id": 52,
            "name": "Greece",
            "code": "GR",
            "iso3": "GRC",
            "nameAr": "اليونان",
            "nameRu": "Греция",
            "nameFr": "Grèce"
        },
        {
            "id": 201,
            "name": "Greenland",
            "code": "GL",
            "iso3": "GRL",
            "nameAr": "الأرض الخضراء",
            "nameRu": "Гренландия",
            "nameFr": "Groenland"
        },
        {
            "id": 214,
            "name": "Grenada",
            "code": "GD",
            "iso3": "GRD",
            "nameAr": "غرينادا",
            "nameRu": "Гренада",
            "nameFr": "Grenade"
        },
        {
            "id": 222,
            "name": "Guadeloupe",
            "code": "GP",
            "iso3": "GLP",
            "nameAr": "جوادلوب",
            "nameRu": "Гваделупа",
            "nameFr": null
        },
        {
            "id": 223,
            "name": "Guam",
            "code": "GU",
            "iso3": "GUM",
            "nameAr": "غوام",
            "nameRu": "Гуам",
            "nameFr": null
        },
        {
            "id": 58,
            "name": "Guatemala",
            "code": "GT",
            "iso3": "GTM",
            "nameAr": "غواتيمالا",
            "nameRu": "Гватемала",
            "nameFr": null
        },
        {
            "id": 88,
            "name": "Guinea",
            "code": "GN",
            "iso3": "GIN",
            "nameAr": "غينيا",
            "nameRu": "Гвинея",
            "nameFr": "Guinée"
        },
        {
            "id": 143,
            "name": "Guinea-Bissau",
            "code": "GW",
            "iso3": "GNB",
            "nameAr": "غينيا بيساو",
            "nameRu": "Гвинея-Бисау",
            "nameFr": "Guinée-Bissau"
        },
        {
            "id": 161,
            "name": "Guyana",
            "code": "GY",
            "iso3": "GUY",
            "nameAr": "غيانا",
            "nameRu": "Гайана",
            "nameFr": "Guyane"
        },
        {
            "id": 119,
            "name": "Haiti",
            "code": "HT",
            "iso3": "HTI",
            "nameAr": "هايتي",
            "nameRu": "Гаити",
            "nameFr": "Haïti"
        },
        {
            "id": 87,
            "name": "Honduras",
            "code": "HN",
            "iso3": "HND",
            "nameAr": "هندوراس",
            "nameRu": "Гондурас",
            "nameFr": null
        },
        {
            "id": 31,
            "name": "Hong Kong",
            "code": "HK",
            "iso3": "HKG",
            "nameAr": "هونج كونج",
            "nameRu": "Гонконг",
            "nameFr": null
        },
        {
            "id": 55,
            "name": "Hungary",
            "code": "HU",
            "iso3": "HUN",
            "nameAr": "هنغاريا",
            "nameRu": "Венгрия",
            "nameFr": "Hongrie"
        },
        {
            "id": 172,
            "name": "Iceland",
            "code": "IS",
            "iso3": "ISL",
            "nameAr": "أيسلندا",
            "nameRu": "Исландия",
            "nameFr": "Islande"
        },
        {
            "id": 3,
            "name": "India",
            "code": "IN",
            "iso3": "IND",
            "nameAr": "الهند",
            "nameRu": "Индия",
            "nameFr": "Inde"
        },
        {
            "id": 2,
            "name": "Indonesia",
            "code": "ID",
            "iso3": "IDN",
            "nameAr": "إندونيسيا",
            "nameRu": "Индонезия",
            "nameFr": "Indonésie"
        },
        {
            "id": 19,
            "name": "Iran",
            "code": "IR",
            "iso3": "IRN",
            "nameAr": "إيران",
            "nameRu": "Иран",
            "nameFr": "Iran"
        },
        {
            "id": 35,
            "name": "Iraq",
            "code": "IQ",
            "iso3": "IRQ",
            "nameAr": "العراق",
            "nameRu": "Ирак",
            "nameFr": "Irak"
        },
        {
            "id": 106,
            "name": "Ireland",
            "code": "IE",
            "iso3": "IRL",
            "nameAr": "أيرلندا",
            "nameRu": "Ирландия",
            "nameFr": "Irlande"
        },
        {
            "id": 194,
            "name": "Isle Of Man",
            "code": "IM",
            "iso3": "IMN",
            "nameAr": "جزيرة آيل أوف مان",
            "nameRu": "Остров Мэн",
            "nameFr": "Île de Man"
        },
        {
            "id": 96,
            "name": "Israel",
            "code": "IL",
            "iso3": "ISR",
            "nameAr": "إسرائيل",
            "nameRu": "Израиль",
            "nameFr": "Israël"
        },
        {
            "id": 59,
            "name": "Italy",
            "code": "IT",
            "iso3": "ITA",
            "nameAr": "إيطاليا",
            "nameRu": "Италия",
            "nameFr": "Italie"
        },
        {
            "id": 138,
            "name": "Jamaica",
            "code": "JM",
            "iso3": "JAM",
            "nameAr": "جامايكا",
            "nameRu": "Ямайка",
            "nameFr": "Jamaïque"
        },
        {
            "id": 1,
            "name": "Japan",
            "code": "JP",
            "iso3": "JPN",
            "nameAr": "اليابان",
            "nameRu": "Япония",
            "nameFr": "Japon"
        },
        {
            "id": 191,
            "name": "Jersey",
            "code": "JE",
            "iso3": "JEY",
            "nameAr": "جيرسي",
            "nameRu": "Джерси",
            "nameFr": null
        },
        {
            "id": 47,
            "name": "Jordan",
            "code": "JO",
            "iso3": "JOR",
            "nameAr": "الأردن",
            "nameRu": "Иордания",
            "nameFr": null
        },
        {
            "id": 78,
            "name": "Kazakhstan",
            "code": "KZ",
            "iso3": "KAZ",
            "nameAr": "كازاخستان",
            "nameRu": "Казахстан",
            "nameFr": null
        },
        {
            "id": 38,
            "name": "Kenya",
            "code": "KE",
            "iso3": "KEN",
            "nameAr": "كينيا",
            "nameRu": "Кения",
            "nameFr": null
        },
        {
            "id": 189,
            "name": "Kiribati",
            "code": "KI",
            "iso3": "KIR",
            "nameAr": "كيريباتي",
            "nameRu": "Кирибати",
            "nameFr": null
        },
        {
            "id": 166,
            "name": "Kosovo",
            "code": "XK",
            "iso3": "XKS",
            "nameAr": "كوسوفو",
            "nameRu": "Косово",
            "nameFr": null
        },
        {
            "id": 54,
            "name": "Kuwait",
            "code": "KW",
            "iso3": "KWT",
            "nameAr": "الكويت",
            "nameRu": "Кувейт",
            "nameFr": "Koweit"
        },
        {
            "id": 111,
            "name": "Kyrgyzstan",
            "code": "KG",
            "iso3": "KGZ",
            "nameAr": "قيرغيزستان",
            "nameRu": "Кыргызстан",
            "nameFr": "Kirghizistan"
        },
        {
            "id": 123,
            "name": "Laos",
            "code": "LA",
            "iso3": "LAO",
            "nameAr": "لاوس",
            "nameRu": "Лаос",
            "nameFr": null
        },
        {
            "id": 124,
            "name": "Latvia",
            "code": "LV",
            "iso3": "LVA",
            "nameAr": "لاتفيا",
            "nameRu": "Латвия",
            "nameFr": "Lettonie"
        },
        {
            "id": 148,
            "name": "Lebanon",
            "code": "LB",
            "iso3": "LBN",
            "nameAr": "لبنان",
            "nameRu": "Ливан",
            "nameFr": "Liban"
        },
        {
            "id": 142,
            "name": "Lesotho",
            "code": "LS",
            "iso3": "LSO",
            "nameAr": "ليسوتو",
            "nameRu": "Лесото",
            "nameFr": null
        },
        {
            "id": 118,
            "name": "Liberia",
            "code": "LR",
            "iso3": "LBR",
            "nameAr": "ليبيريا",
            "nameRu": "Либерия",
            "nameFr": "Libéria"
        },
        {
            "id": 102,
            "name": "Libya",
            "code": "LY",
            "iso3": "LBY",
            "nameAr": "ليبيا",
            "nameRu": "Ливия",
            "nameFr": "Libye"
        },
        {
            "id": 212,
            "name": "Liechtenstein",
            "code": "LI",
            "iso3": "LIE",
            "nameAr": "ليختنشتاين",
            "nameRu": "Лихтенштейн",
            "nameFr": null
        },
        {
            "id": 132,
            "name": "Lithuania",
            "code": "LT",
            "iso3": "LTU",
            "nameAr": "ليتوانيا",
            "nameRu": "Литва",
            "nameFr": "Lituanie"
        },
        {
            "id": 173,
            "name": "Luxembourg",
            "code": "LU",
            "iso3": "LUX",
            "nameAr": "لوكسمبورغ",
            "nameRu": "Люксембург",
            "nameFr": null
        },
        {
            "id": 237,
            "name": "Macau",
            "code": "MO",
            "iso3": "MAC",
            "nameAr": "ماكاو",
            "nameRu": "Макао",
            "nameFr": "Macao"
        },
        {
            "id": 135,
            "name": "Macedonia",
            "code": "MK",
            "iso3": "MKD",
            "nameAr": "مقدونيا",
            "nameRu": "Македония",
            "nameFr": "Macédoine"
        },
        {
            "id": 64,
            "name": "Madagascar",
            "code": "MG",
            "iso3": "MDG",
            "nameAr": "مدغشقر",
            "nameRu": "Мадагаскар",
            "nameFr": null
        },
        {
            "id": 80,
            "name": "Malawi",
            "code": "MW",
            "iso3": "MWI",
            "nameAr": "ملاوي",
            "nameRu": "Малави",
            "nameFr": null
        },
        {
            "id": 26,
            "name": "Malaysia",
            "code": "MY",
            "iso3": "MYS",
            "nameAr": "ماليزيا",
            "nameRu": "Малайзия",
            "nameFr": "Malaisie"
        },
        {
            "id": 167,
            "name": "Maldives",
            "code": "MV",
            "iso3": "MDV",
            "nameAr": "جزر المالديف",
            "nameRu": "Мальдивы",
            "nameFr": null
        },
        {
            "id": 74,
            "name": "Mali",
            "code": "ML",
            "iso3": "MLI",
            "nameAr": "مالي",
            "nameRu": "Мали",
            "nameFr": null
        },
        {
            "id": 144,
            "name": "Malta",
            "code": "MT",
            "iso3": "MLT",
            "nameAr": "مالطا",
            "nameRu": "Мальта",
            "nameFr": "Malte"
        },
        {
            "id": 193,
            "name": "Marshall Islands",
            "code": "MH",
            "iso3": "MHL",
            "nameAr": "جزر مارشال",
            "nameRu": "Маршалловы острова",
            "nameFr": "Iles Marshall"
        },
        {
            "id": 157,
            "name": "Martinique",
            "code": "MQ",
            "iso3": "MTQ",
            "nameAr": "مارتينيك",
            "nameRu": "Мартиника",
            "nameFr": null
        },
        {
            "id": 113,
            "name": "Mauritania",
            "code": "MR",
            "iso3": "MRT",
            "nameAr": "موريتانيا",
            "nameRu": "Мавритания",
            "nameFr": "Mauritanie"
        },
        {
            "id": 168,
            "name": "Mauritius",
            "code": "MU",
            "iso3": "MUS",
            "nameAr": "موريشيوس",
            "nameRu": "Маврикий",
            "nameFr": "Maurice"
        },
        {
            "id": 192,
            "name": "Mayotte",
            "code": "YT",
            "iso3": "MYT",
            "nameAr": "مايوت",
            "nameRu": "Майотта",
            "nameFr": null
        },
        {
            "id": 8,
            "name": "Mexico",
            "code": "MX",
            "iso3": "MEX",
            "nameAr": "المكسيك",
            "nameRu": "Мексика",
            "nameFr": "Mexique"
        },
        {
            "id": 133,
            "name": "Moldova",
            "code": "MD",
            "iso3": "MDA",
            "nameAr": "مولدوفا",
            "nameRu": "Молдова",
            "nameFr": "Moldavie"
        },
        {
            "id": 187,
            "name": "Monaco",
            "code": "MC",
            "iso3": "MCO",
            "nameAr": "موناكو",
            "nameRu": "Монако",
            "nameFr": null
        },
        {
            "id": 97,
            "name": "Mongolia",
            "code": "MN",
            "iso3": "MNG",
            "nameAr": "منغوليا",
            "nameRu": "Монголия",
            "nameFr": "Mongolie"
        },
        {
            "id": 169,
            "name": "Montenegro",
            "code": "ME",
            "iso3": "MNE",
            "nameAr": "الجبل الأسود",
            "nameRu": "Черногория",
            "nameFr": "Monténégro"
        },
        {
            "id": 225,
            "name": "Montserrat",
            "code": "MS",
            "iso3": "MSR",
            "nameAr": "مونتسيرات",
            "nameRu": "Монтсеррат",
            "nameFr": null
        },
        {
            "id": 44,
            "name": "Morocco",
            "code": "MA",
            "iso3": "MAR",
            "nameAr": "المغرب",
            "nameRu": "Марокко",
            "nameFr": "Maroc"
        },
        {
            "id": 105,
            "name": "Mozambique",
            "code": "MZ",
            "iso3": "MOZ",
            "nameAr": "موزمبيق",
            "nameRu": "Мозамбик",
            "nameFr": null
        },
        {
            "id": 40,
            "name": "Myanmar",
            "code": "MM",
            "iso3": "MMR",
            "nameAr": "ميانمار",
            "nameRu": "Мьянма",
            "nameFr": "Birmanie"
        },
        {
            "id": 153,
            "name": "Namibia",
            "code": "NA",
            "iso3": "NAM",
            "nameAr": "ناميبيا",
            "nameRu": "Намибия",
            "nameFr": "Namibie"
        },
        {
            "id": 226,
            "name": "Nauru",
            "code": "NR",
            "iso3": "NRU",
            "nameAr": "ناورو",
            "nameRu": "Науру",
            "nameFr": null
        },
        {
            "id": 120,
            "name": "Nepal",
            "code": "NP",
            "iso3": "NPL",
            "nameAr": "نيبال",
            "nameRu": "Непал",
            "nameFr": "Népal"
        },
        {
            "id": 93,
            "name": "Netherlands",
            "code": "NL",
            "iso3": "NLD",
            "nameAr": "هولندا",
            "nameRu": "Нидерланды",
            "nameFr": "Pays-Bas"
        },
        {
            "id": 165,
            "name": "New Caledonia",
            "code": "NC",
            "iso3": "NCL",
            "nameAr": "كاليدونيا الجديدة",
            "nameRu": "Новая Каледония",
            "nameFr": "Nouvelle Calédonie"
        },
        {
            "id": 100,
            "name": "New Zealand",
            "code": "NZ",
            "iso3": "NZL",
            "nameAr": "نيوزيلندا",
            "nameRu": "Новая Зеландия",
            "nameFr": "Nouvelle-Zélande"
        },
        {
            "id": 116,
            "name": "Nicaragua",
            "code": "NI",
            "iso3": "NIC",
            "nameAr": "نيكاراغوا",
            "nameRu": "Никарагуа",
            "nameFr": null
        },
        {
            "id": 117,
            "name": "Niger",
            "code": "NE",
            "iso3": "NER",
            "nameAr": "النيجر",
            "nameRu": "Нигер",
            "nameFr": null
        },
        {
            "id": 15,
            "name": "Nigeria",
            "code": "NG",
            "iso3": "NGA",
            "nameAr": "نيجيريا",
            "nameRu": "Нигерия",
            "nameFr": null
        },
        {
            "id": 221,
            "name": "Niue",
            "code": "NU",
            "iso3": "NIU",
            "nameAr": "نيوي",
            "nameRu": "Ниуэ",
            "nameFr": "Nioué"
        },
        {
            "id": 232,
            "name": "Norfolk Island",
            "code": "NF",
            "iso3": "NFK",
            "nameAr": "جزيرة نورفولك",
            "nameRu": "Остров Норфолк",
            "nameFr": "l'ile de Norfolk"
        },
        {
            "id": 60,
            "name": "North Korea",
            "code": "KP",
            "iso3": "PRK",
            "nameAr": "كوريا الشمالية",
            "nameRu": "Северная Корея",
            "nameFr": "Corée du Nord"
        },
        {
            "id": 217,
            "name": "Northern Mariana Islands",
            "code": "MP",
            "iso3": "MNP",
            "nameAr": "جزر مريانا الشمالية",
            "nameRu": "Северные Марианские острова",
            "nameFr": "Îles Mariannes du Nord"
        },
        {
            "id": 114,
            "name": "Norway",
            "code": "NO",
            "iso3": "NOR",
            "nameAr": "النرويج",
            "nameRu": "Норвегия",
            "nameFr": "Norvège"
        },
        {
            "id": 95,
            "name": "Oman",
            "code": "OM",
            "iso3": "OMN",
            "nameAr": "سلطنة عمان",
            "nameRu": "Оман",
            "nameFr": null
        },
        {
            "id": 17,
            "name": "Pakistan",
            "code": "PK",
            "iso3": "PAK",
            "nameAr": "باكستان",
            "nameRu": "Пакистан",
            "nameFr": null
        },
        {
            "id": 228,
            "name": "Palau",
            "code": "PW",
            "iso3": "PLW",
            "nameAr": "بالاو",
            "nameRu": "Палау",
            "nameFr": "Palaos"
        },
        {
            "id": 92,
            "name": "Panama",
            "code": "PA",
            "iso3": "PAN",
            "nameAr": "بنما",
            "nameRu": "Панама",
            "nameFr": null
        },
        {
            "id": 134,
            "name": "Papua New Guinea",
            "code": "PG",
            "iso3": "PNG",
            "nameAr": "بابوا غينيا الجديدة",
            "nameRu": "Папуа - Новая Гвинея",
            "nameFr": "Papouasie Nouvelle Guinée"
        },
        {
            "id": 140,
            "name": "Paraguay",
            "code": "PY",
            "iso3": "PRY",
            "nameAr": "باراغواي",
            "nameRu": "Парагвай",
            "nameFr": null
        },
        {
            "id": 23,
            "name": "Peru",
            "code": "PE",
            "iso3": "PER",
            "nameAr": "بيرو",
            "nameRu": "Перу",
            "nameFr": "Pérou"
        },
        {
            "id": 5,
            "name": "Philippines",
            "code": "PH",
            "iso3": "PHL",
            "nameAr": "فيلبيني",
            "nameRu": "Филиппины",
            "nameFr": null
        },
        {
            "id": 234,
            "name": "Pitcairn Islands",
            "code": "PN",
            "iso3": "PCN",
            "nameAr": "جزر بيتكيرن",
            "nameRu": "Острова Питкэрн",
            "nameFr": "Îles Pitcairn"
        },
        {
            "id": 82,
            "name": "Poland",
            "code": "PL",
            "iso3": "POL",
            "nameAr": "بولندا",
            "nameRu": "Польша",
            "nameFr": "Pologne"
        },
        {
            "id": 62,
            "name": "Portugal",
            "code": "PT",
            "iso3": "PRT",
            "nameAr": "البرتغال",
            "nameRu": "Португалия",
            "nameFr": "le Portugal"
        },
        {
            "id": 79,
            "name": "Puerto Rico",
            "code": "PR",
            "iso3": "PRI",
            "nameAr": "بورتوريكو",
            "nameRu": "Пуэрто-Рико",
            "nameFr": "Porto Rico"
        },
        {
            "id": 104,
            "name": "Qatar",
            "code": "QA",
            "iso3": "QAT",
            "nameAr": "دولة قطر",
            "nameRu": "Катар",
            "nameFr": null
        },
        {
            "id": 154,
            "name": "Reunion",
            "code": "RE",
            "iso3": "REU",
            "nameAr": "جمع شمل",
            "nameRu": "Воссоединение",
            "nameFr": "Réunion"
        },
        {
            "id": 81,
            "name": "Romania",
            "code": "RO",
            "iso3": "ROU",
            "nameAr": "رومانيا",
            "nameRu": "Румыния",
            "nameFr": "Roumanie"
        },
        {
            "id": 13,
            "name": "Russia",
            "code": "RU",
            "iso3": "RUS",
            "nameAr": "روسيا",
            "nameRu": "Россия",
            "nameFr": "Russie"
        },
        {
            "id": 107,
            "name": "Rwanda",
            "code": "RW",
            "iso3": "RWA",
            "nameAr": "رواندا",
            "nameRu": "Руанда",
            "nameFr": null
        },
        {
            "id": 227,
            "name": "Saint Barthelemy",
            "code": "BL",
            "iso3": "BLM",
            "nameAr": "سانت بارتيليمي",
            "nameRu": "Сен-Бартельми",
            "nameFr": "Saint Barthélemy"
        },
        {
            "id": 224,
            "name": "Saint Helena, Ascension, and Tristan da Cunha",
            "code": "SH",
            "iso3": "SHN",
            "nameAr": "سانت هيلانة وأسنسيون وتريستان دا كونها",
            "nameRu": "Остров Святой Елены, Вознесение и Тристан-да-Кунья",
            "nameFr": "Sainte-Hélène, Ascension et Tristan da Cunha"
        },
        {
            "id": 204,
            "name": "Saint Kitts and Nevis",
            "code": "KN",
            "iso3": "KNA",
            "nameAr": "سانت كيتس ونيفيس",
            "nameRu": "Сент-Китс и Невис",
            "nameFr": "Saint-Christophe-et-Niévès"
        },
        {
            "id": 182,
            "name": "Saint Lucia",
            "code": "LC",
            "iso3": "LCA",
            "nameAr": "القديسة لوسيا",
            "nameRu": "Санкт-Люсия",
            "nameFr": "Sainte-Lucie"
        },
        {
            "id": 209,
            "name": "Saint Martin",
            "code": "MF",
            "iso3": "MAF",
            "nameAr": "القديس مارتن",
            "nameRu": "Сен-Мартен",
            "nameFr": null
        },
        {
            "id": 229,
            "name": "Saint Pierre and Miquelon",
            "code": "PM",
            "iso3": "SPM",
            "nameAr": "سانت بيير وميكلون",
            "nameRu": "Сен-Пьер и Микелон",
            "nameFr": "Saint-Pierre-et-Miquelon"
        },
        {
            "id": 197,
            "name": "Saint Vincent and the Grenadines",
            "code": "VC",
            "iso3": "VCT",
            "nameAr": "سانت فنسنت وجزر غرينادين",
            "nameRu": "Святой Винсент и Гренадины",
            "nameFr": "Saint-Vincent-et-les-Grenadines"
        },
        {
            "id": 188,
            "name": "Samoa",
            "code": "WS",
            "iso3": "WSM",
            "nameAr": "ساموا",
            "nameRu": "Самоа",
            "nameFr": null
        },
        {
            "id": 215,
            "name": "San Marino",
            "code": "SM",
            "iso3": "SMR",
            "nameAr": "سان مارينو",
            "nameRu": "Сан-Марино",
            "nameFr": "Saint Marin"
        },
        {
            "id": 184,
            "name": "Sao Tome and Principe",
            "code": "ST",
            "iso3": "STP",
            "nameAr": "ساو تومي وبرينسيبي",
            "nameRu": "Сан-Томе и Принсипи",
            "nameFr": "Sao Tomé et Principe"
        },
        {
            "id": 32,
            "name": "Saudi Arabia",
            "code": "SA",
            "iso3": "SAU",
            "nameAr": "المملكة العربية السعودية",
            "nameRu": "Саудовская Аравия",
            "nameFr": "Arabie Saoudite"
        },
        {
            "id": 94,
            "name": "Senegal",
            "code": "SN",
            "iso3": "SEN",
            "nameAr": "السنغال",
            "nameRu": "Сенегал",
            "nameFr": "Sénégal"
        },
        {
            "id": 98,
            "name": "Serbia",
            "code": "RS",
            "iso3": "SRB",
            "nameAr": "صربيا",
            "nameRu": "Сербия",
            "nameFr": "Serbie"
        },
        {
            "id": 196,
            "name": "Seychelles",
            "code": "SC",
            "iso3": "SYC",
            "nameAr": "سيشيل",
            "nameRu": "Сейшелы",
            "nameFr": "les Seychelles"
        },
        {
            "id": 122,
            "name": "Sierra Leone",
            "code": "SL",
            "iso3": "SLE",
            "nameAr": "سيرا ليون",
            "nameRu": "Сьерра-Леоне",
            "nameFr": null
        },
        {
            "id": 36,
            "name": "Singapore",
            "code": "SG",
            "iso3": "SGP",
            "nameAr": "سنغافورة",
            "nameRu": "Сингапур",
            "nameFr": "Singapour"
        },
        {
            "id": 216,
            "name": "Sint Maarten",
            "code": "SX",
            "iso3": "SXM",
            "nameAr": "سينت مارتن",
            "nameRu": "Синт-Мартен",
            "nameFr": "Saint-Martin"
        },
        {
            "id": 145,
            "name": "Slovakia",
            "code": "SK",
            "iso3": "SVK",
            "nameAr": "سلوفاكيا",
            "nameRu": "Словакия",
            "nameFr": "Slovaquie"
        },
        {
            "id": 155,
            "name": "Slovenia",
            "code": "SI",
            "iso3": "SVN",
            "nameAr": "سلوفينيا",
            "nameRu": "Словения",
            "nameFr": "Slovénie"
        },
        {
            "id": 180,
            "name": "Solomon Islands",
            "code": "SB",
            "iso3": "SLB",
            "nameAr": "جزر سليمان",
            "nameRu": "Соломоновы острова",
            "nameFr": "Les îles Salomon"
        },
        {
            "id": 46,
            "name": "Somalia",
            "code": "SO",
            "iso3": "SOM",
            "nameAr": "الصومال",
            "nameRu": "Сомали",
            "nameFr": "Somalie"
        },
        {
            "id": 27,
            "name": "South Africa",
            "code": "ZA",
            "iso3": "ZAF",
            "nameAr": "جنوب أفريقيا",
            "nameRu": "Южная Африка",
            "nameFr": "Afrique du Sud"
        },
        {
            "id": 236,
            "name": "South Georgia And South Sandwich Islands",
            "code": "GS",
            "iso3": "SGS",
            "nameAr": "جزر جورجيا الجنوبية وساندويتش الجنوبية",
            "nameRu": "Южная Георгия и Южные Сандвичевы острова",
            "nameFr": "Géorgie du Sud et îles Sandwich du Sud"
        },
        {
            "id": 7,
            "name": "South Korea",
            "code": "KR",
            "iso3": "KOR",
            "nameAr": "كوريا الجنوبية",
            "nameRu": "Южная Корея",
            "nameFr": "Corée du Sud"
        },
        {
            "id": 141,
            "name": "South Sudan",
            "code": "SS",
            "iso3": "SSD",
            "nameAr": "جنوب السودان",
            "nameRu": "южный Судан",
            "nameFr": "Soudan du sud"
        },
        {
            "id": 34,
            "name": "Spain",
            "code": "ES",
            "iso3": "ESP",
            "nameAr": "إسبانيا",
            "nameRu": "Испания",
            "nameFr": "Espagne"
        },
        {
            "id": 69,
            "name": "Sri Lanka",
            "code": "LK",
            "iso3": "LKA",
            "nameAr": "سيريلانكا",
            "nameRu": "Шри-Ланка",
            "nameFr": null
        },
        {
            "id": 30,
            "name": "Sudan",
            "code": "SD",
            "iso3": "SDN",
            "nameAr": "السودان",
            "nameRu": "Судан",
            "nameFr": "Soudan"
        },
        {
            "id": 159,
            "name": "Suriname",
            "code": "SR",
            "iso3": "SUR",
            "nameAr": "سورينام",
            "nameRu": "Суринам",
            "nameFr": "Surinam"
        },
        {
            "id": 233,
            "name": "Svalbard",
            "code": "XR",
            "iso3": "XSV",
            "nameAr": "سفالبارد",
            "nameRu": "Шпицберген",
            "nameFr": null
        },
        {
            "id": 179,
            "name": "Swaziland",
            "code": "SZ",
            "iso3": "SWZ",
            "nameAr": "سوازيلاند",
            "nameRu": "Свазиленд",
            "nameFr": null
        },
        {
            "id": 89,
            "name": "Sweden",
            "code": "SE",
            "iso3": "SWE",
            "nameAr": "السويد",
            "nameRu": "Швеция",
            "nameFr": "Suède"
        },
        {
            "id": 171,
            "name": "Switzerland",
            "code": "CH",
            "iso3": "CHE",
            "nameAr": "سويسرا",
            "nameRu": "Швейцария",
            "nameFr": "Suisse"
        },
        {
            "id": 77,
            "name": "Syria",
            "code": "SY",
            "iso3": "SYR",
            "nameAr": "سوريا",
            "nameRu": "Сирия",
            "nameFr": "Syrie"
        },
        {
            "id": 24,
            "name": "Taiwan",
            "code": "TW",
            "iso3": "TWN",
            "nameAr": "تايوان",
            "nameRu": "Тайвань",
            "nameFr": "Taïwan"
        },
        {
            "id": 126,
            "name": "Tajikistan",
            "code": "TJ",
            "iso3": "TJK",
            "nameAr": "طاجيكستان",
            "nameRu": "Таджикистан",
            "nameFr": "Tadjikistan"
        },
        {
            "id": 29,
            "name": "Tanzania",
            "code": "TZ",
            "iso3": "TZA",
            "nameAr": "تنزانيا",
            "nameRu": "Танзания",
            "nameFr": "Tanzanie"
        },
        {
            "id": 12,
            "name": "Thailand",
            "code": "TH",
            "iso3": "THA",
            "nameAr": "تايلاند",
            "nameRu": "Таиланд",
            "nameFr": "Thaïlande"
        },
        {
            "id": 156,
            "name": "The Bahamas",
            "code": "BS",
            "iso3": "BHS",
            "nameAr": "جزر البهاما",
            "nameRu": "Багамские острова",
            "nameFr": "Les Bahamas"
        },
        {
            "id": 151,
            "name": "The Gambia",
            "code": "GM",
            "iso3": "GMB",
            "nameAr": "غامبيا",
            "nameRu": "Гамбия",
            "nameFr": "La Gambie"
        },
        {
            "id": 160,
            "name": "Timor-Leste",
            "code": "TL",
            "iso3": "TLS",
            "nameAr": "تيمور ليشتي",
            "nameRu": "Тимор-Лешти",
            "nameFr": null
        },
        {
            "id": 127,
            "name": "Togo",
            "code": "TG",
            "iso3": "TGO",
            "nameAr": "توجو",
            "nameRu": "Идти",
            "nameFr": "Togo"
        },
        {
            "id": 200,
            "name": "Tonga",
            "code": "TO",
            "iso3": "TON",
            "nameAr": "تونغا",
            "nameRu": "Тонга",
            "nameFr": "Tonga"
        },
        {
            "id": 181,
            "name": "Trinidad and Tobago",
            "code": "TT",
            "iso3": "TTO",
            "nameAr": "ترينداد وتوباغو",
            "nameRu": "Тринидад и Тобаго",
            "nameFr": "Trinité-et-Tobago"
        },
        {
            "id": 115,
            "name": "Tunisia",
            "code": "TN",
            "iso3": "TUN",
            "nameAr": "تونس",
            "nameRu": "Тунис",
            "nameFr": "Tunisie"
        },
        {
            "id": 16,
            "name": "Turkey",
            "code": "TR",
            "iso3": "TUR",
            "nameAr": "تركيا",
            "nameRu": "Турция",
            "nameFr": "Turquie"
        },
        {
            "id": 128,
            "name": "Turkmenistan",
            "code": "TM",
            "iso3": "TKM",
            "nameAr": "تركمانستان",
            "nameRu": "Туркменистан",
            "nameFr": "Turkménistan"
        },
        {
            "id": 208,
            "name": "Turks and Caicos Islands",
            "code": "TC",
            "iso3": "TCA",
            "nameAr": "جزر تركس وكايكوس",
            "nameRu": "острова Теркс и Кайкос",
            "nameFr": "îles Turques-et-Caïques"
        },
        {
            "id": 211,
            "name": "Tuvalu",
            "code": "TV",
            "iso3": "TUV",
            "nameAr": "توفالو",
            "nameRu": "Тувалу",
            "nameFr": null
        },
        {
            "id": 240,
            "name": "U.S. Virgin Islands",
            "code": "VI",
            "iso3": "VIR",
            "nameAr": "جزر فيرجن الأمريكية",
            "nameRu": "Виргинские острова США",
            "nameFr": "Îles Vierges américaines"
        },
        {
            "id": 86,
            "name": "Uganda",
            "code": "UG",
            "iso3": "UGA",
            "nameAr": "أوغندا",
            "nameRu": "Уганда",
            "nameFr": "Ouganda"
        },
        {
            "id": 56,
            "name": "Ukraine",
            "code": "UA",
            "iso3": "UKR",
            "nameAr": "أوكرانيا",
            "nameRu": "Украина",
            "nameFr": null
        },
        {
            "id": 50,
            "name": "United Arab Emirates",
            "code": "AE",
            "iso3": "ARE",
            "nameAr": "الإمارات العربية المتحدة",
            "nameRu": "Объединенные Арабские Эмираты",
            "nameFr": "Emirats Arabes Unis"
        },
        {
            "id": 21,
            "name": "United Kingdom",
            "code": "GB",
            "iso3": "GBR",
            "nameAr": "المملكة المتحدة",
            "nameRu": "Великобритания",
            "nameFr": "Royaume-Uni"
        },
        {
            "id": 10,
            "name": "United States",
            "code": "US",
            "iso3": "USA",
            "nameAr": "الولايات المتحدة",
            "nameRu": "Соединенные Штаты",
            "nameFr": "États-Unis"
        },
        {
            "id": 85,
            "name": "Uruguay",
            "code": "UY",
            "iso3": "URY",
            "nameAr": "أوروغواي",
            "nameRu": "Уругвай",
            "nameFr": null
        },
        {
            "id": 66,
            "name": "Uzbekistan",
            "code": "UZ",
            "iso3": "UZB",
            "nameAr": "أوزبكستان",
            "nameRu": "Узбекистан",
            "nameFr": "Ouzbékistan"
        },
        {
            "id": 185,
            "name": "Vanuatu",
            "code": "VU",
            "iso3": "VUT",
            "nameAr": "فانواتو",
            "nameRu": "Вануату",
            "nameFr": null
        },
        {
            "id": 220,
            "name": "Vatican City",
            "code": "VA",
            "iso3": "VAT",
            "nameAr": "مدينة الفاتيكان",
            "nameRu": "Ватикан",
            "nameFr": "Cité du Vatican"
        },
        {
            "id": 63,
            "name": "Venezuela",
            "code": "VE",
            "iso3": "VEN",
            "nameAr": "فنزويلا",
            "nameRu": "Венесуэла",
            "nameFr": null
        },
        {
            "id": 18,
            "name": "Vietnam",
            "code": "VN",
            "iso3": "VNM",
            "nameAr": "فيتنام",
            "nameRu": "Вьетнам",
            "nameFr": "Viêt Nam"
        },
        {
            "id": 231,
            "name": "Wallis and Futuna",
            "code": "WF",
            "iso3": "WLF",
            "nameAr": "واليس وفوتونا",
            "nameRu": "Уоллис и Футуна",
            "nameFr": "Wallis et Futuna"
        },
        {
            "id": 238,
            "name": "West Bank",
            "code": "XW",
            "iso3": "XWB",
            "nameAr": "الضفة الغربية",
            "nameRu": "Западный берег",
            "nameFr": "banque de l'Ouest"
        },
        {
            "id": 57,
            "name": "Yemen",
            "code": "YE",
            "iso3": "YEM",
            "nameAr": "اليمن",
            "nameRu": "Йемен",
            "nameFr": "Yémen"
        },
        {
            "id": 67,
            "name": "Zambia",
            "code": "ZM",
            "iso3": "ZMB",
            "nameAr": "زامبيا",
            "nameRu": "Замбия",
            "nameFr": "Zambie"
        },
        {
            "id": 71,
            "name": "Zimbabwe",
            "code": "ZW",
            "iso3": "ZWE",
            "nameAr": "زيمبابوي",
            "nameRu": "Зимбабве",
            "nameFr": null
        }
    ];

    if(currentLanguage === 'ar') {
        items.sort(compareAr);
        vipItems.sort(compareAr);
    }
    
    if(currentLanguage === 'fr') {
        items.sort(compareFr);
        vipItems.sort(compareFr);
    }

    if(currentLanguage === 'Ru') {
        items.sort(compareRu);
        vipItems.sort(compareRu);
    }

    const children = vipItems.concat(items); 

    return children;
};


function compareAr( a, b ) {
    var textA = a.nameAr ? a.nameAr.toUpperCase() : a.name;
    var textB = b.nameAr ? b.nameAr.toUpperCase() : b.name;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

function compareFr( a, b ) {
    var textA = a.nameFr ? a.nameFr.toUpperCase() : a.name;
    var textB = b.nameFr ? b.nameFr.toUpperCase() : b.name;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

function compareRu( a, b ) {
    var textA = a.nameRu ? a.nameRu.toUpperCase() : a.name;
    var textB = b.nameRu ? b.nameRu.toUpperCase() : b.name;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export function getCountryNameByLang(country, currentLanguage, truncate = false) {
    var countryName = country.name;

    if (currentLanguage === "ar" && country.nameAr) {
        countryName =  country.nameAr;
    } 
    
    if (currentLanguage === "ru" && country.nameRu) {
        countryName =  country.nameRu;
    }

    if (currentLanguage === "fr" && country.nameFr) {
        countryName = country.nameFr;
    }
    
    if(truncate) {
        return truncString(countryName, 13);
    }

    return countryName;
};


export function getCityNameByLang(city, currentLanguage, truncate = false) {
    var cityVal = city.name;
    if (currentLanguage === "ar" && city.nameAr) {
        cityVal = city.nameAr;
    } 
    
    if (currentLanguage === "ru" && city.nameRu) {
        cityVal = city.nameRu;
    }

    if(truncate) {
        return truncString(cityVal, 13);
    }
    
    return cityVal;
};