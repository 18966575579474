import { useState, useContext } from "react";
import { getItem } from '../../../service/LocalStorage';
import axios from 'axios';
import DeleteActivity from "./deleteActivity";
import Auth from "../../../context/Auth";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../../../component/spinner/tevifSpinner";
import { Clipboard } from "@capacitor/clipboard";
import CommentActivity from "./activityComment";

const {REACT_APP_API_URL, REACT_APP_FRON_URL} = process.env;

function FooterActivity({
    activity,
    activities,
    setActivities,
    setIdActivityShowLikes,
    setIdCommentShowLikes
}) {
    let userToken = getItem('userToken')

    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    // id to delete
    const [idActivityToDelete, setIdActivityToDelete] = useState(0);
    const [deleteSpinner, setDeleteSpinner] = useState(0);

    const changeIdToDelete = async (id) => {
        setIdActivityToDelete(id)
    }

    const cancelDelete = async () => {
        setIdActivityToDelete(0)
    }

    const confirmDelete = async (id) => {
        setDeleteSpinner(id)

        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        axios.delete(`${REACT_APP_API_URL}/api/activities/${id}/delete`, config)
            .then (response => {
                if(response.status == 204) {
                    let newActivities = activities.filter(data => data.id != id);
                    setActivities(newActivities)
                }
            }).catch(err => {

            })

            setIdActivityToDelete(0)
            setDeleteSpinner(0)
    }

    const likeActivity = async (id) => {
        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        let newActivities = []
        axios.post(`${REACT_APP_API_URL}/api/activities/${id}/like`, null, config)
            .then (response => {
                activities.forEach(function callback(value, index) {
                    if(value.id == id) {
                        newActivities[index] = response.data; 
                    } else {
                        newActivities[index] = value;
                    }
                });

                setActivities(newActivities);
            })
    }


    const showLikeActivity = (id) => {
        setIdActivityShowLikes(id);
    }

    const [copied, setCopied] = useState(false);
    async function copyLinkActivity() {
        let uri = `${REACT_APP_FRON_URL}/activities/${activity.token}`;
        await Clipboard.write({
            string: uri
          });
        
        setCopied(true);
        setTimeout(function(){
            setCopied(false);
        }, 5000);
    }

    const {t} = useTranslation();

    return (
            <div className="post-meta">
                {isAuthenticated ? (
                    <div className="post-meta-bottom">
                        { deleteSpinner != activity.id ? (
                            <ul className="react-list">
                                <li className="react">
                                    <>
                                        <a onClick={() => likeActivity(activity.id)} style={activity.likedByCurrentUser ? {cursor: "pointer", color: "#f24570"} : {cursor: "pointer"}}>
                                            <i className="icofont-heart-alt" style={{fontSize: "25px"}}></i> 
                                        </a>    
                                        {(() => {
                                            if (activity.nbLikes > 0){
                                                let likeTxt = `${activity.nbLikes} ${t('people')}`;
                                                let nbLikeTxt = activity.nbLikes - 1;
                                                
                                                /*if(activity.likedByCurrentUser && nbLikeTxt === 0) {
                                                    return (
                                                        <>
                                                            <a style={activity.likedByCurrentUser ? {color: "#f24570"} : {cursor: "pointer"}}>
                                                                <span>&nbsp;&nbsp;{t('you')}</span>
                                                            </a>
                                                        </>
                                                    )
                                                } else if(activity.likedByCurrentUser && nbLikeTxt > 0) {
                                                    likeTxt = `${t('you_and')} ${nbLikeTxt} ${t('people')}`
                                                }*/
                                                return (
                                                    <a onClick={() => showLikeActivity(activity.id)} style={activity.likedByCurrentUser ? {cursor: "pointer", color: "#f24570"} : {cursor: "pointer"}}>
                                                        <span>&nbsp;&nbsp;{likeTxt}</span>
                                                    </a>
                                                )
                                            }
                                        })()}
                                    </>
                                </li>
                                <DeleteActivity
                                    idActivityToDelete={idActivityToDelete}
                                    activityId={activity.id}
                                    confirmDelete={confirmDelete}
                                    changeIdToDelete={changeIdToDelete}
                                    cancelDelete={cancelDelete}
                                    activityUserId={activity.user.id}
                                />
                                <li>
                                    {copied ? (
                                        <a onClick={copyLinkActivity} style={{cursor: "pointer"}}>
                                            <i className="icofont-verification-check"></i>
                                            &nbsp;&nbsp;{t('copied')}
                                        </a>
                                    ) : (
                                        <a onClick={copyLinkActivity} style={{cursor: "pointer"}}>
                                            <i className="icofont-share"></i>
                                            &nbsp;&nbsp;{t('share')}
                                        </a>
                                    )}
                                </li>
                            </ul>
                        ) : (
                        <ul className="react-list">
                            <li className="react">
                                <TevifSpinner />
                            </li>
                        </ul>)}
                    </div>
                ) 
                : null }
                <CommentActivity 
                    comments={activity.comments ?? []} 
                    prettyNbComments={activity.prettyNbComments}
                    activityId={activity.id}
                    setIdCommentShowLikes={setIdCommentShowLikes}
                />
            </div>
        );
}

export default FooterActivity;