import { Fragment, useContext, useEffect, useRef, useState } from "react";
import Header from "../../component/layout/headertwo";
import FilterSearch from "../../component/sidebar/filtersearch";
import Media from "./media";
import Activity from './activity';
import FormEditProfile from './currentUser/formEditProfile';
import Auth from "../../context/Auth";
import { getInitialMedias, getInitialUserData } from "../../service/User";
import { getItem } from "../../service/LocalStorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import Followers from "./currentUser/followers";
import Footer from "../../component/layout/mobilefooter";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../service/Window";
import BlockedUsers from "./currentUser/blocked-users";
import ViewProfile from "./currentUser/viewProfile";
import AppSectionTwo from "../../component/section/appsectiontwo";
import LightMode from "../../context/LightMode";
import { Clipboard } from "@capacitor/clipboard";
import InitializeAndResumeBanner from "../../component/adMob/initializeBanner";
import TevifSpinner from "../../component/spinner/tevifSpinner";
import UpdateProfilePhoto from "../../component/camera/updateProfilePhoto";
import { getProfileName } from "../../service/String";

const {REACT_APP_API_URL, REACT_APP_FRON_URL} = process.env;

function ProfileCurrentUser() { 
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    let userToken = getItem('userToken');

    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const { isLightMode, setIsLightMode } = useContext(LightMode);
    const [user, setUser] = useState(getInitialUserData());
    const [activities, setActivities] = useState([]);
    const [medias, setMedias] = useState(getInitialMedias());
    const [totalMedia, setTotalMedia] = useState(0);
    const [nbFollowers, setNbFollowers] = useState(0);
    const [nbBlockedUsers, setNbBlockedUsers] = useState(0);
    const [nbViewProfile, setNbViewProfile] = useState(0);
    const [showFormEditProfil, setShowFormEditProfil] = useState(queryParameters.get("editProfile")?? null);
    const [spinnerActivities, setSpinnerActivities] = useState(false);
    const [width, height] = useWindowSize();
    const dataFetchedRef = useRef(false);
    const [copied, setCopied] = useState(false);

    async function fetchUser() {
        let config = { 
            "Content-Type" : "application/json",
            "Authorization": "Bearer "+ userToken 
        };
        
        let url = `${REACT_APP_API_URL}/api/users/current`;

        try {
            setSpinnerActivities(true);
            const userResponse = await fetch(url, {
                method: "GET",
                headers: config
            })
            
            if(userResponse.status === 404) {
                navigate('/user-not-found');
            }

            const userData = await userResponse.json();
            setUser(userData);
            setActivities(userData.activities.items);
            setMedias(userData.mediasInfo);
            setTotalMedia(userData.mediasInfo.totalMedia);
            setNbFollowers(userData.prettyNbFollow);
            setNbViewProfile(userData.nbViewProfile);
            setSpinnerActivities(false);
        } catch (err) {
            navigate('/user-not-found');
        } 
      }
      
    useEffect(() => {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      fetchUser();
    }, [])
    
    const {t} = useTranslation();

    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    async function copyLinkProfile() {
        let uri = `${REACT_APP_FRON_URL}/profile/${encodeURIComponent(user.username)}`;
        await Clipboard.write({string: uri});
        setCopied(true);

        setTimeout(function(){
            setCopied(false);
        }, 5000);
    }

    return (
        <Fragment>
            <Header />
            <InitializeAndResumeBanner />
                <section className="profile-section padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="member-profile">
                                <div className="profile-item">
                                    <div className="profile-cover">
                                        {isLightMode ? (<img src="../assets/images/profile/transparent_cover_light_mode.jpg" alt="cover-pic" />) : (<img src="../assets/images/profile/transparent_cover.jpg" alt="cover-pic" />)}
                                    </div>
                                    <div className="profile-information">
                                        <UpdateProfilePhoto
                                            currentPhoto={user.publicPathProfilePicture}
                                            setUser={setUser}
                                            width={width}
                                         />   
                                        <div className="profile-name">
                                            <h4>{getProfileName(user.username, user.firstName ?? '', user.lastName ?? '', false)}{user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null}</h4>
                                        </div>
                                        <ul className="profile-contact">
                                                <li>
                                                {copied ? (
                                                        <a onClick={copyLinkProfile} style={{cursor: "pointer"}}>
                                                            <div className="icon"><i className="icofont-verification-check"></i></div>
                                                            <div className="text">
                                                                <p>{t('copied')}</p>
                                                            </div>
                                                        </a>
                                                    ) : (
                                                        <a onClick={copyLinkProfile} style={{cursor: "pointer"}}>
                                                            <div className="icon"><i className="icofont-share"></i></div>
                                                            <div className="text">
                                                                <p>{t('share_profile')}</p>
                                                            </div>
                                                        </a>
                                                    )}
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="profile-details">
                                    <nav className="profile-nav">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className={showFormEditProfil ? "nav-link" : "nav-link active"} id="nav-ativity-tab" data-bs-toggle="tab"
                                                data-bs-target="#activity" type="button" role="tab" aria-controls="activity"
                                                aria-selected="true">{t('activity')}</button>
                                            <button className={showFormEditProfil ? "nav-link active" : "nav-link"} id="nav-profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false">{t('edit_profile')}</button>
                                            <button className="nav-link" id="nav-media-tab" data-bs-toggle="tab" data-bs-target="#media"
                                                type="button" role="tab" aria-controls="media" aria-selected="false">{t('photo')}/{t('video')} <span
                                                    className="item-number">{totalMedia}</span></button>        
                                            <div className="dropdown">
                                                <a className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    {t('more')}
                                                </a>

                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <li>
                                                        <button className="nav-link dropdown-item" id="nav-viewprofile-users-tab" data-bs-toggle="tab"
                                                        data-bs-target="#viewprofile" type="button" role="tab" aria-controls="viewprofile"
                                                        aria-selected="false">{t('visitors_to_your_profile')} <span className="item-number">{nbViewProfile}</span></button>
                                                    </li>
                                                    <li>
                                                        <button className="nav-link  dropdown-item" id="nav-followers-tab" data-bs-toggle="tab"
                                                        data-bs-target="#followers" type="button" role="tab" aria-controls="followers"
                                                        aria-selected="false">{t('followers')} <span className="item-number">{nbFollowers}</span></button>
                                                    </li>
                                                    <li>
                                                        <button className="nav-link dropdown-item" id="nav-blocked-users-tab" data-bs-toggle="tab"
                                                        data-bs-target="#blocked-users" type="button" role="tab" aria-controls="blocked-users"
                                                        aria-selected="false">{t('blocked_users')} <span className="item-number">{nbBlockedUsers}</span></button>
                                                    </li>
                                                </ul>
                                            </div>       
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className={(showFormEditProfil) ? "tab-pane activity-page fade show" : "tab-pane activity-page fade show active"} id="activity" role="tabpanel">
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <article>
                                                            <div className="activity-tab">
                                                            {
                                                                spinnerActivities ? (
                                                                    <TevifSpinner />
                                                                ) : (
                                                                    <Activity 
                                                                        userProfile={user}
                                                                        activities={activities}
                                                                        setActivities={setActivities}
                                                                        setMedias={setMedias}
                                                                        isOwner={true}
                                                                        isAuthenticated={isAuthenticated}
                                                                    />
                                                                )}
                                                            </div>
                                                        </article>
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch 
                                                                user={user}
                                                            />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={showFormEditProfil ? "tab-pane fade active show" : "tab-pane fade"} id="profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <article>
                                                            <div className="info-card mb-20">
                                                                <FormEditProfile 
                                                                    user={user}
                                                                    setUser={setUser}
                                                                />
                                                            </div>
                                                        </article>
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch user={user} />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                        <Media 
                                            medias={medias} 
                                            userId={user.id} 
                                            isAuthenticated={isAuthenticated}
                                            setTotalMedia={setTotalMedia}
                                            totalMedia={totalMedia}
                                            isOwner={true}
                                            user={user}
                                        />
                                        <Followers user={user} />
                                        <BlockedUsers 
                                            user={user} 
                                            setNbBlockedUsers={setNbBlockedUsers}
                                        />
                                        <ViewProfile 
                                            user={user} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <AppSectionTwo />    
            <Footer />
        </Fragment>
    );
}
 
export default ProfileCurrentUser;