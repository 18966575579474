import { useEffect, useState } from "react";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useTranslation } from "react-i18next";
import { getItem } from "../../service/LocalStorage";
import axios from "axios";

const {REACT_APP_API_URL} = process.env;

function UpdateProfilePhoto({currentPhoto, setUser, width}) {    
    
    const {t} = useTranslation();
    let userToken = getItem('userToken');

    const [selectedPhoto, setSelectedPhoto] = useState("");
    const [formFile, setFormFile] = useState(null);
    const [spinnerChangePhoto, setSpinnerChangePhoto] = useState(false);

    useEffect(() => {
        setSelectedPhoto(currentPhoto);
    }, [currentPhoto]);

    const changeProfileImage = (e) => {
      try {
          if(e.target.files[0]) {
            setFormFile(e.target.files[0]);
            setSelectedPhoto(URL.createObjectURL(e.target.files[0]));
          }
      } catch(err) {
          //ignore
      }
  }

  const cancelUpdatePhoto = (e) => {
      setSelectedPhoto(currentPhoto);
      setFormFile(null);
  }

    const takePicture = async () => {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt 
        });
      
        setSelectedPhoto(image.webPath);
        setFormFile(image.path);
      };

    const updatePhoto = async () => {
        if(formFile) {
            setSpinnerChangePhoto(true);
            const formData = new FormData();
            formData.append('profileImage', formFile);
            const config = {     
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer "+ userToken 
                }
            }
            
            axios.post(`${REACT_APP_API_URL}/api/user-update/photo`, formData, config)
            .then (response => {
                setUser(response.data);
                setSelectedPhoto(response.data.publicPathProfilePicture);
                setSpinnerChangePhoto(false);
                setFormFile(null);
            }).catch(err => {
                setFormFile(null);
                setSpinnerChangePhoto(false);
            })
        }
    }

    return (
        <>
        {width > 991 ? (
            <div className="profile-pic">
                {selectedPhoto ? <img src={selectedPhoto} alt="edit-photo" /> : null}
                {spinnerChangePhoto ? null : (
                    <>
                        {formFile ? (
                            <div className="custom-upload" style={{opacity: 1}}>
                                <div className="file-btn">
                                    <span className="d-lg-inline-block" style={{cursor: "pointer"}} onClick={updatePhoto}> 
                                        <i className="icofont-check icofont-2x"></i>
                                    </span> 
                                    <span className="d-lg-inline-block" style={{marginLeft:"10px", cursor: "pointer"}}  onClick={cancelUpdatePhoto}> 
                                        <i className="icofont-close-circled icofont-2x"></i>
                                    </span>    
                                </div>
                            </div>
                        ) : (
                            <div className="custom-upload" style={{opacity: 1}}>
                                <div className="file-btn">
                                    <label htmlFor="user_photo">
                                        <span className="d-lg-inline-block"> <i className="icofont-camera"></i>
                                            {t('edit')}</span>
                                        <input type="file" id="user_photo" onChange={changeProfileImage} accept="image/*" />
                                    </label>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            ) : (
                <>
                    <div className="profile-pic">
                        {selectedPhoto ? <img src={selectedPhoto} alt="edit-photo" /> : null}
                    </div>            
                    {spinnerChangePhoto ? null : (
                        <div className="form-group" style={{marginTop: "10px"}}>
                        {formFile ? (
                            <>
                                <span className="validate-profile-pic-custom-upload d-lg-inline-block" style={{cursor: "pointer"}} onClick={updatePhoto}> 
                                    <i className="icofont-check"></i>
                                    {t('confirm')}
                                </span>
                                <span className="validate-profile-pic-custom-upload d-lg-inline-block" style={{marginLeft:"10px", cursor: "pointer"}}  onClick={cancelUpdatePhoto}> 
                                    <i className="icofont-close-circled"></i>
                                    {t('cancel')}
                                </span>
                            </>
                        ) : (
                            <div className="custom-upload">
                                <div className="file-btn">
                                    <label htmlFor="user_photo" className="validate-profile-pic-custom-upload">
                                        <span className="d-lg-inline-block"> <i className="icofont-camera"></i>
                                            {t('edit')}</span>
                                        <input type="file" id="user_photo" onChange={changeProfileImage} accept="image/*" />
                                    </label>
                                </div>
                            </div>
                        )}
                        </div>
                    )}
                </>
            )
        }
        </>
    );    
}
 
export default UpdateProfilePhoto;