import { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../component/layout/headertwo";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../service/Window";
import Footer from "../component/layout/mobilefooter";


const {REACT_APP_FRON_URL} = process.env;


function ErrorPage() {  
    const [width, height] = useWindowSize();
    const {t} = useTranslation();

    return (
        <Fragment>
            <Header />
            <section className="fore-zero padding-tb"  style={width < 576 ? {marginTop: "35px"} : {}}>
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-thumb">
                                <img src={REACT_APP_FRON_URL+"/assets/images/404.png"} alt="404" />
                            </div>
                            <div className="zero-content">
                                <h2>{t('oops_this_page_not_found')}</h2>
                                <p>{t('we_are_really_sorry_but_the_page_you_requested_is_missing')} <i className="icofont-worried"></i></p>
                                <Link to="/" className="lab-btn"><span>{t('go_back_to_home')}</span> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );
}
 
export default ErrorPage;