import { PhotoView } from "react-photo-view";

export default function ShowMediaInUserProfile({
    mediaType, path
}) {


    if(mediaType == 1) {
        return (
            <PhotoView src={path}>
                <img 
                    src={path} 
                    alt="img" 
                />
            </PhotoView>
        );
    }
        

    if(mediaType == 2) {
        return (
            <video 
                width="100%" 
                controls 
                src={path}
            ></video>
        );
    }

    return null;
}
