export function mergeAndRemovingDuplicate(array1, array2) {
    let ids = [];
    for (let i=0; i<array1.length; i++) {
        ids.push(array1[i].id);
    }
   
    for (let i=0; i<array2.length; i++) {
        if (ids.indexOf(array2[i].id)===-1) {
            array1.push(array2[i]);
        }
    }
                    
    return array1;
}


export function deleteObjectByIDFromArray(arr, id) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
            arr.splice(i, 1); 
            break; 
        }
    }

    return arr;
}


export function getElementOfArrayById(arr, position) {
    for (var i = 0; i < arr.length; i++) {
        if (i === position) {
            return arr[i];  
        }
    }

    return null;
}
