import { Carousel } from '@trendyol-js/react-carousel';
import { useEffect, useRef, useState } from 'react';
import { getHeadersApiListingUsers } from '../../service/User';
import { useWindowSize } from '../../service/Window';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VideoActivity from '../media/video';
import axios from 'axios';
import { getItem } from '../../service/LocalStorage';
import TevifSpinner from '../../component/spinner/tevifSpinner';

const { REACT_APP_API_URL } = process.env;

export default function CarouselReels({currentUser}) {

    const inputRef = useRef(null);
    const navigate = useNavigate();

    const [themes, setThemes] = useState([]);
    const [width] = useWindowSize();
    const [styleImg, setStyleImg] = useState({ borderRadius: "10px", margin: "0 10px" });
    const apiHeaders = getHeadersApiListingUsers();
    const [formFile, setFormFile] = useState(null);
    const [errorFormDesc,setErrorFormDesc] = useState("");
    const [fileBackgroundImage, setFileBackgroundImage] = useState("");
    const [pathMediaSelected, setPathMediaSelected] = useState("");
    const [popupClassNameCarousel, setPopupClassNameCarousel] = useState("popup");

    const [spinner, setSpinner] = useState(false);

    let userToken = getItem('userToken');

    useEffect(() => {
        if(currentUser && currentUser.publicPathCropedProfilePicture) {
            setFileBackgroundImage(currentUser.publicPathCropedProfilePicture);
        }
    }, [currentUser]);


    function getThemes() {
        fetch(`${REACT_APP_API_URL}/api/theme/list`, {
            method: "GET",
            headers: apiHeaders,
        })
        .then(response => response.json())
        .then((result) => {
            var listItems = result.items;
            if (listItems) {
                listItems.pop();
            }

            // Ajouter l'élément de formulaire au début du tableau
            const uploadFormElement = {
                isForm: true
            };
            listItems.unshift(uploadFormElement);

            setThemes(listItems);
        }).catch((error) => {
            console.error('Erreur:', error);
        });
    }

    useEffect(() => {
        getThemes();

        if (width < 350) {
            setStyleImg({ height: "124px", width: "70px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 400) {
            setStyleImg({ height: "160px", width: "90px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 450) {
            setStyleImg({ height: "177px", width: "100px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 500) {
            setStyleImg({ height: "204px", width: "115px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 550) {
            setStyleImg({ height: "222px", width: "125px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 600) {
            setStyleImg({ height: "240px", width: "135px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 650) {
            setStyleImg({ height: "240px", width: "135px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 700) {
            setStyleImg({ height: "240px", width: "135px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 750) {
            setStyleImg({ height: "240px", width: "135px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 800) {
            setStyleImg({ height: "240px", width: "135px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 850) {
            setStyleImg({ height: "328px", width: "185px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 900) {
            setStyleImg({ height: "337px", width: "190px", borderRadius: "10px", margin: "0 10px" });
        } else if (width < 950) {
            setStyleImg({ height: "337px", width: "190px", borderRadius: "10px", margin: "0 10px" });
        } else {
            setStyleImg({ height: "400px", width: "225px", borderRadius: "10px", margin: "0 10px" });
        }
    }, [width]);


    const labelStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',
        borderRadius: '10px',
        width: '100%',
        height: '100%'
    };

    const {t} = useTranslation();



    const handleFileUpload = (e) => {
        setErrorFormDesc("");
        const file = e.target.files[0]
        
        if(file.size) {
            var sizeMo = (file.size)/1000000; 
            if(sizeMo > 300) {
                setErrorFormDesc(t("the_file_is_too_large_allowed_maximum_size_is_300_mb"))
                setFormFile("");
            } else {
                setFormFile(e.target.files[0])                
            }

            setPathMediaSelected(URL.createObjectURL(e.target.files[0]));
            setPopupClassNameCarousel("popup open");
        }
    };

    const closePopup = () => {
        setErrorFormDesc("");
        setPathMediaSelected("");
        setPopupClassNameCarousel("popup");
        setFormFile("");
        inputRef.current.value = null;
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setErrorFormDesc("");
        setSpinner(true);
        
        if(!formFile) {
            setErrorFormDesc(t('no_data_selected'));
            setSpinner(false);
        } else {
            const formData = new FormData();
		    formData.append('file', formFile);
            formData.append('description', "");
            formData.append('is_reel', "yes");

            const config = {     
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer "+ userToken 
                }
            }
            
            axios.post(`${REACT_APP_API_URL}/api/activities`, formData, config)
            .then (response => {
                let responseData = response.data;
                var lastActivity =  responseData.activities.items[0];
                setSpinner(false);
                closePopup();
                navigate(`/reels/${lastActivity.token}?id=${lastActivity.id}`)
                
            }).catch(err => {
                if(err.response.data.code === 400) {
                    setErrorFormDesc(t('the_files_accepted_are_image_or_video_and_maximum_size_less_than_or_equal_to_300_MB'));
                    setFormFile("");
                } else {
                    setErrorFormDesc(t('an_error_has_occurred_please_retry_later'));
                }
                setSpinner(false);
            })
        }
    }

    return (
        <>
            {themes && themes.length > 0 ? (
                <>
                    <Carousel show={3.5} slide={3} swiping={true} responsive={true} rightArrow={false} leftArrow={false}>
                        {themes.map((theme, index) => (
                            theme.isForm ? (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundImage: `url("${fileBackgroundImage}")`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    opacity: 0.5,
                                    ...styleImg
                                }}>
                                    <label htmlFor="file-upload" style={{ ...labelStyle, cursor: 'pointer' }}>
                                        <span style={{marginLeft: "10px"}}>{t('create_your_reality')}</span>
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                        ref={inputRef}
                                    />
                                </div>
                            ) : (
                                <NavLink to={`/reels/${theme.token}?id=${theme.reelId}`} key={index}>
                                    <div key={index}>
                                        <img src={theme.reelPoster} alt="poster" style={styleImg} />
                                    </div>

                                    <p style={{ position: 'absolute', zIndex: 999, marginTop: "-30px", marginLeft: "25px", color: "#fff" }}>
                                        <i className="icofont-ui-play"></i>&nbsp;{theme.reelNbView}
                                    </p>
                                </NavLink>
                            )
                        ))}
                    </Carousel>

                    {/* /////////////////////////////////////////////////////////////////////////////// */}
                        <div className={popupClassNameCarousel}>
                            <div className="container">
                                <div className="popup__area">
                                    <div className="popup__close" onClick={closePopup}>
                                        <i className="icofont-close"></i>
                                    </div>
                                    <div className="row">
                                        <div className="popup__fild">
                                            <h4 className="mb-4">{t('create_a_story')}</h4>
                                            <div className="widget widget-post" style={{
                                                    boxShadow: "none",
                                                    width: "auto", 
                                                    maxHeight: "700px",
                                                    overflow: "scroll"
                                                }}>
                                                    <p style={{color: "#f24570"}}>{errorFormDesc}</p>
                                                                {formFile ? (
                                                                    <VideoActivity
                                                                    styleObject={{
                                                                        maxHeight: "500px",
                                                                        width: "auto",
                                                                        marginLeft: "auto",
                                                                        marginRight: "auto",
                                                                        display: "block",
                                                                        borderRadius: "10px"
                                                                    }} 
                                                                    path={pathMediaSelected}
                                                                    poster={null}
                                                                />
                                                                ) : null}
                                                <ul className="lab-ul widget-wrapper  row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                                    <form className="post-form form-activity" onSubmit={handleSubmit}>
                                                        <li className="post-submit">
                                                        {spinner ? <TevifSpinner /> : 
                                                        <input type="submit" value={t('post')} className="lab-btn" style={{backgroundColor: "#f24570"}}/>
                                                        }
                                                        </li>
                                                    </form>    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>            
                        </div>
                    {/* /////////////////////////////////////////////////////////////////////////////// */}
                </>
            ) : null}
        </>
    )
}
