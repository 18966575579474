import { Component, useContext } from "react";
import { Link } from "react-router-dom";
import Auth from "../../context/Auth";
import { useTranslation } from "react-i18next";

const btnText = "Registration Now";


function BannerTwo () {

    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const {t} = useTranslation();

    return (
        <section className="banner-section style2 bgimg" style={{backgroundImage: "url(/assets/images/banner/bg2.jpg)"}}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-end">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <div className="intro-form">
                                    <h2><span className="theme-color">{t('location_based_social_network')}</span></h2>
                                    <ul>
                                        <li key="li_banner_1"><span style={{color:"#f24570"}}><b>{t('explore_your_neighborhood_and_its_news')}</b></span></li>
                                        <li key="li_banner_2"><b>{t('discover_the_people_around_you')}</b></li>
                                        <li key="li_banner_3"><b>{t('share_your_experiences_and_opinions_with_the_community')}</b></li>
                                        <li key="li_banner_4"><b>{t('share_your_moments_of_life_and_your_adventures_with_an_engaged_community')}</b></li>
                                        <li key="li_banner_5"><b>{t('connect_with_the_world_around_you_free_and_effortless')}</b></li>
                                    </ul>
                                    {!isAuthenticated ? (
                                        <ul>
                                            <li>
                                                <Link to="/signup" className="lab-btn"><span>{t('registration_now')}</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/login" className="lab-btn"><span>{t('login')}</span></Link>    
                                            </li>
                                        </ul>
                                        ): null }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-thumb">
                                {/*<img src="assets/images/banner/01.png" alt="img" />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default BannerTwo;