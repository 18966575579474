import jwtDecode from "jwt-decode";
import { getItem } from "../../../service/LocalStorage";
import { useTranslation } from "react-i18next";

function DeleteActivity({
    idActivityToDelete,
    activityId,
    confirmDelete,
    changeIdToDelete,
    cancelDelete,
    activityUserId
}) {

    const userToken = getItem('userToken');
    let currentUserId = null;
    if(userToken) {
        currentUserId = jwtDecode(userToken).user_id;
    }


    const {t} = useTranslation();
    if (currentUserId !== activityUserId) {
        return null;
    }

    if(idActivityToDelete != activityId) {
        return (
            <li className="react">
                    <a  style={{cursor: "pointer"}} onClick={() => changeIdToDelete(activityId)}>
                        <i className="icofont-trash"></i> {t('delete')}
                    </a>
            </li>
        );
    } else {
        return (
            <li className="react"> 
                <a  style={{
                        cursor: "pointer",
                        color: "#f24570",
                        fontWeight: "bold"
                    }}
                    onClick={() => confirmDelete(activityId)}
                >
                    &nbsp;{t('confirm')}&nbsp;
                </a>
                <a  style={{
                        cursor: "pointer",
                        color: "#f2aaaa",
                        fontWeight: "bold"
                    }}
                    onClick={() => cancelDelete()}
                >
                    &nbsp;{t('cancel')}&nbsp;
                </a>
            </li>
        );
    }
    

    return null
}

export default DeleteActivity;