import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getProfileName } from "../../service/String";


function Member() {
    const [lovelyUsers, setLovelyUsers] = useState(null);
    
    useEffect(() => {
        async function fetchLovelyUsers() {        
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/users/public/lovely-members`, 
                    {
                        method: "GET"
                    }
                )
                const data = await response.json();
                setLovelyUsers(data.items);
            } catch (err) {} 
        }
        fetchLovelyUsers();
    
    }, []);

    const {t} = useTranslation();
    
    return (
        <section className="member-section padding-tb">
            <div className="container">
                <div className="section-header">
                    <h2>{t('meet_our_lovely_members')}</h2>
                    <p>{t('meet_our_lovely_members_description')}</p>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center g-3 g-md-4 row-cols-xl-5 row-cols-md-3 row-cols-2">
                        {lovelyUsers ? lovelyUsers.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="lab-item member-item style-1">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <Link to={`/profile/${val.username}`}><img src={`${val.profilePicture}`} alt={`${val.profileUsername}`} style={{maxHeight: "293px"}} /></Link>
                                        </div>
                                        <div className="lab-content">
                                            <h6><Link to={`/profile/${val.username}`}>{getProfileName(val.username, val.firstName ?? '', val.lastName ?? '', true)}{val.verified ? <i className="icofont-check" title="Verify Profile"></i> : null}</Link> </h6>
                                            <p>{val.age},&nbsp;
                                                {(() => {
                                                    if (val.gender == 1) {
                                                        return (t('male'));
                                                    } else if (val.gender === 2) {
                                                        return (t('female'));
                                                    } else {
                                                        return (t('other'));
                                                    }
                                                })()}
                                                </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                    </div>
                    <div className="member-button-group d-flex flex-wrap justify-content-center">
                        <Link to="/members-classic-mode" className="lab-btn"><span>{t('discover_all_members')}</span></Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Member;