const appLanguages = [
    'fr',
    'en', 
    'ar',
    'es',
    'ru',
    'tr',
    'it', 
    'de'
];

export function getLangFromUrl() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlLang = urlParams.get('lang');
    
    if(appLanguages.includes(urlParams.get('lang'))) {
        return urlLang;
    }

    return 'en';
}

export function getAppLanguages() {
    return appLanguages;
}