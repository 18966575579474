import { useEffect } from "react";
import { hideBanner } from "./adMob";

export default function InitializeAndHideBanner() {
    useEffect(() => {
		const initializeAndHideBanner = async () => {
			await hideBanner();
		};
	
		initializeAndHideBanner();
	}, []);
}