import { NavLink } from "react-router-dom";
import FooterActivity from "./footerActivity";
import MediaActivity from "./mediaActivity";
import jwtDecode from "jwt-decode";
import { getItem } from "../../../service/LocalStorage";
import { useTranslation } from "react-i18next";
import { getCityNameByLang, getCountryNameByLang } from "../../../service/CityApi";
import TextWithUrlDetection from "../../../component/text/textWithUrlDetection";
import { getProfileName } from "../../../service/String";

export default function ActivityBody({activity, activities, setActivities, setIdActivityShowLikes, setIdCommentShowLikes})
{
    const userToken = getItem('userToken');
    const currentUserName = userToken ? jwtDecode(userToken).username : null;
    
    let urlProfile = `/profile/${activity.user.username}`;

    if(activity.user.username === currentUserName) {
        urlProfile = `/profile`;
    }

    const currentLanguage = getItem('i18nextLng') ?? "en";

    const {t} = useTranslation();

    const activityCity = activity.city;
    return (
        <>
            <div className="post-content">
                <div className="post-author">
                    <div className="post-author-inner">
                        <div className="author-thumb">
                            <NavLink to={urlProfile} >
                                <img 
                                    src={activity.user.publicPathCropedProfilePicture} 
                                    alt="img" 
                                    className="rounded-circle"
                                />
                            </NavLink>       
                        </div>
                        <div className="author-details">
                            <h6>
                                <NavLink to={urlProfile} >
                                    {getProfileName(activity.user.username, activity.user.firstName ?? '', activity.user.lastName ?? '', true)}{activity.user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }
                                </NavLink>
                            </h6>
                            <ul className="post-status">
                                <li className="post-privacy">
                                    {getCityNameByLang(activityCity, currentLanguage, true)},&nbsp;{getCountryNameByLang(activityCity.country, currentLanguage, true)}
                                </li>&nbsp;
                            </ul>
                            <ul className="post-status">
                                <li className="post-time">
                                {(() => {                                        
                                        if (currentLanguage === "en") {
                                            return (
                                            <>
                                                {activity.creationDateInArray.int_val ? (<>{activity.creationDateInArray.int_val}</>) : null} {t(activity.creationDateInArray.str_val)}                                            
                                                {activity.creationDateInArray.str_val !== "now" ? (<>&nbsp;{t('ago')}</>) : null}
                                            </>); 
                                        }

                                        return (<>
                                        {activity.creationDateInArray.str_val !== "now" ? (<>{t('ago')}&nbsp;</>) : null}
                                        {activity.creationDateInArray.int_val ? (<>{activity.creationDateInArray.int_val}</>) : null} {t(activity.creationDateInArray.str_val)}</>)
                                    })()}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="post-description">
                    <p className="text_wrap"><TextWithUrlDetection text={activity.description} /></p>
                    <MediaActivity
                        media={activity.media}
                        poster={!activity.publicPosterUrl ? null : activity.publicPosterUrl}
                    />
                </div>
            </div>
            <FooterActivity 
                activity={activity}
                setActivities={setActivities}
                activities={activities}
                setIdActivityShowLikes={setIdActivityShowLikes}
                setIdCommentShowLikes={setIdCommentShowLikes}
            />
        </>
    );
}