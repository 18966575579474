import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getHeadersApiListingUsers } from '../../service/User';
import { getItem } from '../../service/LocalStorage';
import TevifSpinner from '../spinner/tevifSpinner';

const {REACT_APP_API_URL} = process.env; 

function ShowNotificationUser({openPopUpNotif, setOpenPopUpNotif, nbUnViewedNotification}) {
    const navigate = useNavigate();

    let userToken = getItem('userToken');
    let currentUserIdentifier = null
    if(userToken) {
        currentUserIdentifier = jwtDecode(userToken).username;
    }   

    
    const [spinner, setSpinner] = useState(false);
    const [spinnerMore, setSpinnerMore] = useState(false);
    
    const [notifications, setNotifications] = useState([]);
    const [popupClassNameNotif, setPopupClassNameNotif] = useState("popup");
    const [showLoadMore, setShowLoadMore] = useState(true);
    const apiHeaders = getHeadersApiListingUsers();
    const [page, setPage] = useState(1);


    useEffect(() => {
        async function getNotifications() {
            setSpinner(true);
            try {
                const userResponse = await fetch(`${REACT_APP_API_URL}/api/notification-user/list?order_field=id&order_direction=DESC&limit=10`, {
                    method: "GET",
                    headers: apiHeaders
                })
                
                const data = await userResponse.json();
                setNotifications(data);         
                setSpinner(false);
                setPopupClassNameNotif("popup open");
            
            } catch (err) {
                setSpinner(false);
            } 
        }
        
        if (openPopUpNotif === 0) {
            setPopupClassNameNotif("popup");

            setNotifications([]);

        } else if (openPopUpNotif > 0) {
            getNotifications();
        }
    }, [openPopUpNotif]);

    const closePopup = () => {
        setOpenPopUpNotif(0);
        setPopupClassNameNotif("popup");
        setNotifications([]);
    }

    const {t} = useTranslation();
    const currentLanguage = getItem('i18nextLng') ?? "en";

    
    const markNotifAsReadAndDoRedirection = (idNotification, linkToRedirect) => {
        fetch(`${REACT_APP_API_URL}/api/notification-user/${idNotification}/mark-as-viewed`, {
            method: "POST",
            headers: apiHeaders,
            }).then(() => {
                navigate(linkToRedirect);
            }).catch((error) => {
                navigate(linkToRedirect);
            });
        ; 
    }

    const getNoptifications = () => {
        let newPage = page + 1;
        setPage(newPage);
        setSpinnerMore(true);

        fetch(`${REACT_APP_API_URL}/api/notification-user/list?order_field=id&order_direction=DESC&limit=10&page=${newPage}`, {
            method: "GET",
            headers: apiHeaders,
          })
          .then(function(response){
            if(response.status === 200) {
                return response.json();
            } else {
                setSpinnerMore(false);
            }
          })
          .then(function(data){
            if(data.length > 0) {
                let newNotifications = [...data, ...notifications];
        
                setNotifications(newNotifications);
            } else {
                setShowLoadMore(false);
            }

            setSpinnerMore(false);
          })
    }

    return (
        <>
        <div className={popupClassNameNotif}>
            <div className="container">
                <div className="popup__area">
                    <div className="popup__close" onClick={closePopup}>
                        <i className="icofont-close"></i>
                    </div>
                    <div className="row">
                        <div className="popup__fild">
                            <h4 className="mb-4">{t('notifications')} {`(${nbUnViewedNotification}  ${t('unread')})`} </h4>
                            {spinner ? (<TevifSpinner/>) : null}               
                            <div className="widget widget-post" style={{
                                    boxShadow: "none",
                                    width: "auto", 
                                    maxHeight: "300px",
                                    overflow: "scroll"
                                }}>
                                <ul className="lab-ul widget-wrapper  row row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-4">
                                    {notifications.map((notification, i) => {
                                        return (
                                            <li className="d-flex flex-wrap" key={`notification_${i}`} style={{cursor: "pointer"}} onClick={() => markNotifAsReadAndDoRedirection(notification.id, notification.link)}>
                                                <div className="post-thumb">
                                                    <img 
                                                        src={notification.poster} 
                                                        alt={notification.userSourceIdentifier} 
                                                        className="rounded-circle"
                                                    />
                                                </div>
                                                <div className="post-content ps-4">
                                                    <h6 style={notification.viewed ? {}: {color:"#f24570"}}>{t(notification.title)}</h6>  
                                                    <p style={notification.viewed ? {}: {color:"#f24570"}}>
                                                        {notification.userSourceIdentifier} {t(notification.description)}
                                                    </p>
                                                    <p style={notification.viewed ? {}: {color:"#f24570"}}>
                                                    {(() => {                                        
                                                        if (currentLanguage === "en") {
                                                            return (
                                                            <>
                                                                {notification.creationDateInArray.int_val ? (<>{notification.creationDateInArray.int_val}</>) : null} {t(notification.creationDateInArray.str_val)}                                            
                                                                {notification.creationDateInArray.str_val !== "now" ? (<>&nbsp;{t('ago')}</>) : null}
                                                            </>); 
                                                        }

                                                        return (<>
                                                        {notification.creationDateInArray.str_val !== "now" ? (<>{t('ago')}&nbsp;</>) : null}
                                                        {notification.creationDateInArray.int_val ? (<>{notification.creationDateInArray.int_val}</>) : null} {t(notification.creationDateInArray.str_val)}</>)
                                                    })()}
                                                    </p>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                                {spinnerMore ? (<TevifSpinner/>) : null}  
                                {showLoadMore ? (
                                <div className="load-btn">
                                    <a className="lab-btn" onClick={getNoptifications}><i className="icofont-plus"></i> {t('load_more')}</a>
                                </div>
                                ): 
                                (
                                    <div className="load-btn">
                                        <p>{t('no_more_data_to_load')}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        </>
    );
}

export default ShowNotificationUser;