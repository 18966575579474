import { Fragment, useEffect, useState } from "react";
import HeaderTwo from "../component/layout/headertwo";
import Footer from "../component/layout/mobilefooter";
import { useWindowSize } from "../service/Window";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";
import { useTranslation } from "react-i18next";
import { getHeadersApiListingUsers } from "../service/User";
import { validateEmail, validateNumber } from "../service/String";
import axios from "axios";
import TevifSpinner from "../component/spinner/tevifSpinner";


const {REACT_APP_API_URL} = process.env;

export default function ValidateProfile() {
    const [width, height] = useWindowSize();
    let marginValue = (process.env.REACT_APP_IS_IOS_APP === "true") ? "55px" : "35px";
    let apiHeaders = getHeadersApiListingUsers();

    const [verifiedObject, setVerifiedObject] = useState(null);
    const [firstCall, setFirstCall] = useState(false);

    useEffect(() => {
        async function fetchVerifiedUser() {        
            try {
                const response = await fetch(
                    `${REACT_APP_API_URL}/api/verified-user`, 
                    {
                        method: "POST",
                        headers: apiHeaders
                    }
                )

                const data = await response.json();
                setVerifiedObject(data);
                if(data.email) {
                    setEmail(data.email);
                }

            } catch (err) {} 
        }

        setFirstCall(true);

        if(firstCall) {
            fetchVerifiedUser();
        }      
    }, [firstCall]);

    const[email, setEmail] = useState("");
    const[emailError, setEmailError] = useState(false);
    const[photoFile, setPhotoFile] = useState("");
    const[photoUrl, setPhotoUrl] = useState("");
    const[photoError, setPhotoError] = useState(false);
    const[code, setCode] = useState("");
    const[codeEmailSended, setCodeEmailSended] = useState(false);
    const[codeError, setCodeError] = useState(false);
    const[invalidCode, setInvalidCode] = useState(false);
    const[formError, setFormError] = useState(false);
    const[spinnerCode, setSpinnerCode] = useState(false);
    const[errorStringValidateCode, setErrorStringValidateCode] = useState(false);
    const[spinnerSubmitForm, setSpinnerSubmitForm] = useState(false);


    const {t} = useTranslation();

    const changeProfileImage = (e) => {
        try {
            if(e.target.files[0]) {
                setPhotoFile(e.target.files[0]);
                setPhotoUrl(URL.createObjectURL(e.target.files[0]));
            }
        } catch(err) {
            //ignore
        }
    }

    const cancelUpdatePhoto = () => {
        setPhotoFile(null);
        setPhotoUrl(null);
    }

    const verificationEmail = () => {
        setEmailError(false);
        if(!email || !validateEmail(email)) {
            setEmailError(true);
            return;
        }

        fetch(`${REACT_APP_API_URL}/api/verified-user/send-email-confirmation`, {
            method: "POST",
            body: JSON.stringify({'email': email}),
            headers: apiHeaders,
          }).then(
              (response) => (response.json())
            ).then((response)=> {
                setCodeEmailSended(true); 
        });
    } 

    const checkTheCode = (e) => {
        let value = e.target.value;
        setCodeError(false);
        setErrorStringValidateCode(false);
        setInvalidCode(false);

        if(value && !validateNumber(value)) {
            setErrorStringValidateCode(true);
            return;
        }

        setCode(value);
        if(value.length === 4) {
            setSpinnerCode(true);
            fetch(`${REACT_APP_API_URL}/api/verified-user/confirmation-email-code`, {
                method: "POST",
                body: JSON.stringify({'code': value}),
                headers: apiHeaders,
                }).then(
                    (response) => (response.json())
                ).then((response)=> {
                    setSpinnerCode(false);
                    if(response && response.emailConfirmation) {
                        setVerifiedObject(response);
                    } else if(response && response.code && response.code === 400) {
                        setCodeError(true);
                        setInvalidCode(true);
                    }
            })
        }
    }

    const handleSubmit = async event => {
        event.preventDefault(); 
        
        const formData = new FormData();
        formData.append('photo', photoFile);

        apiHeaders["Content-Type"] = "multipart/form-data";
        
        const config = {     
            headers: apiHeaders
        }

        setSpinnerSubmitForm(true);
        axios.post(`${REACT_APP_API_URL}/api/verified-user/post`, formData, config)
        .then ( async response => {
            if(response.data && response.data.status === verifiedObject.allStatus.STATUS_SENDED) {
                setVerifiedObject(response.data);
            }
            setSpinnerSubmitForm(false);
        }).catch(err => {
            setFormError(true);
            setSpinnerSubmitForm(false);
        })
    }

    return (
        <Fragment>
            <HeaderTwo />
            <InitializeAndHideBanner />
            <div className="login-section padding-tb" style={(width < 576) ? {marginTop: marginValue} : {}}>
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{t('validate_my_account')}</h3>
                        {(() => {
                                if(!verifiedObject) {
                                    return (
                                        <TevifSpinner />
                                    );
                                } else if (verifiedObject.isUserVerified || verifiedObject.status === verifiedObject.allStatus.STATUS_APPROUVED) {
                                    return (
                                        <>
                                            <div style={{color: "green"}}>
                                                <p>{t('your_profile_validation_request_has_been_successfully_accepted_congratulations')}</p> <i className="icofont-check-circled icofont-3x"></i>
                                            </div>
                                        </>
                                    )
                                } else if (verifiedObject.status === verifiedObject.allStatus.STATUS_SENDED) {
                                    return (
                                        <div className="form-group">
                                            <p>
                                                {t('profile_validation_request_has_been_taken_into_account')} 
                                            </p>
                                            <i className="icofont-ui-folder icofont-3x"></i>&nbsp;<i className="icofont-sand-clock icofont-3x"></i>
                                        </div>
                                    );
                                } else if (verifiedObject.status === verifiedObject.allStatus.STATUS_DEFAULT || verifiedObject.status === verifiedObject.allStatus.STATUS_REFUSED) {
                                    return (
                                        <>
                                            {verifiedObject.status === verifiedObject.allStatus.STATUS_REFUSED ? (<p style={{color: "red"}}>{t('your_profile_has_been_rejected_please_choose_another_photo_and_try_again')}</p>) : null}
                                            <form className="account-form banner-form" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <section className="profile-section">
                                                        <div className="member-profile">
                                                            <div className="profile-item">
                                                                <div className="profile-information" style={{marginTop: 0}}>
                                                                    <div className="validate-profile-pic">
                                                                        <img src={verifiedObject.publicPhoto} alt="edit-photo" style={{maxHeight: "300px"}} />
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                                <div className="form-group">
                                                    <section className="profile-section">
                                                        <div className="member-profile">
                                                            <div className="profile-item">
                                                                <div className="profile-information" style={{marginTop: 0}}>
                                                                    <div className="validate-profile-pic" style={{margin: "auto auto"}}>
                                                                        {photoFile ? (<img src={photoUrl} alt="edit-photo" style={{maxHeight: "300px"}} />) : (
                                                                            <p>
                                                                                {t('please_upload_your_photo_using_the_same_gesture_as_the_girl_in_the_image_above')}
                                                                            </p>
                                                                        )}
                                                                    </div>   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                                <div className="form-group">
                                                    <div style={{opacity: 1}}>
                                                        <div className="file-btn">
                                                                {photoFile ? (
                                                                    <span className="validate-profile-pic-custom-upload">
                                                                        <span className="d-none d-lg-inline-block" onClick={cancelUpdatePhoto}> 
                                                                            <i className="icofont-close-circled"></i>
                                                                            {t('cancel')}
                                                                        </span>
                                                                        <span className="d-lg-none mr-0" onClick={cancelUpdatePhoto}>
                                                                            <i className="icofont-close-circled"></i>
                                                                            {t('cancel')}
                                                                        </span>
                                                                    </span>
                                                                ) : (
                                                                    <label htmlFor="user_photo" className="validate-profile-pic-custom-upload">
                                                                        <span className="d-none d-lg-inline-block"> 
                                                                            <i className="icofont-camera"></i>
                                                                            {t('select_a_photo')}
                                                                        </span>
                                                                        <span className="d-lg-none mr-0">
                                                                            <i className="icofont-camera"></i>
                                                                            {t('select_a_photo')}
                                                                        </span>
                                                                        <input hidden type="file" id="user_photo" onChange={changeProfileImage} accept="image/*" />
                                                                    </label>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {!verifiedObject.emailConfirmation ? (
                                                    <>
                                                        <div className="form-group">
                                                            <>{emailError ? (<label style={{color: "red"}}>{t('email')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('email')} *</label>)}</>
                                                            <input
                                                                style={emailError ? {borderColor: "red"}: {}}
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                value={email}
                                                                onChange={(e)=>{setEmailError(false); setEmail(e.target.value);}}
                                                                placeholder={t('enter_your_email')}
                                                            />
                                                        </div>
                                                    
                                                        {codeEmailSended ? (
                                                            <div className="form-group">
                                                                <section className="profile-section">
                                                                    <div className="member-profile">
                                                                        <div className="profile-item">
                                                                            <div className="profile-information" style={{marginTop: 0}}>
                                                                                <div className="validate-profile-pic" style={{margin: "auto auto"}}>
                                                                                        <p style={{color: "green"}}>
                                                                                            {t('a_verification_email_containing_your_code_has_been_sent_please_check_your_inbox')}
                                                                                        </p>
                                                                                </div>   
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        ) : (
                                                            <div className="form-group">
                                                                <div style={{opacity: 1}}>
                                                                    <div className="file-btn">
                                                                        <label className="validate-profile-pic-custom-upload" onClick={verificationEmail}>
                                                                            <span className="d-none d-lg-inline-block"> 
                                                                            <i className="icofont-email"></i>
                                                                                {t('get_verification_code')}
                                                                            </span>
                                                                            <span className="d-lg-none mr-0">
                                                                                <i className="icofont-email"></i>
                                                                                {t('get_verification_code')}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="form-group">
                                                            {spinnerCode ? (
                                                                <TevifSpinner/>
                                                            ) : (
                                                                <>{codeError ? (<label style={{color: "red"}}>{invalidCode ? t('invalid_code') : t('verification_code')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('verification_code')} *&nbsp;:</label>)}
                                                                &nbsp;<input 
                                                                    maxLength={4}
                                                                    type="text" 
                                                                    pattern="[0-9]*" 
                                                                    name="code_validate_email"
                                                                    value={code}
                                                                    onChange={checkTheCode}
                                                                    placeholder="_ _ _ _" 
                                                                    className="cart-page-input-text" 
                                                                    style={{
                                                                        resize: "none",
                                                                        outline: "none",
                                                                        width: "75px"
                                                                    }}
                                                                />
                                                                {errorStringValidateCode ? <p style={{color: "red"}}>{t('please_enter_only_numbers_in_this_field')}</p> : null}    
                                                                </>                                            
                                                            )} 

                                                        </div>
                                                    </>
                                                    ) : (
                                                        <div className="form-group">
                                                            <p style={{color: "green"}}>{verifiedObject.email} <i className="icofont-verification-check"></i></p>
                                                            <p style={{color: "green"}}>{t('your_email_address_has_been_successfully_approved')}</p>
                                                        </div>
                                                    )}
                                                <div className="form-group">
                                                    {formError 
                                                        ? (<p className="mb-4" style={{"color": "red"}}>{t('an_error_has_occurred')}</p>) 
                                                        : null
                                                    }
                                                    {spinnerSubmitForm ? 
                                                            <TevifSpinner/> 
                                                    : (<button className="d-block lab-btn"><span>{t('send_request')}</span></button>) }
                                                </div>
                                            </form>
                                        </>
                                        
                                    );
                                }
                            }
                        )()}
                    </div>
                </div>
            </div>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}

