import { useTranslation } from "react-i18next";
import { getItem } from "../../../service/LocalStorage";
import { getCityNameByLang, getCountryNameByLang } from "../../../service/CityApi";

function UserData({user}) {

    const currentLanguage = getItem('i18nextLng') ?? "en";
    const {t} = useTranslation();

    if (currentLanguage === "ar" || currentLanguage === 'ru' || currentLanguage === 'fr'){
        return (
            <>
                {user.activeDateAgo ? <p>{t('active')} {user.activedDateInArray.int_val ? (
                    <>
                        {t('ago')}&nbsp;{user.activedDateInArray.int_val}
                    </>
                ) : null} {t(user.activedDateInArray.str_val)}</p> : null}
                <p>{getCityNameByLang(user.city, currentLanguage, true)}, {getCountryNameByLang(user.city.country, currentLanguage, true)}</p>
            </>
        )
    }

    
    return <>
            {user.activeDateAgo ? <p>{t('active')} {user.activedDateInArray.int_val ? (
                    <>
                        {user.activedDateInArray.int_val}
                    </>
                ) : null} {t(user.activedDateInArray.str_val)}&nbsp;{t('ago')}</p> : null}
        <p>{user.city.name}, {user.city.country.name}</p>
    </>;
}
 
export default UserData;