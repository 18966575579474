import { useEffect, useState } from "react";
import  { useLocation, useNavigate } from 'react-router-dom';
import { getCityNameByLang, getCountriesApi, getCountryNameByLang } from "../../service/CityApi";
import { getAges } from "../../service/User";
import { getItem } from "../../service/LocalStorage";
import PositionUser from "../../page/map/postionUser";
import { useWindowSize } from "../../service/Window";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../spinner/tevifSpinner";

const {REACT_APP_API_URL, REACT_APP_S_B} = process.env;

function AboutTwo({
    setUsersCollection,
    gender,
    ageFrom,
    ageTo,
    country,
    city,
    cityName,
    setGender,
    setAgeFrom,
    setAgeTo,
    setCountry,
    setTotalItems,
    emptyCollection,
    setSpinner,
    orderField,
    isMemberPage,
    apiUrl,
    apiHeaders,
    setCity,
    searchTypeForm,
    languageArabic,
    languageBengali,
    languageChinese,
    languageEnglish,
    languageFrench,
    languageHindi,
    languagePortuguese,
    languageRussian,
    languageSpanish,
    languageUrdu,
    languageTurkic,
    updateLanguages,
    resultSearchRef={resultSearchRef}
}) {
    const navigate = useNavigate();
    const [countriesList, setCountriesList] = useState([]);

    const [spinnerCity, setSpinnerCity] = useState(false);
    const [citiesList, setCitiesList] = useState([]);
    const [noCitiesFound, setNoCitiesFound] = useState(false);
    const [showAutocompleteCities, setShowAutocompleteCities] = useState(false);
    const [cityNameForm, setCityNameForm] = useState(cityName);
    const [cityIdForm, setCityIdForm] = useState(city ?? "");
    const [width, height] = useWindowSize();

    const [searchType, setSearchType] = useState(searchTypeForm ?? "map");


    // languages

    const [selectLanguages, setSelectLanguages] = useState(false);
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const hideShowSelectLanguages = () => {
        setSelectLanguages(!selectLanguages);
    }
 
    
    // end langugaes

    const updateCountry = (value) => {
        setCountry(value); 
        setCityNameForm(""); 
        if(setCity !== null) {
            setCity("");
        }
         
        setCityIdForm("");
    }

    const handleSubmit = async event => {
        event.preventDefault();

        setSelectLanguages(false);
        setGender(gender);
        setAgeFrom(ageFrom);
        setAgeTo(ageTo);
        setCountry(country);
        if(setCity) {
            setCity(cityIdForm);
        }
        

        let languages = '';
        if(languageArabic) {languages = languages + '&languages[1]=1';}
        if(languageBengali) {languages = languages + '&languages[2]=2';}
        if(languageChinese) {languages = languages + '&languages[3]=3';}
        if(languageEnglish) {languages = languages + '&languages[4]=4';}
        if(languageFrench) {languages = languages + '&languages[5]=5';}
        if(languageHindi) {languages = languages + '&languages[6]=6';}
        if(languagePortuguese) {languages = languages + '&languages[7]=7';}
        if(languageRussian) {languages = languages + '&languages[8]=8';}
        if(languageSpanish) {languages = languages + '&languages[9]=9';}
        if(languageUrdu) {languages = languages + '&languages[10]=10';}
        if(languageTurkic) {languages = languages + '&languages[11]=11';}
        
        if(searchType === 'map') {
            let mapZoom = 5;
            let positionLat = getItem("positionLat") ?? "";
            let positionLng = getItem("positionLng") ?? "";
            
            if(positionLat && positionLng) {
                mapZoom = 10;
            }
        
            let path = `gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&lat=${positionLat}&lng=${positionLng}&mapZoom=${mapZoom}${languages}`;
            navigate(`/members-in-a-map?${path}`);    
        } else {
            let orderValue = orderField ?? "score";
            let path = `gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&city=${cityIdForm}&cityName=${cityNameForm}&order_field=${orderValue}&page=1${languages}`;
            navigate(`/members-classic-mode?${path}`);
            if(isMemberPage) {
                setUsersCollection(emptyCollection);
                setTotalItems(0);
                setSpinner(1);
        
                try {
                    const response = await fetch(`${REACT_APP_API_URL}${apiUrl}?${path}`, 
                        {
                            method: "GET",
                            headers: apiHeaders
                        }
                    );
                    const data = await response.json();
                    setTotalItems(data.totalItems);
                    setSpinner(0);
                    setUsersCollection(data);
                    if(data.parameters.city.name) {
                        setCityNameForm(data.parameters.city.name);
                    }
                } catch (err) {}
            }
        }
    }

    const getCitiesApi = (e) => {
        setShowAutocompleteCities(false);
        setSpinnerCity(true);
        setNoCitiesFound(false);

        let searchValue = e.target.value; 

        setCityNameForm(searchValue);

        if(searchValue.trim()) {
            fetch(`${REACT_APP_API_URL}/api/cities?allData=0&country=${country}&search=${searchValue}&limit=7&sortCapital=1`)
            .then(response => 
                response.json()
            )
            .then(json => {
                setSpinnerCity(false);
                setCitiesList(json.items);
                if(json.items.length > 0) {
                    setShowAutocompleteCities(true);
                } else {
                    setShowAutocompleteCities(false);
                    setNoCitiesFound(true);
                }
            });
        } else {
            setCityIdForm("");
            setShowAutocompleteCities(false);
            setSpinnerCity(false);
            setCitiesList([]);
            setNoCitiesFound(true);
        }
    };

    const selectCity = (city, currentLanguage) => {
        if(currentLanguage === "ar") {
            setCityNameForm(city.nameAr);
        } else if(currentLanguage === "ru") {
            setCityNameForm(city.nameRu);
        } else {
            setCityNameForm(city.name);
        }
        
        setCitiesList([]);
        setShowAutocompleteCities(false)
        setSpinnerCity(false);
        setCityIdForm(city.id);
    }

    const location = useLocation();
    let currentUrl = location.pathname;

    useEffect(() => {
        getCountriesApi(currentLanguage).then(result => setCountriesList(result));
    }, []);

    const {t} = useTranslation();
    
    useEffect(() => {
        if(location) {
            if(location.pathname === "/members-classic-mode") {
                setSearchType("classic");
            }
        }
        
    }, [location]);


    const [term,setTerm] = useState("");

    const searchInAllMembers = (e) => {
        let searchValue = e.target.value;
        let trimValue = searchValue.trim();
        setTerm(e.target.value);

        if(searchValue.length === 0) {
            setSpinner(true);
            fetch(
                `${REACT_APP_API_URL}${apiUrl}`,
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            .then(response => response.json())
            .then(data => {
                if(data.items.length > 0) {
                    setUsersCollection(data);
                    setTotalItems(data.totalItems);
                } 

                setSpinner(false);
            });
        }

        if(!trimValue) {
            return;
        }


        if(trimValue.length > 2) {
            setSpinner(true);
            fetch(
                `${REACT_APP_API_URL}/api/users/search-in-all-members?term=${searchValue}`,
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            .then(response => response.json())
            .then(data => {
                setUsersCollection(data);
                setTotalItems(data.totalItems);
                setSpinner(false);
            });    
        }
    };

    return (
        <>
            <div className="about about--style3 padding-top pt-xl-0" style={currentUrl === "/members-classic-mode" ? {marginTop: "120px"} : {}}>
                <div className="container">
                    <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                        <form onSubmit={handleSubmit}>
                            <div className="banner__list">
                                <PositionUser />
                                <div className="row g-4 align-items-center row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                                    {
                                        currentUrl === "/" ? (
                                            <div className="col-12">
                                                <label>{t("search_type")}</label>
                                                <div className="banner__inputlist">
                                                    <select value={searchType} onChange={(e)=>{setSearchType(e.target.value);}}>
                                                        <option value="map">{t("in_a_map")}</option>
                                                        <option value="classic">{t("classic")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    <div className="col-12">
                                        <label>{t("looking_for")}</label>
                                        <div className="banner__inputlist">
                                            <select value={gender} onChange={(e)=>{setGender(e.target.value);}}>
                                                <option value="">{t("gender")}</option>
                                                <option value="1">{t("male")}</option>
                                                <option value="2">{t("female")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label>{t("age")}</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="banner__inputlist">
                                                    <select value={ageFrom} onChange={(e)=>{setAgeFrom(e.target.value);}}>
                                                        <option value=""></option>
                                                        {getAges().map((age) => (
                                                            <option value={age} key={`age-${age}`} >{age}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="banner__inputlist">
                                                    <select value={ageTo} onChange={(e)=>{setAgeTo(e.target.value);}}>
                                                        <option value=""></option>
                                                        {getAges().map((age) => (
                                                            <option value={age} key={`age-${age}`} >{age}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label>{t("country")}</label>
                                        <div className="banner__inputlist">
                                            <select value={country} onChange={(e) => {updateCountry(e.target.value)}}>
                                                <option value="">{t('all_countries')}</option>
                                                {countriesList.map(
                                                    c => <option key={c.key ? "country_"+c.key : "country_"+c.id} value={c.id}>
                                                        {getCountryNameByLang(c, currentLanguage)}
                                                        </option>
                                                    )}
                                            </select>
                                        </div>
                                    </div>      
            

                                    <div className="col">
                                        <label>{t("city")}</label>{noCitiesFound && showAutocompleteCities ? (
                                                <span className="mb-4" style={{"color": "#f24570"}}> {t('city_not_found')}</span>
                                        ) : null}
                                        <input
                                        className="input-form-bg-color"
                                            type="text"
                                            name="searchCity"
                                            id="searchcity"
                                            value={cityNameForm}
                                            onChange={getCitiesApi}
                                            placeholder={t("search_city_here")}
                                            style={{
                                                padding: "14px 15px",
                                                borderRadius: "4px",
                                                boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
                                            }}
                                        />
                                        
                                        {spinnerCity ? <TevifSpinner /> : null }

                                        { showAutocompleteCities ? (
                                            <div className="widget widget-category input-form-bg-color" style={width >= 992 ? {position: "absolute"} : {position: "relative"}}>
                                                <ul className="lab-ul widget-wrapper list-bg-none">
                                                    {citiesList.map((valMap, index) => (
                                                        <li key={`${valMap.name}-${index}`}>
                                                            <a 
                                                                className="d-flex flex-wrap justify-content-between" 
                                                                onClick={() => selectCity(valMap, currentLanguage)}
                                                                style={{cursor: "pointer"}}
                                                            >
                                                                {getCountryNameByLang(valMap.country, currentLanguage)},&nbsp;{getCityNameByLang(valMap, currentLanguage)}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul> 
                                            </div>
                                        ) : null }
                                    </div>             

                                    <div className="col">
                                        <label style={{marginTop: "10px"}}>{t('speak_the_languages')}</label>
                                        <label className="input-form-bg-color"
                                            onClick={() => {hideShowSelectLanguages()}}
                                            style={{
                                                cursor:"pointer", 
                                                borderRadius: "5px", 
                                                padding: "15px",
                                                width: "100%"
                                            }}
                                        >
                                            
                                            {languageArabic ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/arabic.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageBengali ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/bengali.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageChinese ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/chinese.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageEnglish ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/english.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageFrench ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/french.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageHindi ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/hindi.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languagePortuguese ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/portuguese.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageRussian ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/russian.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageSpanish ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/spanish.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageUrdu ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/turkic.png`} style={{width: "20px"}} />&nbsp;</>) : null}
                                            {languageTurkic ? (<><img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/urdu.png`} style={{width: "20px"}} />&nbsp;</>) : null}

                                            <span style={{opacity: "0"}}>Tevif</span> <i className="icofont-rounded-down" style={{float: "right"}}></i>
                                        </label>

                                        {selectLanguages ? (
                                            <div className="widget widget-category input-form-bg-color" style={width >= 576 ? {position: "absolute"} : {position: "relative", textAlign: "center"}}>
                                                <ul className="lab-ul widget-wrapper list-bg-none">                                                                                                    
                                                
                                                    <li key={`li_language_arabic`}>
                                                        <label htmlFor={`id_language_arabic`}>
                                                        <input type="checkbox" name={`language_arabic`} id={`id_language_arabic`} checked={languageArabic} onChange={() => updateLanguages(1)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/arabic.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_bengali`}>
                                                        <label htmlFor={`id_language_bengali`}>
                                                        <input type="checkbox" name={`language_bengali`} id={`id_language_bengali`} checked={languageBengali} onChange={() => updateLanguages(2)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/bengali.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_chinese`}>
                                                        <label htmlFor={`id_language_chinese`}>
                                                        <input type="checkbox" name={`language_chinese`} id={`id_language_chinese`} checked={languageChinese} onChange={() => updateLanguages(3)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/chinese.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_english`}>
                                                        <label htmlFor={`id_language_english`}>
                                                        <input type="checkbox" name={`language_english`} id={`id_language_english`} checked={languageEnglish} onChange={() => updateLanguages(4)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/english.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_french`}>
                                                        <label htmlFor={`id_language_french`}>
                                                        <input type="checkbox" name={`language_french`} id={`id_language_french`} checked={languageFrench} onChange={() => updateLanguages(5)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/french.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_hindi`}>
                                                        <label htmlFor={`id_language_hindi`}>
                                                        <input type="checkbox" name={`language_hindi`} id={`id_language_hindi`} checked={languageHindi} onChange={() => updateLanguages(6)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/hindi.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_portuguese`}>
                                                        <label htmlFor={`id_language_portuguese`}>
                                                        <input type="checkbox" name={`language_portuguese`} id={`id_language_portuguese`} checked={languagePortuguese} onChange={() => updateLanguages(7)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/portuguese.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_russian`}>
                                                        <label htmlFor={`id_language_russian`}>
                                                        <input type="checkbox" name={`language_russian`} id={`id_language_russian`} checked={languageRussian} onChange={() => updateLanguages(8)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/russian.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_spanish`}>
                                                        <label htmlFor={`id_language_spanish`}>
                                                        <input type="checkbox" name={`language_spanish`} id={`id_language_spanish`} checked={languageSpanish} onChange={() => updateLanguages(9)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/spanish.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_turkic`}>
                                                        <label htmlFor={`id_language_turkic`}>
                                                        <input type="checkbox" name={`language_turkic`} id={`id_language_turkic`} checked={languageTurkic} onChange={() => updateLanguages(11)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/turkic.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>

                                                    <li key={`li_language_urdu`}>
                                                        <label htmlFor={`id_language_urdu`}>
                                                        <input type="checkbox" name={`language_urdu`} id={`id_language_urdu`} checked={languageUrdu} onChange={() => updateLanguages(10)}/>
                                                        <span style={{
                                                            marginLeft: "5px",
                                                            cursor:"pointer", 
                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                            width: "100%", lineHeight: "56px"}}>
                                                                <img src={`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/urdu.png`} style={{
                                                                    width: "40px"
                                                                    }}/> </span>
                                                        </label>
                                                    </li>
                                                
                                                </ul> 
                                            </div>
                                        ):null}
                                    </div>

                                    <div className="col" ref={resultSearchRef}>
                                        <button type="submit" className="lab-btn d-block"><span>{t("find")}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <div className="about about--style3 padding-top pt-xl-0" style={{marginTop:"5px"}}>
                <div className="container">
                    <div className="widget widget-search" style={{boxShadow: "0px 0px 0px 0px #8888881a"}}>
                        <form className="search-wrapper" >
                            <input
                                type="text"
                                name="search"
                                id="item01"
                                value={term}
                                onChange={searchInAllMembers}
                                placeholder={t('search_by_name')}
                            />
                            <button type="submit"><i className="icofont-search-2"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default AboutTwo;