import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderTwo from "../component/layout/headertwo";
import './stylePages/showHidePassword.css';
import { login } from "../service/AuthApi";
import Auth from "../context/Auth";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

export default function ResetPassword () {
    const navigate = useNavigate();
    let { token } = useParams();
    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const [errorType, setErrorType] = useState("");

    
    const [user, setUser] = useState({
        username: "",
        password: ""
    })

    const handleSubmit = async event => {
        event.preventDefault();
        
        setErrorType("");
        
        let pass1 = password1.trim();
        let pass2 = password2.trim();

        if(pass1.length < 1 || pass2.length < 1) {
            return;
        }

        if(pass1 !==  pass2) {
            setErrorType("pwd_not_match");
            return;
        }

        setSpinner(true);
        const bodyRequest = {
            'plainPassword': password1
        };

        fetch(`${REACT_APP_API_URL}/api/public/reset-password/reset/${token}`, {
            method: "POST",
            body: JSON.stringify(bodyRequest),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            }).then(
            (response) => (response.json())).then((data)=> {
            if(data.username) {
                setErrorType("");
                setPassword1("");
                setPassword2("");

                let user = {
                    username: data.username,
                    password: password1
                }
        
                setUser(user);
                setSpinner(false);
            } else {
                if(data.code === 404 || data.code === 401) {
                    setErrorType("token_expired_or_not_found");
                } else {
                    setErrorType("error_system");
                }

                setSpinner(false);
            }
        })
    }

    useEffect(() => {
        async function loggedUser() {
            setSpinner(true);
            const response = await login(user);
            setIsAuthenticated(true);
            setSpinner(false);
    
            navigate('/news');
        }

        if(user.password && user.username) {
            loggedUser();    
        }
        
    }, [user]);

    useEffect(() => {
        if(isAuthenticated) {
            navigate('/news');
        }
        
    }, [isAuthenticated]);

    const {t} = useTranslation();

    return (
        <Fragment>
            <HeaderTwo />
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{t('reset_password')}</h3>
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group password-container">
                                <input
                                    type={showPassword1 ? "text" : "password"}
                                    name="password_1"
                                    value={password1}
                                    onChange={(e)=>{setPassword1(e.target.value)}}
                                    placeholder={`${t('enter_your_new_password')} *`}
                                />
                                {showPassword1 ? (
                                    <i className="icofont-eye-blocked fa-eye-login" onClick={() => setShowPassword1(false)}></i>
                                ) : (
                                    <i className="icofont-eye fa-eye-login" onClick={() => setShowPassword1(true)}></i>
                                )}
                            </div>
                            <div className="form-group password-container">
                                <input
                                    type={showPassword2 ? "text" : "password"}
                                    name="password_2"
                                    value={password2}
                                    onChange={(e)=>{setPassword2(e.target.value)}}
                                    placeholder={`${t('confirm_your_password')} *`}
                                />
                                {showPassword2 ? (
                                    <i className="icofont-eye-blocked fa-eye-login" onClick={() => setShowPassword2(false)}></i>
                                ) : (
                                    <i className="icofont-eye fa-eye-login" onClick={() => setShowPassword2(true)}></i>
                                )}
                            </div>
                            {errorType === "pwd_not_match" ? (
                                <div className="form-group">
                                <p style={{color: "#f24570"}}>{t('the_new_password_and_confirm_password_do_not_match')}</p>
                            </div>) : null}

                            {errorType === "error_system" ? (
                                <div className="form-group">
                                <p style={{color: "#f24570"}}>{t('a_problem_has_occurred_please_try_again_later')}</p>
                            </div>) : null}

                            {errorType === "token_expired_or_not_found" ? (
                                <div className="form-group">
                                <p style={{color: "#f24570"}}>{t('expired_or_not_found_token')}</p>
                            </div>) : null}

                            {spinner ? (
                                <TevifSpinner/> 
                            ): null}
                            <div className="form-group mb-2">
                                <button className="d-block lab-btn mb-0"><span>{t('change_password')}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 