import { useTranslation } from "react-i18next";

function SelectYesNo({val, setVal}) {
    const {t} = useTranslation();
    return (
        <select value={val} onChange={(e)=>{setVal(e.target.value);}}>
            <option value="yes">{t('yes')}</option>
            <option value="no">{t('no')}</option>
        </select>
    );
    
}
 
export default SelectYesNo;