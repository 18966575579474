import { useTranslation } from "react-i18next";
import { getItem } from "../../service/LocalStorage";
import { getCityNameByLang, getCountryNameByLang } from "../../service/CityApi";
import { getProfileName } from "../../service/String";

function LikeMember({similarList, setProfileUsername}) {

    
    const changeUserProfile = (username) => {
        setProfileUsername(username);
    }

    const currentLanguage = getItem('i18nextLng') ?? "en";
    const {t} = useTranslation();

    return (
        <div className="widget like-member">
            <div className="widget-inner">
                <div className="widget-title">
                    <h5>{t('you_may_like')}</h5>
                </div>
                <div className="widget-content">
                    <div className="row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 g-3">
                        { similarList ? (
                            similarList.map((val, i) => (
                                <a onClick={() => changeUserProfile(val.username)} key={`user_like_${val.id}`} style={{cursor: "pointer"}}>
                                    <div className="col" key={i}>
                                        <div className="lab-item member-item style-1">
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <img 
                                                        src={val.publicPathCropedProfilePicture} 
                                                        alt="profile-image" 
                                                    />
                                                </div>
                                                <div className="lab-content">
                                                    <h6>
                                                        {getProfileName(val.username, val.firstName ?? '', val.lastName ?? '', true)}
                                                        {val.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }
                                                    </h6>
                                                    <p>{getCityNameByLang(val.city, currentLanguage)}, {getCountryNameByLang(val.city.country, currentLanguage)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))
                        ) : null }
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default LikeMember;