import { Fragment, useContext, useEffect, useState } from "react";

import HeaderTwo from '../component/layout/headertwo';
import Meet from '../component/section/meet';
import BannerTwo from '../component/section/bannertwo';
import Footer from "../component/layout/mobilefooter";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import AppSectionTwo from "../component/section/appsectiontwo";
import Auth from "../context/Auth";
import { useNavigate } from "react-router-dom";
import TevifSpinner from "../component/spinner/tevifSpinner";
import { useWindowSize } from "../service/Window";
import Member from '../component/section/member';
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";

function HomeTwo () {
    const [width, height] = useWindowSize();
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const [showBody, setShowBody] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();
    
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');  

    useEffect(() => {
        if(isAuthenticated) {
            if(isPushNotificationsAvailable) {
                PushNotifications.addListener('pushNotificationReceived', (notification) => {
                });

                PushNotifications.addListener('pushNotificationActionPerformed', (res) => {
                    if(res && res.notification && res.notification.data && res.notification.data.click_action) {
                        const clickAction = res.notification.data.click_action;
                        if(clickAction === "https://api.tevif.com/app/middleware-link/platform/redirect") {
                            window.location.href = clickAction;
                        } else {
                            navigate(clickAction);
                        }
                    }
                });
            }

            navigate("/news");
        } else {
            setShowBody(true);
        }
      }, [isAuthenticated])

      let marginValue = "35px";
      if (process.env.REACT_APP_IS_IOS_APP === "true") {
          marginValue = "55px";
      }

    return (
        <Fragment>
            <Helmet>
                <title>Tevif | {t('social_media')} | {t('live_chat')} | {t('free_dating_site')}</title>
				<meta name="description" content={`${t("home_page_meta_description")}. ${t('contact_and_chat_with_women_and_men_online')}`} />
			</Helmet>
            { showBody ? (
                <>
                    <HeaderTwo />
                    <InitializeAndHideBanner />
                    <BannerTwo />
                    {/*<BannerTs />*/}
                    <Member />

                    <Meet />
                    <AppSectionTwo />
                </>
            ) : 
            <>
                <HeaderTwo />
                <section className="profile-section padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="member-profile">
                                <div className="profile-details">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane activity-page fade show active" id="activity" role="tabpanel">
                                            <div className="col-xl-12" style={{maxWidth:"856px", margin:"auto"}}>
                                                <article>
                                                    <div className="activity-tab">
                                                        <TevifSpinner />
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>      
            </>
            }
            <Footer />
        </Fragment>
    );
    
}
 
export default HomeTwo;