import {getItem} from './LocalStorage';

export function isLightModeActived() {
    const mode = getItem('lightMode');
    
    if(mode === "0") {
        return false;
    }

    return true;
}
