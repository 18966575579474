import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const {REACT_APP_API_URL} = process.env;

function SelectOrder({
    gender,
    ageFrom,
    ageTo,
    country,
    city,
    orderField,
    setOrderField,
    setUsersCollection,
    emptyCollection,
    setSpinner,
    apiUrl,
    cityName,
    apiHeaders
}) {
    const navigate = useNavigate();

    const onChange = async (event) => {
        const value = event.target.value;

        let path = `gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&city=${city}&cityName=${cityName}&order_field=${value}&page=1`;

        setOrderField(value);
        setUsersCollection(emptyCollection);
        navigate(`/members-classic-mode?${path}`);
        setSpinner(1);

        try {
            const response = await fetch(`${REACT_APP_API_URL}${apiUrl}?${path}`, 
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            const data = await response.json();

            setSpinner(0);
            setUsersCollection(data);
        } catch (err) {}
        
    };

    const {t} = useTranslation();
    return (
        <select defaultValue={orderField} onChange={onChange} >
            <option value="score">{t('popular')}</option>
            <option value="id">{t('newest_registerd')}</option>
            <option value="lastActivate">{t('online')}</option>
        </select>
    );
    
}
 
export default SelectOrder;