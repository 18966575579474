import ShowMedia from "../../media/showMedia";

function MediaActivity({media, poster, maxHeightValue}) {

    if(media) {
        return (
            <div className="post-desc-img">
                <ShowMedia 
                    media={media}
                    path={media.publicPath}
                    styleObject={{
                        maxHeight: maxHeightValue ?? "500px",
                        width: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                        borderRadius: "10px"
                    }}
                    poster={poster}
                />
            </div> 
        );   
    }

    return null
}

export default MediaActivity;