import { Fragment } from "react";
import Header from "../component/layout/headertwo";
import { useTranslation } from "react-i18next";
import Footer from "../component/layout/mobilefooter";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";


function Policy () {
    const {t} = useTranslation();
        return (
            <Fragment>
                <Header />
                <InitializeAndResumeBanner />
                <section className="terms-section padding-tb banner-admob-margin" style={process.env.REACT_APP_IS_IOS_APP === "true" ? {marginTop: "35px"} : {}}>
                    <div className="container">
                        <div className="terms-content">
                            <div className="terms-header">
                                <h4>{t('privacy_policy')}</h4>
                                <p><span className="theme-color fw-bold">{t('last_update')}:</span> 2023-06-01</p>
                            </div>
                            <div className="terms-text">
                                <p className="mb-4">{t('p_1_desc')}</p>
                                <ol>
                                    <li>
                                        <h5>{t('collection_of_personal_information')}</h5>
                                        <p>{t('p_2_dec')}</p>
                                    </li>
                                    <li>
                                        <h5>{t('use_of_personal_information')}</h5>
                                        <p>{t('p_3_dec')}</p>
                                    </li>
                                    <li>
                                        <h5>{t('disclosure_of_personal_information')}</h5>
                                        <p>{t('p_4_dec')}</p>
                                    </li>
                                    <li>
                                        <h5>{t('protection_of_personal_information')}</h5>
                                        <p>{t('p_5_dec')}</p>
                                    </li>
                                    <li>
                                        <h5>{t('links_to_third_party_sites')}</h5>
                                        <p>{t('p_6_dec')}</p>
                                    </li>
                                    <li>
                                        <h5>{t('privacy_policy_changes')}</h5>
                                        <p>{t('p_7_dec')}</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <AppSectionTwo />
                <Footer />
            </Fragment>
        );
}
 
export default Policy;