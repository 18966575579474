import { useEffect, useState } from 'react';
import { getItem } from '../../../service/LocalStorage';
import jwtDecode from 'jwt-decode';
import { getHeadersApiListingUsers } from '../../../service/User';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCityNameByLang, getCountryNameByLang } from '../../../service/CityApi';
import TevifSpinner from '../../../component/spinner/tevifSpinner';
import { getProfileName } from "../../../service/String";

const {REACT_APP_API_URL} = process.env; 

function ActivityShowLikes({idActivityShowLikes, setIdActivityShowLikes}) {
    let userToken = getItem('userToken');
    let currentUserIdentifier = null
    if(userToken) {
        currentUserIdentifier = jwtDecode(userToken).username;
    }   

    const [popupClassNameLikes, setPopupClassNameLikes] = useState("popup");

    const [spinner, setSpinner] = useState(false);
    const [likes, setLikes] = useState([]);
    
    const apiHeaders = getHeadersApiListingUsers();

    useEffect(() => {
        async function getActivityLikes() {
            setSpinner(true);
            try {
                const userResponse = await fetch(`${REACT_APP_API_URL}/api/activities/${idActivityShowLikes}/likes`, {
                    method: "GET",
                    headers: apiHeaders
                })
                
                const likesData = await userResponse.json();
                setLikes(likesData);         
                setSpinner(false);
                setPopupClassNameLikes("popup open");
            
            } catch (err) {
                setSpinner(false);
            } 
        }
        
        if (idActivityShowLikes === 0) {
            setPopupClassNameLikes("popup");

            setLikes([]);

        } else if (idActivityShowLikes > 0) {
            getActivityLikes();
        }
    }, [idActivityShowLikes]);

    const closePopup = () => {
        setIdActivityShowLikes(0);
        setPopupClassNameLikes("popup");
        setLikes([]);
    }

    const {t} = useTranslation();
    const currentLanguage = getItem('i18nextLng') ?? "en";
    return (
        <>
        <div className={popupClassNameLikes}>
            <div className="container">
                <div className="popup__area">
                    <div className="popup__close" onClick={closePopup}>
                        <i className="icofont-close"></i>
                    </div>
                    <div className="row">
                        <div className="popup__fild">
                            <h4 className="mb-4">{t('users_like_this_activity')}</h4>
                            {spinner ? (
                                    <TevifSpinner/> 
                                ) : null}               
                            <div className="widget widget-post" style={{
                                    boxShadow: "none",
                                    width: "auto", 
                                    maxHeight: "300px",
                                    overflow: "scroll"
                                }}>
                                <ul className="lab-ul widget-wrapper  row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                                    {likes.map((userLike, i) => {
                                        return (
                                            <Link to={`/profile/${userLike.username}`} key={`link_${i}`}>
                                                <li className="d-flex flex-wrap" key={`conversaation_${i}`} style={{cursor: "pointer"}}>
                                                    <div className="post-thumb">
                                                        <img 
                                                            src={userLike.publicPathCropedProfilePicture} 
                                                            alt={userLike.username} 
                                                            className="rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="post-content ps-4">
                                                        <h6>
                                                            {getProfileName(userLike.username, userLike.firstName ?? '', userLike.lastName ?? '', true)}
                                                            {userLike.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null}</h6>
                                                            {userLike.activeDateAgo ? <p>{t('active')} {userLike.activedDateInArray.int_val ? (
                                                            <>
                                                                {t('ago')}&nbsp;{userLike.activedDateInArray.int_val}
                                                            </>
                                                        ) : null} {t(userLike.activedDateInArray.str_val)}</p> : null}

                                                        <p>
                                                            {getCityNameByLang(userLike.city, currentLanguage)},&nbsp;{getCountryNameByLang(userLike.city.country, currentLanguage)}
                                                        </p>
                                                        <p>{userLike.age} {t('years')}</p>
                                                    </div>
                                                </li>
                                            </Link>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        </>
    );
}

export default ActivityShowLikes;