export function getHourMinuteOfNow() {
    var myDate = new Date();

    //var timeString =  myDate.getHours() + ':' + myDate.getMinutes();

    //const timeString = myDate.toLocaleTimeString({ hour: '2-digit', hour12: false, minute: '2-digit' });

    const timeString = myDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

    return timeString;
}