import { useState } from "react";
import { useTranslation } from "react-i18next";


const {REACT_APP_API_URL} = process.env;

function LoadMoreMessages({
    messages, 
    setMessages, 
    page, 
    setPage, 
    apiHeaders,
    setSpinnerMessage,
    participantUsername,
    setScrollBool
}) {
    
    const [showLoadMore, setShowLoadMore] = useState(true);

    const getMessages = () => {
        let newPage = page + 1;
        setPage(newPage);
        setSpinnerMessage(true);
        
        fetch(`${REACT_APP_API_URL}/api/conversations/messages/${participantUsername}?page=${newPage}&limit=10`, {
            method: "GET",
            headers: apiHeaders,
          })
          .then(function(response){
            if(response.status === 200) {
                return response.json();
            } else {
                setSpinnerMessage(false);
            }
          })
          .then(function(data){
            if(data) {
                if(data.currentItems > 0) {
                    let newMessages = [...data.items, ...messages];
                    setScrollBool(false);
                    setMessages(newMessages);
                }
            }

            if(data.totalPages === newPage || data.currentItems === 0) {
                setShowLoadMore(false);
            }

            setSpinnerMessage(false);
          })
    }

    const {t} = useTranslation();

    return (
        <>
            {showLoadMore ? (
                <div className="load-btn">
                    <a className="lab-btn" onClick={getMessages}><i className="icofont-plus"></i> {t('load_more')}</a>
                </div>
            ): 
            (
                <div className="load-btn">
                    <p>{t('no_more_data_to_load')}</p>
                </div>
            )}
        </>
    );
    
}
 
export default LoadMoreMessages;