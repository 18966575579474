import { Fragment, useState } from "react";
import Header from "../component/layout/headertwo";
import { useWindowSize } from "../service/Window";
import { useTranslation } from "react-i18next";
import Footer from "../component/layout/mobilefooter";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";

const {REACT_APP_API_URL} = process.env;

function ContactPage () {

    const [conName, setConName] = useState("");
    const [conEmail, setConEmail] = useState("");
    const [conSubject, setConSubject] = useState("");
    const [conPhone, setConPhone] = useState("");
    const [conMessage, setConMessage] = useState("");
    const [conDesc, setConDesc] = useState("");
    const [colorConDesc, setColorConDesc] = useState("white");
    const [width, height] = useWindowSize();

    const handleSubmit = async e => {
        e.preventDefault();
        
        const bodyRequest = {
            'name': conName,
            'email': conEmail,
            'subject': conSubject,
            'phone': conPhone,
            'message': conMessage,
        };

        if(!conEmail || !conMessage) {
               setConDesc(t("email_and_message_fields_cannot_be_empty"));
               setColorConDesc("#f24570");  
        } else {
            fetch(`${REACT_APP_API_URL}/api/contact-us`, {
                method: "POST",
                body: JSON.stringify(bodyRequest),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
              }).then(
              (response) => (response.json())
                ).then((response)=> {
              if (response.email === conEmail) {
                setConDesc(t("thank_you_your_message_has_been_sent_successfully"));  
                setColorConDesc("green");  
                resetForm()
              } else if(response.code === 400) {
                setConDesc(t("please_enter_a_valid_email_address"));  
                setColorConDesc("#f24570");  
              } else {
                setConDesc(t("an_error_has_occurred_please_retry_later"));  
                setColorConDesc("red");   
              }
            })
        }
    }

    const resetForm = () => {
        setConName("");
        setConEmail("");
        setConSubject("");
        setConPhone("");
        setConMessage("");
    }

    const {t} = useTranslation();
    return (
        <Fragment>
            <Header />
            <InitializeAndResumeBanner />
            <div className="contact-section" style={width < 576 ? {marginTop: "35px"} : {}}>
                <div className="contact-top padding-tb aside-bg padding-b banner-admob-margin">
                    <div className="container">
                        <div className="row g-4 flex-row-reverse">
                            <div className="col-lg-4">
                                
                            </div>
                            <div className="col-lg-8">
                                <article className="contact-form-wrapper">
                                    <div className="contact-form">
                                        <h4>{t('don_t_be_a_stranger_just_say_hello')}</h4>
                                        
                                        <p className="mb-4" style={{"color": colorConDesc}}>{conDesc}</p>
                                    
                                        <form className="comment-form"  onSubmit={handleSubmit}>
                                            <input
                                                type="text"
                                                name="name"
                                                id="conName"
                                                value={conName}
                                                onChange={(e)=>{setConName(e.target.value)}}
                                                placeholder={`${t("name")} *`}
                                            />
                                            <input
                                                type="text"
                                                name="email"
                                                id="conEmail"
                                                value={conEmail}
                                                onChange={(e)=>{setConEmail(e.target.value)}}
                                                placeholder={`${t("email")} *`}
                                            />
                                            <input
                                                type="text"
                                                name="subject"
                                                id="conSubject"
                                                value={conSubject}
                                                onChange={(e)=>{setConSubject(e.target.value)}}
                                                placeholder={`${t("subject")} *`}
                                            />
                                            <input
                                                type="text"
                                                name="phone"
                                                id="conPhone"
                                                value={conPhone}
                                                onChange={(e)=>{setConPhone(e.target.value)}}
                                                placeholder={`${t("phone")} *`}
                                            />
                                            <textarea 
                                                rows="10" 
                                                type="text"
                                                id="conMessage"
                                                name="message"
                                                value={conMessage}
                                                onChange={(e)=>{setConMessage(e.target.value)}}
                                                placeholder={`${t("type_here_message")} *`}
                                            ></textarea>
                                            <button type="submit" className="lab-btn"><span>{t('send_our_message')}</span></button>
                                        </form>
                                        <p className="form-message"></p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}
 
export default ContactPage;