import { useTranslation } from "react-i18next";
import { Capacitor } from '@capacitor/core';

let PalystoreList = [
    {
        imgUrl: 'https://tevif.s3.eu-west-3.amazonaws.com/stores/01.jpg',
        imgAlt: 'apps',
        Link: 'https://apps.apple.com/us/app/tevif/id6462837548',
    },
    {
        imgUrl: 'https://tevif.s3.eu-west-3.amazonaws.com/stores/02.jpg',
        imgAlt: 'apps',
        Link: 'https://play.google.com/store/apps/details?id=www.tevif.com',
    }
]

function AppSectionTwo() {
    const {t} = useTranslation();
    const isNativePlatform = Capacitor.isNativePlatform();  

    if (isNativePlatform) {
        return null;
    }

    return (
        <div className="app app--style2 padding-tb">
            <div className="container">
                <div className="row g-4 justify-content-center">
                    <div className="col-12">
                        <div className="app__item wow fadeInUp" data-wow-duration="1.5s">
                            <div className="app__inner">
                                <div className="section-header mb-4">
                                    <h2>{t('download_our_apps')}</h2>
                                    <p>{t('download_our_apps_description')}</p>
                                </div>
                                <div className="app__content text-center">
                                    {/*<ul className="d-flex flex-wrap mb-4">
                                        <li className="counter-text border-count">
                                            <h3 className="counter__number mb-0"><CountUp end={count} /></h3>
                                        </li>
    </ul>*/}
                                    <ul className="justify-content-center">
                                        {PalystoreList.map((val, i) => (
                                            <li key={i}><a href={`${val.Link}`}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}
 
export default AppSectionTwo;