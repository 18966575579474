import { useState } from "react";
import { getItem } from '../../service/LocalStorage';
import { useTranslation } from "react-i18next";
import TevifSpinner from "../../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

function LoadMoreMedias({ 
    userId,
    medias,
    setMedias,
    mediaType,
    nbMedia,
    page,
    setPage,
    noMoreMedias,
    setNoMoreMedias
}) {

    const [spinner,setSpinner] = useState(false);
    let userToken = getItem('userToken');
    
    const loadMoreMedias = event => {
        event.preventDefault();  
        setSpinner(true);

        fetch(`${REACT_APP_API_URL}/api/medias/list?userId=`+userId+`&limit=20&page=`+page+`&mediaType=`+mediaType, {
                method: "GET",
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                }
            })
            .then(response => response.json())
            .then((result) => {
                if(result.items.length > 0) {
                    let newMedias = [...medias, ...result.items] 
                    setMedias(newMedias)
                    setPage(page + 1);
                    setSpinner(false);
                }

                if(result.items.length < 5) {
                    setNoMoreMedias(true)
                }
            })
        ;
    }

    const {t} = useTranslation();
    
    if(nbMedia == 0) {
        return null;
    }

    if(spinner && noMoreMedias == false) {
        return (<TevifSpinner />);
    }

    if(noMoreMedias == false) {
        return (
            <div className="load-btn">
                <a className="lab-btn" onClick={loadMoreMedias}>{t('load_more')}</a>
            </div>
        );
    }

    if (noMoreMedias == true) {
        return (
            <div className="load-btn">
                <p>{t('no_more_data_to_load')} </p>
            </div>
        );
    }

    return null;
}

export default LoadMoreMedias;