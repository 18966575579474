import { useCallback, useContext, useEffect, useRef, useState } from "react";
import 'react-photo-view/dist/react-photo-view.css';
import { getItem } from "../../../service/LocalStorage";
import { Link } from "react-router-dom";
import UserData from "../search/userData";
import { useTranslation } from "react-i18next";
import Auth from "../../../context/Auth";
import TevifSpinner from "../../../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

function UserFollowers({username}) {
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const userToken = getItem('userToken');
    const [items,setItems] = useState([]);
    const [page,setPage] = useState(2);
    const [spinner,setSpinner] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const getFollowers = () => {
        setSpinner(true);
        fetch(`${REACT_APP_API_URL}/api/users/followers/${username}?page=${page}`, {
                method: "GET",
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                }
            })
            .then(response => response.json())
            .then((result) => {
                if(result.items.length > 0) {
                    setItems((oldItems) => [...oldItems, ...result.items]);
                    setPage(page + 1);
                }

                if(result.items.length < 20) {
                    setHasMore(false);
                }

                setSpinner(false);
            })
        ;
    }

    useEffect(() => {  
        if(!isAuthenticated) {
            return;
        }

        async function getInitialFollowers() {
            setSpinner(true);                    
            let config = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };
    
            try {
                const userResponse = await fetch(`${REACT_APP_API_URL}/api/users/followers/${username}?page=1`, {
                    method: "GET",
                    headers: config
                })
                    
                const data = await userResponse.json();
                setSpinner(false);
                setItems(data.items);            
            } catch (err) {
            } 
        }
        
        getInitialFollowers();
        
      }, [username])


    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (spinner) {
            return
        };
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
            getFollowers();
          }
        })
        if (node) observer.current.observe(node)
    }, [spinner, hasMore, page]);

    const {t} = useTranslation();

    if (!isAuthenticated) {
        return (
            <div className="tab-pane fade" id="userfollowers" role="tabpanel" aria-labelledby="nav-userfollowers-tab">
                <div className="load-btn">
                    <p>{t('sorry_you_have_to_authenticate_to_see_this_part')}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="tab-pane fade" id="userfollowers" role="tabpanel" aria-labelledby="nav-userfollowers-tab">
            <div>
                <div className="row">
                    <div className="col-xl-8">
                        <article>
                            <div className="row g-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 justify-content-center">
                                {items ? items.map((val, i) => {
                                    if(items.length === i + 1) {
                                        return (
                                            <Link to={`/profile/${val.username}`} key={`user_profile_${val.id}`}>
                                                <div ref={lastElementRef} className="col" key={i}>
                                                    <div className="lab-item member-item style-1">
                                                        <div className="lab-inner">
                                                            <div className="lab-thumb" style={{height:"200px"}}>
                                                                <img 
                                                                    src={val.publicPathCropedProfilePicture} 
                                                                    alt={val.username}
                                                                />
                                                            </div>
                                                            <div className="lab-content">
                                                                <h6>
                                                                    <i className="active green" style={{background: val.colorActive}}></i>
                                                                    &nbsp;{val.username}
                                                                    {val.verified ? (<i className="icofont-check" title="Verify Profile"></i>) : null }
                                                                </h6>
                                                                <UserData user={val}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                    )} else {
                                        return (
                                            <Link to={`/profile/${val.username}`} key={`user_profile_${val.id}`}>
                                                <div className="col" key={i}>
                                                    <div className="lab-item member-item style-1">
                                                        <div className="lab-inner">
                                                            <div className="lab-thumb" style={{height:"200px"}}>
                                                                <img 
                                                                    src={val.publicPathCropedProfilePicture} 
                                                                    alt={val.username}
                                                                />
                                                            </div>
                                                            <div className="lab-content">
                                                                <h6>
                                                                    <i className="active green" style={{background: val.colorActive}}></i>
                                                                    &nbsp;{val.username}
                                                                    {val.verified ? (<i className="icofont-check" title="Verify Profile"></i>) : null }
                                                                </h6>
                                                                <UserData user={val}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                }) : null}
                            </div>
                            {spinner ? (
                            <TevifSpinner/>
                        ): (<>
                                {items.length === 0 ? (
                                    <div className="load-btn">
                                        <p>{t('no_data_to_display')}</p>
                                    </div>
                                ): null}
                            </>)}
                        </article> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserFollowers;