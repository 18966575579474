import { useEffect, useRef } from "react";

export default function VideoActivity({path, poster}) {

  const videoRef = useRef(null);

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: 1
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
            videoRef.current.play()
              .then(() => {}).catch(error => {
              });   
        } else {
            if(videoRef && videoRef.current) {
                videoRef.current.pause();
            }
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);

    observer.observe(videoRef.current);

  });

    return (
      <video
        width="100%"  
        preload="none" 
        controls 
        tabIndex="-1" 
        playsInline 
        aria-label="Vidéo intégrée" 
        src={path} 
        muted={false}
        poster={poster}
        style={{
          maxHeight:"500px",
          borderRadius: "10px"
        }}
        ref={videoRef}
        controlsList="nodownload" 
      >
      </video>
    );
}
