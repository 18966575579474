import { Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/headertwo";
import { useTranslation } from "react-i18next";
import Auth from "../context/Auth";

function DeletedProfile() {  
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const {t} = useTranslation();

    if(isAuthenticated) {
        navigate('/page-not-found');
    }
    
    return (
        <Fragment>
            <Header />
            <section className="fore-zero padding-tb">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-thumb">
                            </div>
                            <div className="zero-content">
                                <h2>{t('your_profile_has_been_deleted')} <i className="icofont-worried"></i></h2>
                                <Link to="/" className="lab-btn"><span>{t('home')}</span> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
 
export default DeletedProfile;