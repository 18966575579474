import { useState, useEffect, useContext } from "react";
import { getAges } from "../../../service/User"
import { getCityNameByLang, getCountriesApi, getCountryNameByLang } from "../../../service/CityApi";
import { getItem } from '../../../service/LocalStorage';
import axios from 'axios';
import SelectDate from "../../../component/select/select-date";
import SelectMonth from "../../../component/select/select-month";
import SelectYear from "../../../component/select/select-year";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Auth from "../../../context/Auth";
import { logout } from "../../../service/AuthApi";
import SelectYesNo from "../../../component/select/select-yes-no";
import LightMode from "../../../context/LightMode";
import TevifSpinner from "../../../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

function FormEditProfile({user, setUser}) { 
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const { isLightMode, setIsLightMode } = useContext(LightMode);
    const navigate = useNavigate();

    //user information
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [countryId, setCountryId] = useState("");
    const [cityId, setCityId] = useState("");
    const [cityName, setCityName] = useState("");
    const [description, setDescription] = useState("");
    const [selectedProfileImage, setSelectedProfileImage] = useState();
    const [dayDateOfBirth, setDayDateOfBirth] = useState("");
    const [monthDateOfBirth, setMonthDateOfBirth] = useState("");
    const [yearDateOfBirth, setYearDateOfBirth] = useState("");
    const [descriptionDeleteUser, setDescriptionDeleteUser] = useState("");
    const [emailDeleteUser, setEmailDeleteUser] = useState("");
    const [errorFormDeleteUser, setErrorFormDeleteUser] = useState(false);    
    const [languageArabic, setLanguageArabic] = useState(false);
    const [languageBengali, setLanguageBengali] = useState(false);
    const [languageChinese, setLanguageChinese] = useState(false);
    const [languageDeutsch, setLanguageDeutsch] = useState(false);
    const [languageEnglish, setLanguageEnglish] = useState(false);
    const [languageFrench, setLanguageFrench] = useState(false);
    const [languageHindi, setLanguageHindi] = useState(false);
    const [languagePortuguese, setLanguagePortuguese] = useState(false);
    const [languageRussian, setLanguageRussian] = useState(false);
    const [languageSpanish, setLanguageSpanish] = useState(false);
    const [languageUrdu, setLanguageUrdu] = useState(false);
    const [languageTurkic, setLanguageTurkic] = useState(false);
    
    const [showInTheMap, setShowInTheMap] = useState("yes");

    // api listing data 
    const [countriesList, setCountriesList] = useState([])
    const [citiesList, setCitiesList] = useState([])

    // boolean form
    const [showAutocompleteCities, setShowAutocompleteCities] = useState(false)
    const [spinner, setSpinner] = useState(false);    
    const [noCitiesFound, setNoCitiesFound] = useState(false);

    // form Error messages
    const [errorFormDesc,setErrorFormDesc] = useState("");
    const [errorFormColor,setErrorFormColor] = useState("");

    //form edit photo
    const [profileImage,setProfileImage] = useState("");
    const [editProfileImage,setEditProfileImage] = useState("");
   

    const [loaderUpdateProfile,setLoaderUpdateProfile] = useState(false);


    const userToken = getItem('userToken');

    useEffect(() => {
        (async () => {
            getCountriesApi(currentLanguage).then(result => setCountriesList(result));
            
            if(user.firstName) {
                setFirstName(user.firstName);
            }

            if(user.lastName) {
                setLastName(user.lastName);
            }

            if(user.email) {
                setEmail(user.email);
                setEmailDeleteUser(user.email);
            }

            if(user.description) {
                setDescription(user.description);
            }

            setGender(user.gender);
            setAge(user.age);
            setCountryId(user.city.country.id);
            setCityId(user.city.id);
            
            if (currentLanguage === "ar"){
                setCityName(user.city.nameAr);
            } else if (currentLanguage == 'ru'){
                setCityName(user.city.nameRu);
            } else {
                setCityName(user.city.name);
            }
                        
            setProfileImage(user.publicPathProfilePicture);

            if(user.dayOfBirth){
                setDayDateOfBirth(user.dayOfBirth);    
            }
            
            if(user.monthOfBirth){
                setMonthDateOfBirth(user.monthOfBirth);
            }
            
            if(user.yearOfBirth){
                setYearDateOfBirth(user.yearOfBirth);
            }

            if(user.showInTheMap){
                setShowInTheMap("yes");
            } else {
                setShowInTheMap("no");
            }
            
            if(user.cityOfBirth){
                setCityOfBirth(user.cityOfBirth.id);

                if (currentLanguage === "ar"){
                    setLabelCityOfBirth(user.cityOfBirth.nameAr);
                } else if (currentLanguage == 'ru'){
                    setLabelCityOfBirth(user.cityOfBirth.nameRu);
                } else {
                    setLabelCityOfBirth(user.cityOfBirth.name);
                }
            }

            let userLanguages = user.languages;
            for (let i = 0; i < userLanguages.length; i++) {
                let lang = userLanguages[i];

                if(lang.id === 1) {setLanguageArabic(true);}
                if(lang.id === 2) {setLanguageBengali(true);}
                if(lang.id === 3) {setLanguageChinese(true);}
                if(lang.id === 4) {setLanguageEnglish(true);}
                if(lang.id === 5) {setLanguageFrench(true);}
                if(lang.id === 6) {setLanguageHindi(true);}
                if(lang.id === 7) {setLanguagePortuguese(true);}
                if(lang.id === 8) {setLanguageRussian(true);}
                if(lang.id === 9) {setLanguageSpanish(true);}
                if(lang.id === 10) {setLanguageUrdu(true);}
                if(lang.id === 11) {setLanguageTurkic(true);}
                if(lang.id === 12) {setLanguageDeutsch(true);}
            }
        })();
      }, [user]);

      const selectCity = (city, currentLanguage) => {
        if(currentLanguage === "ar") {
            setCityName(city.nameAr);
        } else if(currentLanguage === "ru") {
            setCityName(city.nameRu);
        } else {
            setCityName(city.name);
        }

        setCityId(city.id)
        setCitiesList([]);
        setShowAutocompleteCities(false)
        setSpinner(false);
    }

    const changeCountry = (e) => {
        setCountryId(e.target.value);
        setCityName("");
        setCityId("")
        setCitiesList([]);
        setShowAutocompleteCities(false)
        setSpinner(false);
    }

    const getCitiesApi = (e) => {
        setShowAutocompleteCities(false)
        setSpinner(true);
        setNoCitiesFound(false)

        let searchValue = e.target.value; 
        setCityName(e.target.value)
        if(searchValue.trim()) {
            fetch(`${REACT_APP_API_URL}/api/cities?allData=0&country=`+countryId+`&search=`+e.target.value+`&limit=10&sortCapital=1`)
            .then(response => 
                response.json()
            )
            .then(json => {
                setSpinner(false);
                setCitiesList(json.items);
                if(json.items.length > 0) {
                    setShowAutocompleteCities(true)
                } else {
                    setShowAutocompleteCities(false)
                    setNoCitiesFound(true)
                }
            });
        } else {
            setShowAutocompleteCities(false)
            setSpinner(false);
            setCitiesList([]);
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();
        
        setLoaderUpdateProfile(true);
        setErrorFormDesc("")
        setErrorFormColor("")
        
        if(
            !gender
            || !age
            || !cityId
            || !countryId
        ) {
            setLoaderUpdateProfile(false);
            setErrorFormDesc("gender, age, country and city: cannot be empty.")
            setErrorFormColor("#f24570")
        } else {

            let languages = [];
            if(languageArabic) {languages.push(1);}
            if(languageBengali) {languages.push(2);}
            if(languageChinese) {languages.push(3)}
            if(languageEnglish) {languages.push(4)}
            if(languageFrench) {languages.push(5)}
            if(languageHindi) {languages.push(6)}
            if(languagePortuguese) {languages.push(7)}
            if(languageRussian) {languages.push(8)}
            if(languageSpanish) {languages.push(9)}
            if(languageUrdu) {languages.push(10)}
            if(languageTurkic) {languages.push(11)}
            if(languageDeutsch) {languages.push(12)}

            const formData = new FormData();
		    formData.append('profileImage', selectedProfileImage);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('email', email);
            formData.append('gender', gender);
            formData.append('age', age);
            formData.append('country', countryId);
            formData.append('city', cityId);
            formData.append('description', description);            
            formData.append('cityOfBirth', cityOfBirth);
            formData.append('showInTheMap', showInTheMap);

            if(dayDateOfBirth && monthDateOfBirth && yearDateOfBirth) {
                formData.append('dateOfBirth', `${yearDateOfBirth}-${monthDateOfBirth}-${dayDateOfBirth}`);
            }


            if(languages.length > 0) {
                for (let i = 0; i < languages.length; i++) {
                    if(languages[i]) {
                        formData.append('languages[]', languages[i])
                    }
                }
            }
            
            const config = {     
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer "+ userToken 
                }
            }

            axios.post(`${REACT_APP_API_URL}/api/user/update`, formData, config)
            .then (response => {
                setLoaderUpdateProfile(false);
                setErrorFormDesc(t("your_profile_has_been_successfully_updated"));
                setErrorFormColor("green");

                setUser(response.data)
                
            }).catch(err => {
                if(err.response.data.code === 400) {
                    setLoaderUpdateProfile(false);
                    setErrorFormDesc("Invalid data")
                    setErrorFormColor("#f24570")
                } else {
                    setLoaderUpdateProfile(false);
                    setErrorFormDesc("An error has occurred. Please retry later")
                    setErrorFormColor("red")
                }

                if(user.firstName) {
                    setFirstName(user.firstName)
                }
    
                if(user.lastName) {
                    setLastName(user.lastName)
                }
    
                if(user.email) {
                    setEmail(user.email)
                }
    
                if(user.description) {
                    setDescription(user.description)
                }

                setGender(user.gender)
                setAge(user.age)
                setCountryId(user.city.country.id);
                setCityId(user.city.id);
                setCityName(user.city.name);
            })
        }

        setTimeout(function(){ 
            setLoaderUpdateProfile(false);
            setErrorFormDesc("");
            setErrorFormColor("");
        }, 5000);
    }

    const changeProfileImage = (e) => {
        try {
            if(e.target.files[0]) {
                setSelectedProfileImage(e.target.files[0]);
                setEditProfileImage(URL.createObjectURL(e.target.files[0]));
            }
        } catch(err) {
            //ignore
        }
    }

    const handleChange = (id) => {
        if(id === 1) {setLanguageArabic((oldVal) => !oldVal);}
        if(id === 2) {setLanguageBengali((oldVal) => !oldVal);}
        if(id === 3) {setLanguageChinese((oldVal) => !oldVal);}
        if(id === 4) {setLanguageEnglish((oldVal) => !oldVal);}
        if(id === 5) {setLanguageFrench((oldVal) => !oldVal);}
        if(id === 6) {setLanguageHindi((oldVal) => !oldVal);}
        if(id === 7) {setLanguagePortuguese((oldVal) => !oldVal);}
        if(id === 8) {setLanguageRussian((oldVal) => !oldVal);}
        if(id === 9) {setLanguageSpanish((oldVal) => !oldVal);}
        if(id === 10) {setLanguageUrdu((oldVal) => !oldVal);}
        if(id === 11) {setLanguageTurkic((oldVal) => !oldVal);}
        if(id === 12) {setLanguageDeutsch((oldVal) => !oldVal);}
    };

    const {t} = useTranslation(); 
    

    // autocomplete city of birth
    const [cityOfBirth, setCityOfBirth] = useState("");
    const [labelCityOfBirth, setLabelCityOfBirth] = useState("");
    const [showAutocompleteCityOfBirth, setShowAutocompleteCityOfBirth] = useState("");
    const [spinnerCityOfBirth, setSpinnerCityOfBirth] = useState("");
    const [noCityOfBirthFound, setNoCityOfBirthFound] = useState("");
    const [citiesOfBirthList, setCitiesOfBirthList] = useState([]);
    
    const getCityOfBirth = (e) => {
        setShowAutocompleteCityOfBirth(false)
        setSpinnerCityOfBirth(true);
        setNoCityOfBirthFound(false);

        let searchValue = e.target.value; 
        setLabelCityOfBirth(e.target.value)
        if(searchValue.trim()) {
            fetch(`${REACT_APP_API_URL}/api/cities?allData=0&search=`+e.target.value+`&limit=10&sortCapital=1`)
            .then(response => 
                response.json()
            )
            .then(json => {
                setSpinnerCityOfBirth(false);
                setCitiesOfBirthList(json.items);
                if(json.items.length > 0) {
                    setShowAutocompleteCityOfBirth(true)
                } else {
                    setShowAutocompleteCityOfBirth(false)
                    setNoCityOfBirthFound(true)
                }
            });
        } else {
            setShowAutocompleteCityOfBirth(false)
            setSpinnerCityOfBirth(false);
            setCitiesOfBirthList([]);
        }
    };

    const selectCityOfBirth = (city, currentLanguage) => {
        if(currentLanguage === "ar") {
            setLabelCityOfBirth(city.nameAr);
        } else if(currentLanguage === "ru") {
            setLabelCityOfBirth(city.nameRu);
        } else {
            setLabelCityOfBirth(city.name);
        }

        setCityOfBirth(city.id);
        setCitiesOfBirthList([]);
        setShowAutocompleteCityOfBirth(false)
        setSpinnerCityOfBirth(false);
    }

    const handleSubmitDeleteProfile = event => {
        event.preventDefault();

        document.querySelector('.popuplogin').classList.toggle('open')
    } 

    const openLogIn = () => {
        document.querySelector('.popuplogin').classList.toggle('open')
    }

    const handleSubmitConfirmationDeleteProfile = event => {
        event.preventDefault();

        setErrorFormDeleteUser(false);
        if(!emailDeleteUser) {
            setErrorFormDeleteUser(true);
        } else {
            fetch(`${REACT_APP_API_URL}/api/users/delete`, {
                method: "DELETE",
                body: JSON.stringify({
                    "email": emailDeleteUser,
                    "description": descriptionDeleteUser
                }),
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                },
                }).then(() => {
                    logout();
                    setIsAuthenticated(false);
                    navigate('/deleted-profile');
                })
            ;
        }
    }

    return (
        <>
        <div className="widget search-widget">
            <div className="widget-inner">
                <div className="widget-title">
                    <h5>{t('edit_profile')}</h5>
                </div>
                <div className="widget-content">
                    <form className="banner-form" onSubmit={handleSubmit}> 
                        <div className="person">
                            <div className="profile-item">
                                <label>{t('photo')}:</label>
                                <div className="profile-information" style={{marginTop: 0}}>
                                    <div className="profile-pic">
                                        {selectedProfileImage ? (
                                                <img src={editProfileImage} alt="edit-photo" />
                                            ) : (
                                                <img src={profileImage} alt="edit-photo" />
                                            )}
                                        <div className="custom-upload">
                                            <div className="file-btn">
                                                <span className="d-none d-lg-inline-block"> <i className="icofont-camera"></i>
                                                    {t('edit')}</span>
                                                <span className="d-lg-none mr-0"><i className="icofont-plus"></i></span>
                                            </div>
                                            <input type="file" name="change_profile_image" onChange={changeProfileImage} accept="image/*" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="person">
                            <div className="w-100">
                                <label style={!email ? {color: "#f24570"}: {}}>{t('email')}:</label>&nbsp;<span style={{fontSize: "12px", color:"#aaa"}}>({t('will_never_be_shown_to_others')})</span>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={t('enter_your_email')}
                                    value={email}
                                    onChange={(e)=>{setEmail(e.target.value);}}
                                />
                            </div>
                        </div>
                        <div className="person">
                            <div className="w-100">
                                <label style={!firstName ? {color: "#f24570"}: {}}>{t('first_name')}:</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder={t('enter_your_first_name')}
                                    value={firstName}
                                    onChange={(e)=>{setFirstName(e.target.value);}}
                                />
                            </div>
                        </div>
                        <div className="person">
                            <div className="w-100">
                            <label>{t('last_name')}:</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder={t('enter_your_last_name')}
                                    value={lastName}
                                    onChange={(e)=>{setLastName(e.target.value);}}
                                />
                            </div>
                        </div>
                        <div className="gender">
                            <div className="custom-select right w-100">
                                <label>{t('gender')}:</label>
                                <select name="gender" value={gender} onChange={(e)=>{setGender(e.target.value);}}>
                                    <option value="" key="gender-empty"></option>
                                    <option value="1" key="gender-1">{t('male')}</option>
                                    <option value="2" key="gender-2">{t('female')}</option>
                                    <option value="3" key="gender-3">{t('other')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="gender">
                            <div className="custom-select right w-100">
                                <label>{t('age')}:</label>
                                <select name="age" value={age} onChange={(e)=>{setAge(e.target.value);}}>
                                    <option value=""></option>
                                    {getAges().map((age) => (
                                            <option value={age} key={`age-${age}`}>{age}</option>
                                        ))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group gender">
                            <div className="custom-select right">
                            <label>{t('country')}:</label>
                                <select name="country" value={countryId} onChange={changeCountry}>
                                    <option value=""></option>
                                    {countriesList.map(
                                        country => 
                                            <option key={country.key ? "reg_country_"+country.key : "reg_country_"+country.id} value={country.id}>
                                                {getCountryNameByLang(country, currentLanguage)}
                                            </option>
                                        )}
                                </select>
                            </div>
                        </div>
                        {countryId ? (
                                <div className="person">
                                    <div className="w-100">
                                    <label>{t('city')}:</label>
                                        <input
                                            type="text"
                                            name="searchCity"
                                            id="regSearchcity"
                                            value={cityName}
                                            onChange={getCitiesApi}
                                            placeholder={t('search_city_here')}
                                            style={{
                                                padding: "14px 15px",
                                                borderRadius: "4px",
                                                boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
                                            }}
                                        />
                                        <div>
                                    
                                    {spinner ? 
                                            <TevifSpinner/> 
                                    : null }
                                    { showAutocompleteCities ? (
                                        <div className="widget widget-category">
                                            <ul className="lab-ul widget-wrapper list-bg-none">
                                                {citiesList.map((city, index) => (
                                                    <li key={`${city.name}-${index}`}>
                                                        <a 
                                                            className="d-flex flex-wrap justify-content-between" 
                                                            onClick={() => selectCity(city, currentLanguage)}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            {getCityNameByLang(city, currentLanguage)},&nbsp;{getCountryNameByLang(city.country, currentLanguage)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul> 
                                        </div>
                                    ) : null }
                                    {noCitiesFound ? (
                                        <div className="form-group">
                                            <p className="mb-4" style={{"color": "#f24570"}}>{t('city_not_found')}</p>
                                        </div>
                                    ) : null}                                    
                                </div>
                                    </div>
                                </div>
                            ) : null }

                        <div className="person">
                            <div className="w-100" style={{marginTop: "20px"}}>
                                <label>{t('the_languages_spoken')}:</label>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">    
                                    <li className="nav-item" key={`li_lang_arabic`}>
                                        <label htmlFor={`id_language_arabic`}>
                                        <input type="checkbox" name={`language_arabic`} id={`id_language_arabic`} checked={languageArabic} onChange={() => handleChange(1)}/>
                                        <span style={{
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/arabic.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_bengali`}>
                                        <label htmlFor={`id_language_bengali`}>
                                        <input type="checkbox" name={`language_bengali`} id={`id_language_bengali`} checked={languageBengali} onChange={() => handleChange(2)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/bengali.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_chinese`}>
                                        <label htmlFor={`id_language_chinese`}>
                                        <input type="checkbox" name={`language_chinese`} id={`id_language_chinese`} checked={languageChinese} onChange={() => handleChange(3)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/chinese.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_deutsch`}>
                                        <label htmlFor={`id_language_deutsch`}>
                                        <input type="checkbox" name={`language_deutsch`} id={`id_language_deutsch`} checked={languageDeutsch} onChange={() => handleChange(12)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/deutsch.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_english`}>
                                        <label htmlFor={`id_language_english`}>
                                        <input type="checkbox" name={`language_english`} id={`id_language_english`} checked={languageEnglish} onChange={() => handleChange(4)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/english.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_french`}>
                                        <label htmlFor={`id_language_french`}>
                                        <input type="checkbox" name={`language_french`} id={`id_language_french`} checked={languageFrench} onChange={() => handleChange(5)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/french.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_hindi`}>
                                        <label htmlFor={`id_language_hindi`}>
                                        <input type="checkbox" name={`language_hindi`} id={`id_language_hindi`} checked={languageHindi} onChange={() => handleChange(6)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/hindi.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_portuguese`}>
                                        <label htmlFor={`id_language_portuguese`}>
                                        <input type="checkbox" name={`language_portuguese`} id={`id_language_portuguese`} checked={languagePortuguese} onChange={() => handleChange(7)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/portuguese.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_russian`}>
                                        <label htmlFor={`id_language_russian`}>
                                        <input type="checkbox" name={`language_russian`} id={`id_language_russian`} checked={languageRussian} onChange={() => handleChange(8)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/russian.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_spanish`}>
                                        <label htmlFor={`id_language_spanish`}>
                                        <input type="checkbox" name={`language_spanish`} id={`id_language_spanish`} checked={languageSpanish} onChange={() => handleChange(9)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/spanish.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_turkic`}>
                                        <label htmlFor={`id_language_turkic`}>
                                        <input type="checkbox" name={`language_turkic`} id={`id_language_turkic`} checked={languageTurkic} onChange={() => handleChange(11)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/turkic.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                    <li className="nav-item" key={`li_lang_urdu`}>
                                        <label htmlFor={`id_language_urdu`}>
                                        <input type="checkbox" name={`language_urdu`} id={`id_language_urdu`} checked={languageUrdu} onChange={() => handleChange(10)}/>
                                        <span style={{
                                            marginLeft: "5px",
                                            cursor:"pointer", 
                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                            width: "100%", lineHeight: "56px"}}>
                                                <img src={`${REACT_APP_API_URL}/uploads/flags/urdu.png`} style={{
                                                    width: "40px"
                                                    }}/> </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="person">
                            <label>{t('show_me_on_the_map')}:</label>
                            <div className="right d-flex justify-content-between w-100">
                                <div className="col-4">
                                    <SelectYesNo val={showInTheMap} setVal={setShowInTheMap} />
                                </div> 
                            </div>
                        </div>

                        <div className="person">
                            <label>{t('date_of_birth')}:&nbsp;<span style={{fontSize: "12px", color:"#aaa"}}>({t('optional')})</span></label>
                            <div className="right d-flex justify-content-between w-100">
                                <div className="col-4">
                                    <SelectDate val={dayDateOfBirth} setVal={setDayDateOfBirth} />
                                </div> &nbsp;
                                <div className="col-4">
                                    <SelectMonth val={monthDateOfBirth} setVal={setMonthDateOfBirth} />
                                </div> &nbsp;
                                <div className="col-4">
                                    <SelectYear val={yearDateOfBirth} setVal={setYearDateOfBirth} />
                                </div>
                            </div>
                        </div>
                        <div className="person">
                            <div className="w-100">
                                <label>{t('city_of_birth')}:&nbsp;<span style={{fontSize: "12px", color:"#aaa"}}>({t('optional')})</span></label>
                                <input
                                    type="text"
                                    name="origin_city"
                                    id="origin_city"
                                    placeholder={t('enter_your_origin_city')}
                                    value={labelCityOfBirth}
                                    style={{
                                        padding: "14px 15px",
                                        borderRadius: "4px",
                                        boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
                                    }}

                                    onChange={getCityOfBirth}
                                />
                                 <div>
                                {spinnerCityOfBirth ? <TevifSpinner/> : null }
                                    { showAutocompleteCityOfBirth ? (
                                        <div className="widget widget-category">
                                            <ul className="lab-ul widget-wrapper list-bg-none">
                                                {citiesOfBirthList.map((city, index) => (
                                                    <li key={`${city.name}-${index}`}>
                                                        <a 
                                                            className="d-flex flex-wrap justify-content-between" 
                                                            onClick={() => selectCityOfBirth(city, currentLanguage)}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            {getCityNameByLang(city, currentLanguage)},&nbsp;{getCountryNameByLang(city.country, currentLanguage)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul> 
                                        </div>
                                    ) : null }
                                    {noCityOfBirthFound ? (
                                        <div className="form-group">
                                            <p className="mb-4" style={{"color": "#f24570"}}>{t('city_not_found')}</p>
                                        </div>
                                    ) : null}     
                                    </div>
                            </div>   
                        </div>
                        
                        <div className="person">
                            <div className="w-100">
                                <label>{t('description')}:&nbsp;<span style={{fontSize: "12px", color:"#aaa"}}>({t('optional')})</span></label>
                                <textarea
                                    type="text"
                                    name="description"
                                    placeholder={t('enter_your_description')}
                                    value={description}
                                    onChange={(e)=>{setDescription(e.target.value);}}
                                />
                            </div>
                        </div>

                        {errorFormDesc 
                            ? (<p className="mb-4" style={{"color": errorFormColor}}>{errorFormDesc}</p>) 
                            : null
                        }

                        {
                            loaderUpdateProfile ? (
                                <TevifSpinner/>
                            ) : (
                                <button>{t('save_changes')}</button>
                            )
                        }
                    </form>
                </div>
            </div>
        </div>
        <div className="widget search-widget">
            <div className="widget-inner">
                <div className="widget-content">
                    <form className="banner-form" onSubmit={handleSubmitDeleteProfile}> 
                        <button style={{backgroundColor:"#0d3687"}}>{t('delete_your_profile')}</button>
                    </form>
                </div>
            </div>
        </div>
        <div className="popuplogin">
                    <div className="container">
                        <div className="popup__area bgimg" style={isLightMode ? ({backgroundImage: "url(/assets/images/profile/transparent_cover_light_mode.jpg)"}) : ({backgroundImage: "url(/assets/images/profile/transparent_cover.jpg)"})}>
                            <div className="popup__close" onClick={openLogIn}>
                                <i className="icofont-close"></i>
                            </div>
                            <div className="row g-4 align-items-center">
                                <div className="col-xl-12">
                                    <div className="popup__fild">
                                        <h2 className="mb-4">{t('do_you_really_want_to_delete_your_account')}</h2>
                                        {errorFormDeleteUser ? (
                                            <p style={{color:"#f24570"}}>{t('email_address_cannot_be_empty')}</p>
                                        ) : null}
                                        
                                        <form className="banner-form" onSubmit={handleSubmitConfirmationDeleteProfile}>
                                            <div className="age">
                                                <div className="right w-100">
                                                    <input
                                                        type="email"
                                                        name="email_delete_user"
                                                        id="item02"
                                                        placeholder={`${t('enter_your_email')} *`}
                                                        value={emailDeleteUser}
                                                        onChange={(e)=>{setEmailDeleteUser(e.target.value);}}
                                                    />
                                                    <textarea
                                                        type="text"
                                                        name="description_delete_user"
                                                        placeholder={t('tell_us_why')}
                                                        value={descriptionDeleteUser}
                                                        onChange={(e)=>{setDescriptionDeleteUser(e.target.value);}}
                                                    />
                                                </div>
                                            </div>                                      

                                            <button type="submit">{t('delete_your_profile')}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}
 
export default FormEditProfile;