import { Fragment, useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import HeaderTwo from "../../component/layout/headertwo";
import { getHeadersApiListingUsers } from "../../service/User";
import { getItem } from '../../service/LocalStorage';
import jwtDecode from 'jwt-decode';
import LoadMoreMessages from "./loadMoreMessages";
import axios from 'axios';
import ShowMessage from "./showMessage";
import { useTranslation } from "react-i18next";
import { getHourMinuteOfNow } from "../../service/Date";
import Footer from "../../component/layout/mobilefooter";
import { Capacitor } from '@capacitor/core';
import { useWindowSize } from "../../service/Window";
import InitializeAndHideBanner from "../../component/adMob/initializeAndHideBanner";
import TevifSpinner from "../../component/spinner/tevifSpinner";
import { getProfileName } from "../../service/String";

const {REACT_APP_API_URL, REACT_APP_PUSH_SERVER_URL} = process.env;

export default function ShowConveration() {
    const capacitorPlatform = Capacitor.getPlatform(); 
    const navigate = useNavigate();
    const ref = useRef();
    let { participantUsername } = useParams();
    let apiHeaders = getHeadersApiListingUsers();
    
    const userToken = getItem('userToken');
    const tToken = jwtDecode(userToken).t_token;
    const currentUserId = jwtDecode(userToken).user_id;
    const currentUserName = jwtDecode(userToken).username;

    const [spinnerMessage, setSpinnerMessage] = useState(false);
    const [spinnerPostMessage, setSpinnerPostMessage] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState("");    
    const [otherParticipantId, setOtherParticipantId] = useState("");
    const [otherParticipantName, setOtherParticipantName] = useState("");
    const [newItems, setNewItems] = useState([]);
    const [page, setPage] = useState(1);
    const [scrollBool, setScrollBool] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [mediaSelected,setMediaSelected] = useState("");
    const [errorForm,setErrorForm] = useState(false);
    const [formFile, setFormFile] = useState("");
    const [pathMediaSelected, setPathMediaSelected] = useState("");
    const [markItemsAsRead, setMarkItemsAsRead] = useState("");
    const [conversationId, setConversationId] = useState("");
    const [width, height] = useWindowSize();

    const audio = new Audio('./../notification.wav');

    function scrollToRef() {
        ref.current?.scrollIntoView({});
    }

    const handleSubmitMessage = async event => {
        
        event.preventDefault();
                
        let trimStr = messageText.trim();
        if(trimStr.length < 1  && !formFile) {
            return;
        }

        setScrollBool(true);
        setMessageText("");

        if(formFile) {
            setMediaSelected("");
            setSpinnerPostMessage(true);
        }

        const formData = new FormData();
        formData.append('file', formFile);
        formData.append('user_receive', otherParticipantId);
        formData.append('content', messageText);

        axios.post(`${REACT_APP_API_URL}/api/messages`, 
        formData, 
        {
            headers: { 
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer "+ userToken 
            }
        }
        ).then (response => {
                let data = response.data;
                data.creationDateInArray.int_val = getHourMinuteOfNow();
                setSpinnerPostMessage(false);
                setNewItems([data]);
                deleteFileSelected();
                textAreaRef.current.style.height = "56px";
            }).catch(err => {
                setSpinnerPostMessage(false);
            });
    }

    useEffect(() => {
        if(newItems.length > 0) {
            let newMessages = [...messages, ...newItems]
            setMessages(newMessages);
        }

    }, [newItems]);



    useEffect(() => {
        if(markItemsAsRead) {
            let isUpdated = false;
            var arr = [];

            messages.forEach(function(part, index, messages) {
                let message = messages[index];                
                if(message.isRead != true && message.user.id != otherParticipantId) {
                    message.isRead = true;
                    message.readAt = markItemsAsRead;
                    isUpdated = true;
                } 
                
                arr.push(message);
            });

            if(isUpdated) {
                setMessages(arr);
            }
            setMarkItemsAsRead("");
        }
    }, [markItemsAsRead, messages, otherParticipantId]);

    useEffect(() => {
        if(conversationId) {
            // chat live
            let subscribeURLChatLive = new URL(`${REACT_APP_PUSH_SERVER_URL}`);
            subscribeURLChatLive.searchParams.append("topic", `topic/chat-live/${conversationId}/${tToken}`);        
            let sourceChatLive = new EventSource(subscribeURLChatLive.href);
            sourceChatLive.addEventListener('open', () => {
            });
            sourceChatLive.addEventListener('message', (e) => {
                let data = JSON.parse(e.data);
                let dataArray = [data]; 
                audio.play().then(() => {}).catch(error => {
                });;
                setNewItems(dataArray);
    
                fetch(
                    `${REACT_APP_API_URL}/api/messages/${data.id}/is-read`,
                    {
                        method: "POST",
                        headers: apiHeaders
                    }
                )
            });
        
            sourceChatLive.addEventListener('error', (e) => {
            });
            // end chat live
            
            // message is read
            let subscribeURLMsgIsRead = new URL(`${REACT_APP_PUSH_SERVER_URL}`);
            subscribeURLMsgIsRead.searchParams.append("topic", `topic/message-is-read/${conversationId}/${tToken}`);        
    
            let sourceMsgIsRead = new EventSource(subscribeURLMsgIsRead.href);
            
            sourceMsgIsRead.addEventListener('open', () => {
            });
            sourceMsgIsRead.addEventListener('message', (e) => {
                let data = JSON.parse(e.data);
                setMarkItemsAsRead(data.time);
            });
        
            sourceMsgIsRead.addEventListener('error', (e) => {
            });
            // message is read

            return () => {
                sourceMsgIsRead.close();
                sourceChatLive.close();
            };
    
        }        
      }, [conversationId]);

    useEffect(() => {
        if(participantUsername === currentUserName) {
            navigate('/user-not-found');
        }
            
        async function fetchMessages() {
            try {
                setSpinnerMessage(true);
                const response = await fetch(
                    `${REACT_APP_API_URL}/api/conversations/messages/${participantUsername}?page=1&limit=10`, 
                    {
                        method: "GET",
                        headers: apiHeaders
                    }
                )

                if(response.status === 404) {
                    navigate('/user-not-found');
                }
                
                let data = await response.json();
                setSpinnerMessage(false);
                setMessages(data.items);
                var truncateUserName = getProfileName(data.parameters.conversation.otherParticipant.username, data.parameters.conversation.otherParticipant.firstName ?? '', data.parameters.conversation.otherParticipant.lastName ?? '', true);
                setOtherParticipantName(truncateUserName);
                setOtherParticipantId(data.parameters.conversation.otherParticipant.id);
                setConversationId(data.parameters.conversation.id);
                if(data.totalItems > data.currentItems && data.totalPages > 1) {
                    setShowLoadMore(true);
                }
            } catch (err) {}
        }

        fetchMessages();
    }, [participantUsername]); 


    useEffect(() => {
        if(scrollBool && messages.length > 0) {
            scrollToRef();
        }

    }, [messages, scrollBool]);


    const inputRef = useRef(null);

    const fileSelected = (e) => {
        let file = e.target.files[0]
        
        if(file.size) {
            let sizeMo = (file.size)/1000000; 
            if(sizeMo > 300) {
                setErrorForm(true);
                setMediaSelected("");
                setFormFile("");
                inputRef.current.value = null;
                setTimeout(function(){ 
                    setErrorForm(false);
                }, 10000)

            } else {
                setFormFile(e.target.files[0]);
                let typeFile = file.type;

                if(typeFile.includes("image")) {
                    setMediaSelected("image");
                } else {
                    setMediaSelected("video");
                }

                setPathMediaSelected(URL.createObjectURL(e.target.files[0]));
                setTimeout(function(){
                    scrollToRef();
                }, 250);            }
        }
    }

    const deleteFileSelected = () => {
        setMediaSelected("");
        setPathMediaSelected("");
        setFormFile("");
        inputRef.current.value = null;
    }
    
    const {t} = useTranslation();

    const textAreaRef = useRef(null);

    const handleInput = (e) => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${e.target.scrollHeight + 3}px`;
        }
      };

      const [lastKey, setLastKey] = useState("");


      useEffect(() => {
        if(capacitorPlatform === "web") {
            const keyDownHandler = event => {
                if (event.key === 'Enter' && lastKey !== 'Shift') {
                    event.preventDefault();
                    let trimStr = messageText.trim();
                    if(trimStr.length < 1  && !formFile) {
                        return;
                    }
    
                    setScrollBool(true);
                    setMessageText("");
                    
                    if(formFile) {
                        setMediaSelected("");
                        setSpinnerPostMessage(true);
                    }
    
                    const formData = new FormData();
                    formData.append('file', formFile);
                    formData.append('user_receive', otherParticipantId);
                    formData.append('content', messageText);
    
                    axios.post(`${REACT_APP_API_URL}/api/messages`, 
                    formData, 
                    {
                        headers: { 
                            'Content-Type': 'multipart/form-data',
                            "Authorization": "Bearer "+ userToken 
                        }
                    }
                    ).then (response => {
                        let data = response.data;
                        data.creationDateInArray.int_val = getHourMinuteOfNow();
                        setNewItems([data]);
                        deleteFileSelected();
                        textAreaRef.current.style.height = "56px";
                        setSpinnerPostMessage(false);
                    }).catch(err => {
                        setSpinnerPostMessage(false);
                    });
                } else {
                    setLastKey(event.key);
                }
            };
        
            document.addEventListener('keydown', keyDownHandler);
        
            return () => {
              document.removeEventListener('keydown', keyDownHandler);
            };
        }
      }, [formFile, otherParticipantId, messageText, lastKey]);


    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }
    
    const goBack = () => {
        window.history.back();
    }
    
    return (
        <Fragment>
            <HeaderTwo />
            <InitializeAndHideBanner />
            <div className="shop-single padding-tb" style={width < 576 ? {marginTop: marginValue, height: "100vh"} : {height: "100vh"}}>
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-xl-8 col-12">
                            <article>
                                <div className="review">
                                    <ul className="review-nav lab-ul DescActive">
                                        <a onClick={goBack} style={{cursor: "pointer"}}>
                                            <li className="desc" >
                                                <i className="icofont-close"></i>
                                            </li>
                                        </a>
                                        <Link to={`/profile/${participantUsername}`} ><li className="rev" >{otherParticipantName}</li></Link>
                                    </ul>
                                    <div className="review-content review-content-show">
                                        <div className="review-showing">
                                            <div className="container">
                                                <div className="content-wrapper">
                                                    <div className="row gutters">
                                                        <div className="card m-0">
                                                            <div className="row no-gutters">
                                                                <div className="chat-container">
                                                                    <ul className="chat-box chatContainerScroll">
                                                                    {
                                                                            showLoadMore ? (
                                                                                <LoadMoreMessages 
                                                                                    messages={messages}
                                                                                    setMessages={setMessages}
                                                                                    page={page}
                                                                                    setPage={setPage}
                                                                                    apiHeaders={apiHeaders}
                                                                                    setSpinnerMessage={setSpinnerMessage}
                                                                                    participantUsername={participantUsername}
                                                                                    setScrollBool={setScrollBool}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                        
                                                                        { spinnerMessage ? (<TevifSpinner />) : null }
                                                                    {messages.map((val) => (
                                                                        <li key={`li_message_content_${val.id}`} className={currentUserId != val.user.id ? "chat-left" : "chat-right"}>                                                                            
                                                                            <ShowMessage 
                                                                                message={val}
                                                                            />
                                                                        </li>
                                                                    ))}
                                                                        
                                                                    </ul>
                                                                    { spinnerPostMessage ? (<TevifSpinner />) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
 
                                            <div className="client-review">
                                                <div className="review-form">
                                                    <div className="review-title">
                                                        <h5>{t('write_message')}</h5>
                                                    </div>

                                                    {mediaSelected ? (
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-description">
                                                                    <div className="post-desc-img" style={{width: "150px"}}>
                                                                        {
                                                                            mediaSelected === "image" ? (
                                                                                <img 
                                                                                    src={pathMediaSelected} 
                                                                                    alt="img" 
                                                                                />
                                                                            ) : (
                                                                                <video 
                                                                                    width="100%" 
                                                                                    controls 
                                                                                >
                                                                                    <source src={pathMediaSelected}  />
                                                                                </video>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <form className="row" onSubmit={handleSubmitMessage}>
                                                            <div className="col-md-12 col-12" style={{display: "flex"}}>
                                                                <textarea  
                                                                    ref={textAreaRef}
                                                                    rows={1}
                                                                    placeholder={t('type_here_message')} 
                                                                    value={messageText} 
                                                                    onChange={(e)=>{setMessageText(e.target.value);}} 
                                                                    onInput={handleInput}
                                                                    style={{
                                                                        padding: "8px",
                                                                        resize: "none",
                                                                        outline: "none"
                                                                    }}
                                                                />
                                                                <button className="lab-btn" type="submit" style={{height:"56px", marginTop: "0px"}}>
                                                                    <img src="/assets/images/send-white.svg" alt="send" style={{height:"30px", width:"30px"}} />
                                                                </button>
                                                            </div>
                                                            <div className="col-12" style={{marginTop:"5px"}}>
                                                                <label style={{cursor: "pointer"}}>
                                                                    {
                                                                        mediaSelected === "image" ? 
                                                                            (
                                                                                <span style={{color: "#f24570"}}>
                                                                                    <i className="icofont-image"></i>&nbsp;{t('photo_selected')}
                                                                                </span>
                                                                            ) : null
                                                                    }

                                                                    {
                                                                        mediaSelected === "video" ? 
                                                                            (
                                                                                <span style={{color: "#f24570"}}>
                                                                                    <i className="icofont-video-alt"></i>&nbsp;{t('video_selected')}
                                                                                </span>
                                                                            ) : null
                                                                    }

                                                                    {
                                                                        mediaSelected === "" && (<span><i className="icofont-camera"></i>&nbsp;{t('photo')}/{t('video')}</span>)
                                                                    }
                                                                    <input 
                                                                        type="file" 
                                                                        name="formFile" 
                                                                        accept="video/*, image/*" 
                                                                        style={{display: "none"}}
                                                                        onChange={fileSelected} 
                                                                        ref={inputRef}
                                                                    />
                                                                </label>
                                                                {mediaSelected ? (
                                                                    <a onClick={deleteFileSelected} style={{cursor: "pointer"}}>&nbsp;<i className="icofont-trash"></i>&nbsp;{t('cancel')}</a>
                                                                        
                                                                ) : null}
                                                                {
                                                                    errorForm ? (
                                                                        <span style={{"color": "#f24570"}}>&nbsp;{t('the_file_is_too_large_allowed_maximum_size_is_300_mb')}</span>
                                                                    ) : null
                                                                }
                                                                <span ref={ref}></span>
                                                            </div>
                                                        </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {width < 992 ? (<div style={{marginTop:"45px", opacity:"0"}}>Tevif</div>) : null}
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
    
}
 