import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LightMode from "../../../context/LightMode";
import { useWindowSize } from "../../../service/Window";


const {REACT_APP_S_B} = process.env;

function LogoTevif() {    
    const { isLightMode, setIsLightMode } = useContext(LightMode);

    const [currentRoute, setCurrentRoute] = useState("");
    const [logoTevif, setLogoTevif] = useState("");
    const [logoGoBack, setLogoGoBack] = useState("");
    const [width, height] = useWindowSize();

    useEffect(() => {
        if(isLightMode) {
            setLogoTevif(`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/tevif_light_mode.png`);
            setLogoGoBack("/assets/images/go-back-dark-blue.svg");
        } else {
            setLogoTevif(`https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/logo.png`);
            setLogoGoBack("/assets/images/go-back.svg");
        }

    }, [isLightMode]);

    const location = useLocation();
    useEffect(() => {
        if(location) {
            setCurrentRoute(location.pathname);
        }
        
    }, [location]);

    const goBack = () => {
        window.history.back();
    }

    return (
        <div className="logo" style={(process.env.REACT_APP_IS_IOS_APP === "true" && width < 576) ? {marginTop: "20px"} : {}}>
            {(currentRoute.includes("/new") || currentRoute === "/") ? (
                    <NavLink to="/">
                        <img src={logoTevif} alt="logo" />
                    </NavLink>
                ) : (
                    <>
                        {logoGoBack ? (<a  onClick={goBack} style={{cursor: "pointer"}}><img src={logoGoBack} alt="goback" style={{height:"30px", width:"30px"}}/></a>) : null}
                    </>
                )}
        </div>
    );
    
}
 
export default LogoTevif;