import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/headertwo";
import { login } from "../service/AuthApi";
import Auth from "../context/Auth";
import { getAges } from "../service/User"
import { getCityNameByLang, getCountriesApi, getCountryNameByLang } from "../service/CityApi";
import { getItem,setItem } from "../service/LocalStorage";
import { Geolocation } from '@capacitor/geolocation';
import './stylePages/showHidePassword.css';
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../service/Window";
import axios from "axios";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import SelectSignupPhoto from "../component/camera/selectSignupPhoto";
import { validateUsername } from "../service/String";
import Footer from "../component/layout/mobilefooter";

const {REACT_APP_API_URL} = process.env;

function SignUp() {
    const navigate = useNavigate();
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const [regUserName,setRegUserName] = useState("");
    const [regPassword,setRegPassword] = useState("");
    const [regGender,setRegGender] = useState("");
    const [regAge,setRegAge] = useState("");
    const [regCountry,setRegCountry] = useState("");
    const [regCityId,setRegCityId] = useState("");
    const [regCityName,setRegCityName] = useState("");
    const [regDesc,setRegDesc] = useState("");
    const [colorRegDesc,setColorRegDesc] = useState("");
    const [regFormError,setRegFormError] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [showAutocompleteCities, setShowAutocompleteCities] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [noCitiesFound, setNoCitiesFound] = useState(false);
    const [hasLocationPermission, setHasLocationPermission] = useState(getItem('hasLocationPermission') ?? "false");
    const [positionLat, setPositionLat] = useState(getItem('positionLat') ?? "");
    const [positionLng, setPositionLng] = useState(getItem('positionLng') ?? "");
    const [showPassword, setShowPassword] = useState(false);
    const [spinnerRegister, setSpinnerRegister] = useState(false);
    const [acceptCG, setAcceptCG] = useState(false);
    const [acceptCGError, setAcceptCGError] = useState(false);

    /* form errors */
    const [spinnerL, setSpinnerL] = useState(false);
    const [uError, setUError] = useState(false);
    const [pError, setPError] = useState(false);
    const [gError, setGError] = useState(false);
    const [aError, setAError] = useState(false);
    const [coError, setCoError] = useState(false);
    const [ciError, setCiError] = useState(false);
    const [ciAndCoSelected, setCiAndCoSelected] = useState(false);
    /* end form errors */

    /* img inscription */
    const defaultSignupPhoto = "https://tevif.s3.eu-west-3.amazonaws.com/users/girl_man.jpg";
    const [selectedPhoto,setSelectedPhoto] = useState(defaultSignupPhoto);
    const [formFile, setFormFile] = useState(null);
    /* end img inscription*/

    const {t} = useTranslation();

    const functionUseLocation = async () => {
        setRegFormError(false);
        try {
            const position = await Geolocation.getCurrentPosition({ 
                enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 
            });

            if(position) {
                if(hasLocationPermission !== 'true') {
                    setHasLocationPermission('true');
                    setItem('hasLocationPermission', 'true');
                }

                setPositionLat(position.coords.latitude);
                setPositionLng(position.coords.longitude);
                setItem('positionLat', position.coords.latitude);
                setItem('positionLng', position.coords.longitude);

                setSpinnerL(true);
                fetch(`${REACT_APP_API_URL}/api/public/google-maps-location`, {
                    method: "POST",
                    body: JSON.stringify({
                        "lat": position.coords.latitude,
                        "lng": position.coords.longitude
                    }),
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                  }).then(
                  (response) => (response.json())
                    ).then(async (response)=> {
                        setSpinnerL(false);
                        if(response.country) {
                            setCiAndCoSelected(true);
                            setRegCountry(response.country.id);
                            setRegCityId(response.id);
                            setRegCityName(response.name);
                            
                            setItem('positionCity', response.id);
                            setItem('positionCityName', response.name);
                            setItem('positionCountry', response.country.id);
                            setItem('positionLastUpdate', Date.now());
                        }
                });
             }
        } catch(err) {
            setHasLocationPermission('false');
            setItem('hasLocationPermission', 'false');
            setSpinnerL(false);
        }
    }

    useEffect(() => {        
        if(isAuthenticated) {
            navigate('/news');
        }

        getCountriesApi(currentLanguage).then(
            result => setCountriesList(result));

    }, [isAuthenticated]);


    useEffect(() => {        
        // update form error
        if(regFormError) {
            setTimeout(function() {
                setRegFormError(false);
            }, 3000);
        }

    }, [regFormError]);
    
    const handleSubmit = async event => {
        event.preventDefault();
        
        setAcceptCGError(false);

        setSpinnerRegister(true);
        setRegDesc("");
        setColorRegDesc("");
        setRegFormError(false);


        if(!acceptCG) {
            setAcceptCGError(true);
            setSpinnerRegister(false);
        } else {
            setAcceptCGError(false);
        }


        (regUserName) ? setUError(false) : setUError(true);
        (regPassword) ? setPError(false) : setPError(true);
        (regAge) ? setAError(false) : setAError(true);
        
        let positionCdt = false;

        if(positionLat && positionLng) {
            positionCdt = true;
        } else if((regCountry && regCityName) || (regCountry && regCityId)) {
            positionCdt = true;
        }
        
        (regCityId || positionCdt) ? setCiError(false) : setCiError(true);
        (regCountry || positionCdt) ? setCoError(false) : setCoError(true);

        if(
            ! regUserName 
            || !regPassword
            || !regAge
            || !positionCdt
            || !acceptCG
        ) {
               setRegDesc(t('please_fill_in_all_fields_of_the_form'));
               setColorRegDesc("#f24570");
               setRegFormError(true);
               setSpinnerRegister(false);
        } else {
            sendForm();
        }    
    }

    function sendForm() {
        const formData = new FormData();
        formData.append('profileImage', formFile);
        formData.append('username', regUserName);
        formData.append('password', regPassword);
        formData.append('gender', regGender);
        formData.append('age', regAge);
        formData.append('country', regCountry);
        formData.append('city', regCityId);
        formData.append('cityName', regCityName);
        formData.append('lat', positionLat);
        formData.append('lng', positionLng);
        
        const config = {     
            headers: { 
                'Content-Type': 'multipart/form-data'
            }
        }

        axios.post(`${REACT_APP_API_URL}/api/users/public/register`, formData, config)
        .then ( async response => {
            if (response.data.username === regUserName || response.data.profileImage) {
                setRegFormError(false);
                
                const user = {
                    'username': response.data.username,
                    'password': regPassword
                }
                    //login
                    const responseLogin = await login(user);
                    setIsAuthenticated(responseLogin);
                    resetForm();
                    setSpinnerRegister(false);
                    setIsAuthenticated(true);
                    navigate('/profile');
                    //redirection
            } else if(response.data.code === 400) {
                if(Object.hasOwn(response.errors, 'username')) {
                    setRegDesc(t('that_username_has_been_taken_please_choose_another'));
                    setColorRegDesc("#f24570");
                    setRegFormError(true);
                    setSpinnerRegister(false);
                } else {
                    setRegDesc("Invalid data");
                    setColorRegDesc("#f24570");
                    setRegFormError(true);
                    setSpinnerRegister(false);
                }
            } else {
                setRegDesc(t('an_error_has_occurred_please_retry_later'));
                setColorRegDesc("#f24570");
                setRegFormError(true);
                setSpinnerRegister(false);
            }
        }).catch(err => {
            setRegDesc(t('an_error_has_occurred_please_retry_later'));
            setColorRegDesc("#f24570");
            setRegFormError(true);
            setSpinnerRegister(false);
        })
    }
    const resetForm = () => {
        setRegUserName("")
        setRegPassword("")
        setRegGender("")
        setRegAge("")
        setRegCityId("")
        setRegCityName("")
        setRegCountry("")
        setShowAutocompleteCities(false)
    }


    const getCitiesApi = (e) => {
        setCiError(false);
        setShowAutocompleteCities(false)
        setSpinner(true);
        setNoCitiesFound(false)

        let searchValue = e.target.value; 
        setRegCityName(e.target.value)
        if(searchValue.trim()) {
            fetch(`${REACT_APP_API_URL}/api/cities?allData=0&country=`+regCountry+`&search=`+e.target.value+`&limit=10&sortCapital=1`)
            .then(response => 
                response.json()
            ).then(json => {
                setSpinner(false);
                setCitiesList(json.items);
                if(json.items.length > 0) {
                    setShowAutocompleteCities(true)
                } else {
                    setShowAutocompleteCities(false)
                    setNoCitiesFound(true)
                }
            });
        } else {
            setShowAutocompleteCities(false)
            setSpinner(false);
            setCitiesList([]);
        }
    };


    const changeCountry = (e) => {
        setRegCountry(e.target.value);
        setCoError(false);
        setRegCityName("");
        setRegCityId("")
        setCitiesList([]);
        setShowAutocompleteCities(false)
        setSpinner(false);
    }
    
    const selectCity = (id, name) => {
        setRegCityName(name);
        setRegCityId(id)
        setCitiesList([]);
        setShowAutocompleteCities(false)
        setSpinner(false);
    }

    const [unAcceptedCaracter, setUnAcceptedCaracter] = useState(false);

    const changeUsername = (e) => {
        setUnAcceptedCaracter(false);
        setUError(false);
        var newVal = e.target.value;

        if(!validateUsername(newVal)) {
            setUnAcceptedCaracter(true);
        } else {
            setRegUserName(e.target.value);
        }
    }

    const [width, height] = useWindowSize();

    const openConditionGeneral = () => {
        document.getElementById('popup_general_condition').classList.toggle('open');
    }

    
    return (
        <Fragment>
            <Header />
            <InitializeAndHideBanner />
            <div className="login-section padding-tb" style={(width < 576 && process.env.REACT_APP_IS_IOS_APP === "true") ? {marginTop: "35px"} : {}}>
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{t('register_now')}</h3>
                        <form className="account-form banner-form" onSubmit={handleSubmit}>
                            <SelectSignupPhoto
                                formFile={formFile}
                                setFormFile={setFormFile}
                                selectedPhoto={selectedPhoto}
                                setSelectedPhoto={setSelectedPhoto}
                                defaultSignupPhoto={defaultSignupPhoto}
                                />              
                            <div className="form-group">
                                <>{uError ? (<label style={{color: "red"}}>{t('username')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('username')} *</label>)}</>
                                <input
                                    style={uError ? {borderColor: "red"}: {}}
                                    type="text"
                                    name="username"
                                    id="regUserName"
                                    value={regUserName}
                                    onChange={changeUsername}
                                    placeholder={t('enter_your_username')}
                                />
                                <>{unAcceptedCaracter ? (<p style={{color: "red"}}>{t('the_username_should_not_contain_special_characters_such_as')}</p>) : null}</>
                            </div>
                            <div className="form-group password-container">
                                <>{pError ? (<label style={{color: "red"}}>{t('password')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('password')} *</label>)}</>
                                <input
                                    style={pError ? {borderColor: "red"}: {}}
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    id="regPassword"
                                    value={regPassword}
                                    onChange={(e)=>{setPError(false); setRegPassword(e.target.value);}}
                                    placeholder={t('enter_your_password')}
                                    className="my-form-control"
                                />
                                {regPassword ? (
                                        <>
                                          {showPassword ? (
                                                <i className="icofont-eye-blocked fa-eye" onClick={() => setShowPassword(false)}></i>
                                            ) : (
                                                <i className="icofont-eye fa-eye" onClick={() => setShowPassword(true)}></i>
                                            )}
                                        </>
                                    ): null}
                            </div>
                            <div className="form-group gender">
                                <div className="custom-select right">
                                    <>{gError ? (<label style={{color: "red"}}>{t('gender')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('gender')} *</label>)}</>
                                    <div style={gError ? {border: "1px solid red"}: {}}>
                                        <select value={regGender} onChange={(e)=>{setGError(false); setRegGender(e.target.value);}}>
                                            <option value=""></option>
                                            <option value="1">{t('male')}</option>
                                            <option value="2">{t('female')}</option>
                                            <option value="3">{t('other')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group age">
                                <div className="custom-select right">
                                <>{aError ? (<label style={{color: "red"}}>{t('age')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('age')} *</label>)}</>
                                    <div style={gError ? {border: "1px solid red"}: {}}>
                                        <select value={regAge} onChange={(e)=>{setAError(false); setRegAge(e.target.value);}}>
                                            <option value=""></option>
                                            {getAges().map((age) => (
                                                <option value={age} key={`age-${age}`} >{age}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <> {ciAndCoSelected ? null : (
                                <>
                                {spinnerL === true ? (<TevifSpinner/>) : (
                                <>
                                <div className="form-group">
                                    <span onClick={functionUseLocation} style={{
                                        color:"#FFFFFF",
                                        cursor:"pointer", 
                                        backgroundColor: "#f24570", 
                                        borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                        width: "100%", lineHeight: "56px"}}>{t('use_geolocation')} <i className="icofont-location-pin"></i></span>
                                </div>
                                <div className="form-group gender">
                                    <div className="custom-select right">
                                        <>{coError ? (<label style={{color: "red"}}>{t('country')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('country')} *</label>)}</>
                                        <div style={coError ? {border: "1px solid red"}: {}}>
                                            <select value={regCountry}  onChange={changeCountry}>
                                                <option value="">
                                                </option>
                                                {countriesList.map(
                                                    country => <option key={country.key ? "reg_country_"+country.key : "reg_country_"+country.id} value={country.id}>
                                                        {getCountryNameByLang(country, currentLanguage)} 
                                                    </option>
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <>{ciError ? (<label style={{color: "red"}}>{t('city')} * <i className="icofont-warning-alt"></i></label>) : (<label>{t('city')} *</label>)}</>
                                    <>{
                                        !regCountry ? (
                                            <input
                                                type="text"
                                                name="searchCity"
                                                id="regSearchcity"
                                                value={regCityName}
                                                onChange={getCitiesApi}
                                                placeholder={(regCountry)? t("search_city_here") : t("first_select_a_country")}
                                                disabled = {(!regCountry)? "disabled" : ""}
                                                style={ciError ? {
                                                    opacity:"0.5",
                                                    borderColor: "red",
                                                    padding: "14px 15px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
                                                }: {
                                                    opacity:"0.5",
                                                    padding: "14px 15px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
        
                                                }}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name="searchCity"
                                                id="regSearchcity"
                                                value={regCityName}
                                                onChange={getCitiesApi}
                                                placeholder={(regCountry)? t("search_city_here") : t("first_select_a_country")}
                                                disabled = {(!regCountry)? "disabled" : ""}
                                                style={ciError ? {
                                                    borderColor: "red",
                                                    padding: "14px 15px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
                                                }: {
                                                    padding: "14px 15px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0px 2px 2px 0px rgb(242 69 112 / 10%)"
        
                                                }}
                                            />
                                        )
                                    }</>
                                    {spinner ? 
                                            <TevifSpinner/> 
                                    : null }
                                    { showAutocompleteCities ? (
                                        <div className="widget widget-category" style={{backgroundColor: "#dddddd"}}>
                                            <ul className="lab-ul widget-wrapper list-bg-none">
                                                {citiesList.map((city, index) => (
                                                    <li key={`${city.name}-${index}`}>
                                                        <a 
                                                            className="d-flex flex-wrap justify-content-between" 
                                                            onClick={() => selectCity(city.id, city.name)}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            {getCityNameByLang(city, currentLanguage)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul> 
                                        </div>
                                    ) : null }
                                    {/* noCitiesFound ? (
                                        <div className="form-group">
                                            <p className="mb-4" style={{"color": "#f24570"}}>{t('city_not_found')}</p>
                                        </div>
                                    ) : null */}                                    
                                </div>
                                </>
                            )}
                                </>
                            )}
                            </>

                            <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="acceptCG" id="acceptCG"  value={acceptCG} onChange={(e)=>{setAcceptCG(e.target.value)}} />
                                            <label htmlFor="acceptCG" style={acceptCGError ? {color: "#FF0000", fontWeight: 900} : {color: "var(--body-txt-color)"}} >{t('accept_general_condition')}</label>
                                        </div>
                                        <a onClick={openConditionGeneral} style={{cursor: "pointer"}}>{t('general_condition')}</a>
                                    </div>
                                </div>

                            <div className="form-group">
                                {regFormError 
                                    ? (<p className="mb-4" style={{"color": "red"}}>{regDesc}</p>) 
                                    : null
                                }
                                {spinnerRegister ? 
                                        <TevifSpinner/> 
                                : (<button className="d-block lab-btn"><span>{t('get_started_now')}</span></button>) }
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10"><Link to="/login">{t('are_you_a_member')} <span style={{color: "#f24570"}}><b>{t('login')}</b></span></Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <AppSectionTwo />
            <Footer />

            <div className="popup" id="popup_general_condition">
                <div className="container">
                    <div className="popup__area" style={{
                            backgroundImage: "none"
                            }}>
                        <div className="popup__close" onClick={openConditionGeneral}>
                            <i className="icofont-close"></i>
                        </div>
                        <div className="row g-4" style={{
                            maxHeight: "300px",
                            overflow: "scroll"
                            }}>
                                <div className="col-xl-6">
                                    <div className="popup__fild">
                                        <h2 className="mb-4">{t('general_condition')}</h2>
                                        <p>{t('general_condition_t_1')}</p>
                                        <div className="terms-text">
                                            <ol>
                                                <li>
                                                    <h5>{t('secure_messaging')}</h5>
                                                    <p>{t('secure_messaging_desc')}</p>
                                                </li>
                                                <li>
                                                    <h5>{t('location_sharing')}</h5>
                                                    <p>{t('location_sharing_desc')}</p>
                                                </li>
                                                <li>
                                                    <h5>{t('device_information_collection')}</h5>
                                                    <p>{t('device_information_collection_desc')}</p>
                                                </li>
                                                <li>
                                                    <h5>{t('content_respect')}</h5>
                                                    <p>{t('content_respect_desc')}</p>
                                                </li>
                                                <li>
                                                    <h5>{t('links_to_third_party_sites')}</h5>
                                                    <p>{t('p_6_dec')}</p>
                                                </li>
                                                <li>
                                                    <p>{t('general_condition_t_2')}</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </Fragment>
    );
}
 
export default SignUp;