import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getItem } from "../../service/LocalStorage";


let MeetList = [
    {
        imgUrl: 'assets/images/meet/06.jpg',
        imgAlt: 'Dating Meet',
        title: 'Paris, France',
        titleAr: 'باريس, فرنسا',
        titleRu: 'Париж, Франция',
        url: "members-in-a-map?country=22&lat=48.8566&lng=2.3522&mapZoom=10"
    },
]

let MeetListTwo = [
    {
        imgUrl: 'assets/images/meet/02.jpg',
        imgAlt: 'Dating Meet',
        title: 'London, UK',
        titleAr: 'لندن, المملكة المتحدة',
        titleRu: 'Лондон Сити, Великобритания',
        url: "members-in-a-map?country=21&lat=51.5072&lng=-0.1275&mapZoom=10"
    },
    {
        imgUrl: 'assets/images/meet/03.jpg',
        imgAlt: 'Dating Meet',
        title: 'Barcelona, Spain',
        titleAr: 'برشلونة, إسبانيا',
        titleRu: 'Барселона, Испания',
        url: "members-in-a-map?country=34&lat=41.3825&lng=2.1769&mapZoom=10"
    },
    {
        imgUrl: 'assets/images/meet/04.jpg',
        imgAlt: 'Dating Meet',
        title: 'Āgra, India',
        titleAr: 'اجرا, الهند',
        titleRu: 'Агра, Индия',
        url: "members-in-a-map?country=3&lat=27.18&lng=78.02&mapZoom=10"
    },
    {
        imgUrl: 'assets/images/meet/05.jpg',
        imgAlt: 'Dating Meet',
        title: 'Dubai, United Arab Emirates',
        titleAr: 'أبوظبي, الإمارات العربية المتحدة',
        titleRu: 'Абу-Даби, Объединенные Арабские Эмираты',
        url: "members-in-a-map?country=50&lat=24.4667&lng=54.3667&mapZoom=10"
    }
]

let MeetListThree = [
    {
        imgUrl: 'assets/images/meet/01.jpg',
        imgAlt: 'Dating Meet',
        title: 'Rome, Italy',
        titleAr: 'روما, إيطاليا',
        titleRu: 'Рим, Италия',
        url: "members-in-a-map?country=59&lat=41.8931&lng=12.4828&mapZoom=10"
    }
]

function Meet() {
    const {t} = useTranslation();
    const currentLanguage = getItem('i18nextLng') ?? "en";

        return (
            <div className="meet padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h2>{t('meet_people_in_your_area')}</h2>
                        <p>{t('meet_description_listen_and_learn')}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center">
                            {MeetList.map((val, i) => (
                                <div className="col-lg-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to={val.url}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to={val.url}><h4 style={{color:"white"}}>
                                                    
                                                {(() => {
                                                        if (currentLanguage === "ar"){
                                                            return (

                                                                <>{val.titleAr}</>
                                                            )
                                                        }

                                                        if (currentLanguage == 'ru'){
                                                            return (
                                                                <>{val.titleRu}</>
                                                            )
                                                        }
                                                        
                                                        return <><>{val.title}</></>;
                                                    })()}
                                                    
                                                    </h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {MeetListTwo.map((val, i) => (
                                <div className="col-lg-3 col-sm-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to={val.url}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to={val.url}><h4 style={{color:"white"}}>
                                                    {(() => {
                                                        if (currentLanguage === "ar"){
                                                            return (

                                                                <>{val.titleAr}</>
                                                            )
                                                        }

                                                        if (currentLanguage == 'ru'){
                                                            return (
                                                                <>{val.titleRu}</>
                                                            )
                                                        }
                                                        
                                                        return <><>{val.title}</></>;
                                                    })()} 
                                                </h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {MeetListThree.map((val, i) => (
                                <div className="col-lg-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to={val.url}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to={val.url}><h4 style={{color:"white"}}>
                                                {(() => {
                                                        if (currentLanguage === "ar"){
                                                            return (

                                                                <>{val.titleAr}</>
                                                            )
                                                        }

                                                        if (currentLanguage == 'ru'){
                                                            return (
                                                                <>{val.titleRu}</>
                                                            )
                                                        }
                                                        
                                                        return <><>{val.title}</></>;
                                                    })()}
                                                    </h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
}
 
export default Meet;