import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/headertwo";
import Auth from "../context/Auth";
import { login } from "../service/AuthApi";
import './stylePages/showHidePassword.css';
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../service/Window";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import Footer from "../component/layout/mobilefooter";

const title = "Login";

function LogIn() {
    const navigate = useNavigate();

    const [user, setUser] = useState({
        username: "",
        password: ""
    }); 
    
    const [errorMessage, setErrorMessage] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [spinnerLogin, setSpinnerLogin] = useState(false);

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...user, [name]: value})
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setSpinnerLogin(true);
        setErrorMessage(0);

        try {
            const response = await login(user);
            setIsAuthenticated(true);
            navigate('/news');
            setSpinnerLogin(false);
        } catch (err) {
            let errData = err.response.data;
            if(errData.code === 401 || errData.code === 404) {
                setErrorMessage(1);
            }

            setSpinnerLogin(false);
        }
    }

    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

    useEffect(() => {
        if(isAuthenticated) {
            navigate('/news');
        }
        
    }, [isAuthenticated]);

    const {t} = useTranslation();
    const [width, height] = useWindowSize();
    
    return (
        <Fragment>
            <Header />
            <InitializeAndHideBanner />
            <div className="login-section padding-tb" style={(width < 576 && process.env.REACT_APP_IS_IOS_APP === "true") ? {marginTop: "35px"} : {}}>
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{t('login')}</h3>
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={handleChange}
                                    placeholder={t('enter_your_username')}
                                />
                            </div>
                            <div className="form-group password-container">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    placeholder={t('enter_your_password')}
                                />
                                {showPassword ? (
                                    <i className="icofont-eye-blocked fa-eye-login" onClick={() => setShowPassword(false)}></i>
                                ) : (
                                    <i className="icofont-eye fa-eye-login" onClick={() => setShowPassword(true)}></i>
                                )}
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <Link to="/forgetpassword">{t('forget_password')}</Link>
                                </div>
                            </div>
                            <div className="form-group">
                                {spinnerLogin ? 
                                        <TevifSpinner/> 
                                : (<button className="d-block lab-btn"><span>{t('submit_now')}</span></button>) }
                            </div>
                        </form>
                        <div className="account-bottom">
                            {
                                errorMessage ? (<span style={{color: "#f24570"}}>{t('username_or_password_incorrect')}</span>) : null
                            }
                            {spinnerLogin ? null
                                : (
                                    <>
                                    <span className="or"><span>{t('or')}</span></span>
                                        <span className="d-block cate pt-10"><Link to="/signup"><span style={{color: "#f24570"}}><b>{t('create_new_account')}</b></span></Link></span>
                                    </>
                                ) }
                        </div>
                    </div>
                </div>
            </div>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}
 
export default LogIn;