import { useEffect, useState } from "react";
import FilterSearch from "../../component/sidebar/filtersearch";
import LikeMember from "../../component/sidebar/like-member";
import LoadMoreMedias from "../media/loadMoreMedia";
import { PhotoProvider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import DeleteMedia from "../media/deleteMedia";
import { useTranslation } from "react-i18next";
import ShowMediaInUserProfile from "./media/showMediaInUserProfile";

const {REACT_APP_API_URL} = process.env;

function Media({
    medias, 
    userId, 
    isAuthenticated, 
    totalMedia, 
    setTotalMedia, 
    isOwner,
    user,
    similarList,
    setProfileUsername
}) {
    const [images,setImages] = useState([]);
    const [videos,setVideos] = useState([]);
    const [videosPage,setVideosPage] = useState(2);
    const [imagesPage,setImagesPage] = useState(2);    
    const [noMoreImages,setNoMoreImages] = useState(false);
    const [noMoreVideos,setNoMoreVideos] = useState(false);
    const [userMediaId,setUserMediaId] = useState("");

    const [nbVideos,setNbVideos] = useState(0);
    const [nbImages,setNbImages] = useState(0);

    useEffect(() => {
        setImages(medias.images.items)
        setVideos(medias.videos.items)
        setUserMediaId(userId)
        setNbVideos(medias.videos.totalItems)
        setNbImages(medias.images.totalItems)
    }, [medias])

    const {t} = useTranslation();

    return (
        <div className="tab-pane fade" id="media" role="tabpanel" aria-labelledby="nav-media-tab">
            <div>
                <div className="row">
                    <div className="col-xl-8">
                        <article>
                            <div className="media-wrapper">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="photos-media-tab" data-bs-toggle="tab" data-bs-target="#photos-media" type="button" role="tab" aria-controls="photos-media"  aria-selected="false">
                                            <i className="icofont-image"></i> {t('photos')} <span>{nbImages}</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video" aria-selected="false">
                                            <i className="icofont-video-alt"></i> {t('videos')} <span>{nbVideos}</span>
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">                                   
                                    <div className="tab-pane show active" id="photos-media" role="tabpanel"
                                        aria-labelledby="photos-media-tab">
                                        <div className="media-title">
                                            <h3>{t('all_photos')}</h3>
                                        </div>
                                        <div className="media-content">
                                        { isAuthenticated ? (
                                                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-4 g-3">
                                                    <PhotoProvider maskOpacity={0.5}>
                                                    {images.map((val, i) => (
                                                        <div className="col" key={val.id}>
                                                            <div className="media-thumb">
                                                                <ShowMediaInUserProfile
                                                                    mediaType={1}
                                                                    path={val.publicPath}
                                                                />
                                                            </div>
                                                            {isOwner ? (
                                                                <DeleteMedia 
                                                                idMedia={val.id} 
                                                                medias={images}
                                                                setMedias={setImages}
                                                                nbMedias={nbImages}
                                                                setNbMedias={setNbImages}
                                                                setTotalMedia={setTotalMedia}
                                                                totalMedia={totalMedia}
                                                            />
                                                            ): null}
                                                            
                                                        </div>
                                                    ))}
                                                    </PhotoProvider>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">                                                    
                                                        <p><i className="icofont-worried"></i> {t('sorry_you_have_to_authenticate_to_see_this_part')} </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        { isAuthenticated ? 
                                            <LoadMoreMedias 
                                                userId={userMediaId}
                                                medias={images}
                                                setMedias={setImages}
                                                mediaType={1}
                                                nbMedia={medias.images.totalItems}
                                                page={imagesPage}
                                                setPage={setImagesPage}
                                                noMoreMedias={noMoreImages}
                                                setNoMoreMedias={setNoMoreImages}
                                            /> 
                                        : null}
                                        </div>
                                    </div>
                                    
                                    <div className="tab-pane fade" id="video" role="tabpanel"
                                        aria-labelledby="video-tab">
                                        <div className="media-title">
                                            <h3>{t('all_videos')}</h3>
                                        </div>
                                        <div className="media-content">
                                            <div className="row">
                                            { isAuthenticated ? (
                                                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-4 g-3">
                                                    {videos.map((val, i) => (
                                                        <div className="col" key={val.id}>
                                                            <div className="media-thumb">
                                                                <ShowMediaInUserProfile
                                                                    mediaType={2}
                                                                    path={val.publicPath}
                                                                />
                                                            </div>
                                                            {
                                                                isOwner ? (
                                                                    <DeleteMedia 
                                                                        idMedia={val.id} 
                                                                        medias={videos}
                                                                        setMedias={setVideos}
                                                                        nbMedias={nbVideos}
                                                                        setNbMedias={setNbVideos}
                                                                        setTotalMedia={setTotalMedia}
                                                                        totalMedia={totalMedia}
                                                                    />
                                                                ) : null
                                                            }
                                                            
                                                        </div>
                                                    ))}
                                                </div>
                                                ) : (
                                                    <div className="col">                                                    
                                                        <p><i className="icofont-worried"></i> {t('sorry_you_have_to_authenticate_to_see_this_part')} </p>
                                                    </div>)}
                                            </div>

                                            { isAuthenticated ? 
                                                <LoadMoreMedias
                                                    userId={userMediaId}
                                                    medias={videos}
                                                    setMedias={setVideos}
                                                    mediaType={2}
                                                    nbMedia={medias.videos.totalItems}
                                                    page={videosPage}
                                                    setPage={setVideosPage}
                                                    noMoreMedias={noMoreVideos}
                                                    setNoMoreMedias={setNoMoreVideos}
                                                /> 
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="col-xl-4">
                        <aside className="mt-5 mt-xl-0">
                            {isOwner ? null : (
                                <LikeMember
                                similarList={similarList}
                                setProfileUsername={setProfileUsername}
                            />
                            )}
                            <FilterSearch 
                                user={user}
                            />
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Media;