import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { getHeadersApiListingUsers } from "../../../service/User";
import LightMode from "../../../context/LightMode";
import TevifSpinner from "../../../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;
const categories = [
    'inappropriate_content',
    'unauthorized_sales',
    'violence',
    'other',
];

const subCatContent = [
    'hate_speech',
    'sexually_explicit_content'
];

const subCatSale = [
    'weapons',
    'narcotics'
];

const subCatViolence = [
    'threats_of_violence',
    'incitement_to_violence'
];


export default function SignalActivity({
    activityId,
    currentUserIdentifier,
    activityUserIdentifier,
    activities,
    setActivities
}) {
    const { isLightMode, setIsLightMode } = useContext(LightMode);
    
    const {t} = useTranslation();

    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [description, setDescription] = useState("");
    const [blockUser, setBlockUser] = useState(false);
    const [hidePost, setHidePost] = useState(false);
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [toggleContent, setToggleContent] = useState(false);
    const [invalidDataForm, setInvalidDataForm] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [successSignalMessage, setSuccessSignalMessage] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const [closeImg, setCloseImg] = useState("");
    const [pointsImg, setPointsImg] = useState("");
    

    const apiHeaders = getHeadersApiListingUsers();

    const changeCategory = (e) => {
        let val = e.target.value;
        setCategory(val);
        if(val === 'inappropriate_content') {
            setSubcategoriesList(subCatContent);
        } else if (val === 'unauthorized_sales') {
            setSubcategoriesList(subCatSale);
        } else if (val === 'violence') {
            setSubcategoriesList(subCatViolence);
        } else {
            setSubcategoriesList([]);
        }

        setSubcategory("");
    }

    const changeSubcategory = (e) => {
        let val = e.target.value;
        setSubcategory(val);
    }

 
      const handleSubmit = async event => {
        event.preventDefault();
        setInvalidDataForm(false);
        setSpinner(true);
        
        if(
            !subcategory 
            && !hidePost
            && !blockUser
            && !description
        ) {
            setInvalidDataForm(true);
            setSpinner(false);
            return;
        } 
        

        fetch(`${REACT_APP_API_URL}/api/report/activity`, {
            method: "POST",
            body: JSON.stringify({
                activity: activityId,
                cause: subcategory,
                hide_post: hidePost ? 'yes' : 'no',
                block_user: blockUser ? 'yes' : 'no',
                description: description
            }),
            headers: apiHeaders,
        })
        .then((response) => (response.json()))
        .then(async (response)=> {
            setSpinner(false);
            setSuccessSignalMessage(true);
            if(hidePost || blockUser){
                await new Promise(resolve => setTimeout(resolve, 7000));
                if(activities !== null && setActivities !== null) {
                    let newActivities = activities.filter(data => data.id != activityId);
                    setActivities(newActivities);
                }
            } else {
                await new Promise(resolve => setTimeout(resolve, 7000));
            }
            
            resetFormData();
            setToggleContent(false);
            setSuccessSignalMessage(false);
        }).catch(err => {
            setErrorApi(true);
            setSpinner(false);
        });
                
    }


    const resetFormData = () => {
        setCategory("");
        setSubcategory("");
        setDescription("");
        setBlockUser(false);
        setHidePost(false);
        setSubcategoriesList([]);
        setInvalidDataForm(false);
        setErrorApi(false);
    }

    useEffect(() => {
        if(isLightMode) {
            setCloseImg("/assets/images/close-black.svg");
            setPointsImg("/assets/images/points-black.svg");
        } else {
            setCloseImg("/assets/images/close.svg");
            setPointsImg("/assets/images/points.svg");
        }

    }, [isLightMode]);


    if(currentUserIdentifier === activityUserIdentifier) {
        return null;
    }

    return (
        <>
        <div style={{float: "right", margin: 25, cursor: "pointer"}}>
            <img src={toggleContent ? closeImg : pointsImg} alt="signal" style={{height:"30px", width:"30px"}} onClick={() => {setToggleContent(toggleContent => !toggleContent)}} />
        </div>
        {!toggleContent ? null : 
        (
            <div style={{zIndex:"100", position:"relative", float: "right", margin: 55, transform: "translate(-150px)"}}>
                <div className="signal-form">
                    <div className="row align-items-end">
                        <div className="banner-content">
                            <div className="intro-form">
                                <div className="intro-form-inner" style={{padding: 5}}>
                                    <h6 className="mb-3" style={{textAlign: "center"}}>{t('report')}</h6>
                                    {successSignalMessage ? (<p style={{textAlign: "center"}}>{t('success_signal')}</p>) : (
                                        <>
                                        {spinner ? (<TevifSpinner/>) : (
                                            <form  onSubmit={handleSubmit} className="banner-form">
                                            <div className="gender">
                                                <div className="custom-select">
                                                    <select value={category} onChange={changeCategory}>
                                                    <option value=""></option>
                                                        {categories.map((val, i) => (
                                                            <option value={val} key={`opt_${i}`}>
                                                                {t(val)}
                                                            </option>    
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            { subcategoriesList && subcategoriesList.length > 1 ? (
                                                <div className="gender">
                                                    <div className="custom-select">
                                                        <select value={subcategory} onChange={changeSubcategory}>
                                                        <option value=""></option>
                                                            {subcategoriesList.map((val, i) => (
                                                                <option value={val} key={`opt_${i}`}>
                                                                    {t(val)}
                                                                </option>    
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div  style={{marginTop:"5px", marginBottom: "5px"}}>
                                                <div style={{display:"inline-block", marginRight:"10px"}}>
                                                    <input type="checkbox" name={`hide_the_post_${activityId}`} id={`hide_the_post_${activityId}`} value={hidePost} onChange={(e)=>{setHidePost(e.target.value)}} />
                                                </div>
                                                <div  style={{display:"inline-block"}}>
                                                    <label htmlFor={`hide_the_post_${activityId}`}>{t('hide_the_post')}</label>
                                                </div>
                                            </div>
                                            {activities !== null && setActivities !== null ? (
                                                <div style={{marginTop:"5px", marginBottom: "5px"}}>
                                                    <div style={{display:"inline-block", marginRight:"10px"}}>
                                                        <input type="checkbox" name={`block_the_profile_of_${activityId}`} id={`block_the_profile_of_${activityId}`} value={blockUser} onChange={(e)=>{setBlockUser(e.target.value)}} />
                                                    </div>
                                                    <div  style={{display:"inline-block"}}>
                                                        <label htmlFor={`block_the_profile_of_${activityId}`}>{t('block_the_profile_of')} {activityUserIdentifier}</label>
                                                    </div>
                                                </div>
                                            ) : null}
                                            
                                            <div className="gender">
                                                <textarea 
                                                    rows="3" 
                                                    type="text"
                                                    id="conMessage"
                                                    name="message"
                                                    value={description}
                                                    onChange={(e)=>{setDescription(e.target.value)}}
                                                    placeholder={`${t("add_a_comment")}`}
                                                ></textarea>
                                            </div>
                                            {invalidDataForm ? (<p style={{color: "#f24570", textAlign: "center"}}>{t('invalid_data')}</p>) : null}
                                            {errorApi ? (<p style={{color: "#f24570", textAlign: "center"}}>{t('an_error_has_occurred_please_retry_later')}</p>) : null}
                                            <button type="submit">{t('send')}</button>
                                        </form>
                                        )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
        )}
    </>
    );
}