import { getAges } from "../../service/User";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCountriesApi } from "../../service/CityApi";
import { getItem } from "../../service/LocalStorage";
import { useTranslation } from "react-i18next";

function FilterSearch({user}) {
    const navigate = useNavigate();
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const [genderForm,setGenderForm] = useState("");
    const [ageFrom,setAgeFrom] = useState("");
    const [ageTo,setAgeTo] = useState("");
    const [countryForm,setCountryForm] = useState("");
    const [countriesList, setCountriesList] = useState([]);
    const [searchType, setSearchType] = useState("map");

    useEffect(() => {
        if(user) {
            let ageFromForm = user.age - 10;
            if (ageFromForm < 18) {
                ageFromForm = 18;
            }
    
            let ageToForm = user.age + 10;
            if (ageToForm > 70) {
                ageToForm = 70;
            }
    
            setAgeFrom(ageFromForm);
            setAgeTo(ageToForm);
            setCountryForm(user.city.country.id);
        }

        getCountriesApi(currentLanguage).then(result => setCountriesList(result));
    }, [user]);

    const handleSubmit = async event => {
        event.preventDefault();
        if(searchType === 'map') {
            let mapZoom = 5;
            let positionLat = getItem("positionLat") ?? "";
            let positionLng = getItem("positionLng") ?? "";
            
            if(positionLat && positionLng) {
                mapZoom = 10;
            }
        
            let path = `gender=${genderForm}&age_from=${ageFrom}&age_to=${ageTo}&country=${countryForm}&lat=${positionLat}&lng=${positionLng}&mapZoom=${mapZoom}`;
            navigate(`/members-in-a-map?${path}`);    
        } else {   
            let path = `gender=${genderForm}&age_from=${ageFrom}&age_to=${ageTo}&country=${countryForm}&city=&cityName=&order_field=score&page=1`;
            navigate(`/members-classic-mode?${path}`);
        }
        
    }

    const {t} = useTranslation();

    return (
        <div className="widget search-widget">
            <div className="widget-inner">
                <div className="widget-title">
                    <h5>{t('filter')}</h5>
                </div>
                <div className="widget-content">
                    <form className="banner-form" onSubmit={handleSubmit}>
                    <div className="gender">
                            <div className="custom-select right w-100">
                                <select value={searchType} onChange={(e)=>{setSearchType(e.target.value);}}>
                                    <option value="">{t('search_type')}</option>
                                    <option value="map">{t('in_a_map')}</option>
                                    <option value="classic">{t('classic')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="gender">
                            <div className="custom-select right w-100">
                                <select value={genderForm} onChange={(e)=>{setGenderForm(e.target.value);}}>
                                    <option value="">{t('gender')}</option>
                                    <option value="1">{t('male')}</option>
                                    <option value="2">{t('female')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="age">
                            <div
                                className="right d-flex justify-content-between w-100">
                                <div className="custom-select">
                                    <select value={ageFrom} onChange={(e)=>{setAgeFrom(e.target.value);}}>
                                        <option value=""></option>
                                        {getAges().map((age) => (
                                            <option value={age} key={`age-${age}`} >{age}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="custom-select">
                                    <select value={ageTo} onChange={(e)=>{setAgeTo(e.target.value);}}>
                                        <option value=""></option>
                                        {getAges().map((age) => (
                                            <option value={age} key={`age-${age}`} >{age}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="city">
                            <div className="custom-select right w-100">
                                <select value={countryForm} onChange={(e)=>{setCountryForm(e.target.value);}}>
                                    <option value=""></option>
                                    {countriesList.map(
                                        c => <option key={c.key ? "country_"+c.key : "country_"+c.id} value={c.id}>
                                            {(() => {
                                                    if (currentLanguage === "ar"){
                                                        return (

                                                            <>{c.nameAr}</>
                                                        )
                                                    }

                                                    if (currentLanguage == 'ru'){
                                                        return (
                                                            <>{c.nameRu}</>
                                                        )
                                                    }
                                                    
                                                    return <>{c.name}</>;
                                                })()}
                                            </option>
                                        )}
                                </select>
                            </div>
                        </div>
                        <button>{t('find')}</button>

                    </form>
                </div>
            </div>
        </div>
    );
}
 
export default FilterSearch;