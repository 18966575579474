import { useTranslation } from "react-i18next";

function SelectSignupPhoto({formFile, setFormFile, selectedPhoto, setSelectedPhoto, defaultSignupPhoto}) {    
    
    const {t} = useTranslation();
  
    const changeProfileImage = (e) => {
      try {
          if(e.target.files[0]) {
            setFormFile(e.target.files[0]);
            setSelectedPhoto(URL.createObjectURL(e.target.files[0]));
          }
      } catch(err) {
          //ignore
      }
    }

    const cancelUpdatePhoto = (e) => {
        setSelectedPhoto(defaultSignupPhoto);
        setFormFile(null);
    }

    return (
      <>
        <div className="form-group">
          <section className="profile-section">
            <div className="member-profile">
              <div className="profile-item">
                <div className="profile-information" style={{marginTop: 0}}>
                  <div className="profile-pic" style={{margin: "auto auto"}}>
                    <img src={selectedPhoto} alt="edit-photo" />
                  </div>   
                </div>
              </div>
            </div>
          </section>
          <span style={{fontSize: "12px", color:"#aaa"}}>{t('optional')}</span>
        </div>
        {formFile ? (
          <div className="form-group">
            <div className="file-btn">
                <span className="validate-profile-pic-custom-upload d-lg-inline-block" style={{marginLeft:"10px", cursor: "pointer"}}  onClick={cancelUpdatePhoto}> 
                    <i className="icofont-close-circled"></i> {t('cancel')}
                </span>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <div className="custom-upload" style={{opacity: 1}}>
              <div className="file-btn">
                <label htmlFor="user_photo" className="validate-profile-pic-custom-upload">
                    <span className="d-lg-inline-block"> <i className="icofont-camera"></i>{t('select_a_photo')}</span>
                    <input type="file" id="user_photo" onChange={changeProfileImage} accept="image/*" />
                </label>
              </div>
            </div>
          </div>
        )}
      </>
    );    
}
 
export default SelectSignupPhoto;