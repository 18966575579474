import { useEffect, useRef, useState } from "react";
import { getItem } from '../../../service/LocalStorage';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../../../component/spinner/tevifSpinner";
import { getProfileName } from "../../../service/String";

const {REACT_APP_API_URL} = process.env;

function FormPostActivity({
    user, 
    activities, 
    setActivities, 
    setMedias
}) {
    const inputRef = useRef(null);
    const {t} = useTranslation();
    let userToken = getItem('userToken');
    
    const [spinner,setSpinner] = useState(false);
    const [mediaSelected,setMediaSelected] = useState("");

    // form Error messages
    const [errorFormDesc,setErrorFormDesc] = useState("");
    const [errorFormColor,setErrorFormColor] = useState("");
    const [formFile, setFormFile] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [pathMediaSelected, setPathMediaSelected] = useState("");
    const [formUserProfilePicture, setFormUserProfilePicture] = useState("");

    useEffect(() => {
        if(user && user.publicPathCropedProfilePicture) {
            setFormUserProfilePicture(user.publicPathCropedProfilePicture);
        }
    }, [user]);


    const fileSelected = (e) => {
        setErrorFormDesc("")
        setErrorFormColor("")
        let file = e.target.files[0]
        
        if(file.size) {
            let sizeMo = (file.size)/1000000; 
            if(sizeMo > 300) {
                setErrorFormDesc(t("the_file_is_too_large_allowed_maximum_size_is_300_mb"))
                setErrorFormColor("#f24570")  
                setMediaSelected("")
                setFormFile("")
                
                setTimeout(function(){ 
                    setErrorFormDesc("")
                    setErrorFormColor("")
                }, 10000)

            } else {
                setFormFile(e.target.files[0])
                let typeFile = file.type

                if(typeFile.includes("image")) {
                    setMediaSelected("image");
                } else {
                    setMediaSelected("video");
                }

                setPathMediaSelected(URL.createObjectURL(e.target.files[0]));
            }
        }
    }


    const deleteFileSelected = () => {
        setErrorFormDesc("")
        setErrorFormColor("")
        setFormFile("")
        setMediaSelected("")
        inputRef.current.value = null;
        setPathMediaSelected("");
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setErrorFormDesc("")
        setErrorFormColor("")
        
        if(!formDescription && !formFile) {
            setErrorFormDesc("no data selected.")
            setErrorFormColor("#f24570")
        } else {
            setSpinner(true)
            const formData = new FormData();
		    formData.append('file', formFile);
            formData.append('description', formDescription);

            const config = {     
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer "+ userToken 
                }
            }
            
            axios.post(`${REACT_APP_API_URL}/api/activities`, formData, config)
            .then (response => {
                let responseData = response.data;
                let newActivity = [responseData.activities.items[0]];
                setActivities([...newActivity, ...activities]);
                                
                setErrorFormDesc("");
                setErrorFormColor("");
                setFormFile("");
                setFormDescription("");
                setSpinner(false)
                setMediaSelected("");
                
                try {
                    setMedias(responseData.mediasInfo);
                } catch(err) {
                    //do nothing
                }

            }).catch(err => {
                if(err.response.data.code === 400) {
                    setErrorFormDesc("The files accepted are: image or video and maximum size less than or equal to 300 MB");
                    setErrorFormColor("#f24570");
                    setFormFile("");
                    setFormDescription("");
                    setMediaSelected("");
                    setSpinner(false);
                } else {
                    setErrorFormDesc("An error has occurred. Please retry later");
                    setErrorFormColor("red");
                    setSpinner(false);
                }
            })
        }

        setTimeout(function(){ 
            setErrorFormDesc("");
            setErrorFormColor("");
        }, 10000);
    }


    const textAreaRef = useRef(null);

    const handleInput = (e) => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${e.target.scrollHeight + 3}px`;
        }
      };


    if(spinner) {
        return (<TevifSpinner />);
    } else {
        return (
            <div className="create-post mb-20" style={{borderRadius: "10px"}}>
                <div className="lab-inner">
                    <div className="lab-thumb">
                        <div className="thumb-inner">
                            <div className="thumb-img">
                                <NavLink to={`/profile`} >
                                    {formUserProfilePicture ? (<img 
                                        src={formUserProfilePicture} 
                                        alt="img" 
                                        style={{width: 60, heigh:60}}
                                        className="rounded-circle"
                                    />) : null}
                                </NavLink>
                            </div>
                            <div className="thumb-content">
                                <h6>
                                    <NavLink to={`/profile`} >
                                        {getProfileName(user.username, user.firstName ?? '', user.lastName ?? '', false)}
                                        {user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null}
                                    </NavLink>    
                                </h6>
                            </div>
                        </div>
                        {errorFormDesc 
                        ? (<p className="mb-4" style={{"color": errorFormColor}}>{errorFormDesc}</p>) 
                        : null
                    }
                    </div>
                    <div className="lab-content">
                        <form className="post-form form-activity" onSubmit={handleSubmit}>
                            <textarea  
                                ref={textAreaRef}
                                rows={1}
                                placeholder={t('whats_on_your_mind')} 
                                value={formDescription} 
                                onChange={(e)=>{setFormDescription(e.target.value);}} 
                                onInput={handleInput}
                            />
                            
                            {mediaSelected ? (
                                <div className="post-item mb-20">
                                    <div className="post-content">
                                        <div className="post-description">
                                            <div className="post-desc-img">
                                                {
                                                    mediaSelected === "image" ? (
                                                        <img 
                                                            src={pathMediaSelected} 
                                                            alt="img" 
                                                            style={{
                                                                maxHeight:"500px",
                                                                width: "auto",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                                display: "block"
                                                            }}
                                                        />
                                                    ) : (
                                                        <video 
                                                            width="100%" 
                                                            controls 
                                                            style={{
                                                                maxHeight:"500px",
                                                            }}
                                                        >
                                                            <source src={pathMediaSelected}  />
                                                        </video>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="content-type">
                                <ul className="content-list">
                                    <li className="image-video">
                                        
                                            {mediaSelected && mediaSelected == 'image' ? (
                                                <div className="file-btn" style={{color: "#f24570"}}>
                                                    <i className="icofont-image"></i>{t('photo_selected')}
                                                </div>
                                            ) : null}

                                            {mediaSelected && mediaSelected == 'video' ? (
                                                <div className="file-btn" style={{color: "#f24570"}}>
                                                    <i className="icofont-video-alt"></i>{t('video_selected')}
                                                </div>
                                            ) : null}

                                            {!mediaSelected ? (
                                                <div className="file-btn">
                                                    <label htmlFor="formFile"><i className="icofont-camera"></i>{t('photo')}/{t('video')}</label>
                                                </div>
                                            ) : null}
                                        <input ref={inputRef} type="file" name="formFile" id="formFile" onChange={fileSelected} accept="video/*, image/*"/>
                                    </li>
                                    {mediaSelected ? (
                                        <li className="image-video">
                                            <div className="file-btn">
                                                <a onClick={deleteFileSelected} style={{cursor: "pointer"}}><i className="icofont-trash"></i> {t('delete_selected_media')}</a>
                                            </div>
                                        </li>
                                    ) : null}
                                    <li className="post-submit">
                                        <input type="submit" value={t('post')} className="lab-btn" />
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormPostActivity;