import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getHourMinuteOfNow } from "../../../service/Date";
import { useTranslation } from "react-i18next";
import MediaActivity from "./mediaActivity";
import TevifSpinner from "../../../component/spinner/tevifSpinner";
import axios from "axios";
import { getItem } from "../../../service/LocalStorage";
import DeleteComment from "./deleteComment";
import jwtDecode from "jwt-decode";
import Auth from "../../../context/Auth";
import { getProfileName } from "../../../service/String";

function CommentActivity({
    comments, 
    prettyNbComments, 
    activityId,
    setIdCommentShowLikes
}) 
{
    const userToken = getItem('userToken');
    const currentUserName = userToken ? jwtDecode(userToken).username : null;
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const [listComments, setListcomments] = useState(comments ?? []);
    const [textComment, setTextComment] = useState('');
    const [formFile, setFormFile] = useState("");
    const [spinnerPostComment, setSpinnerPostComment] = useState(false);
    const [mediaSelected,setMediaSelected] = useState("");
    const [errorForm,setErrorForm] = useState(false);
    const [pathMediaSelected, setPathMediaSelected] = useState("");
    const [newItems, setNewItems] = useState([]);

    const [nbComments, setNbComments] = useState(prettyNbComments);
    const [idCommentToDelete, setIdCommentToDelete] = useState(0);
    

    const inputRef = useRef(null);
    

    useEffect(() => { 
        if(idCommentToDelete && idCommentToDelete > 0) {
            var newCommentList = listComments.filter(obj => obj.id !== idCommentToDelete);
            setListcomments(newCommentList);
        }
    }, [idCommentToDelete]);
    


    const fileSelected = (e) => {
        let file = e.target.files[0]
        
        if(file.size) {
            let sizeMo = (file.size)/1000000; 
            if(sizeMo > 300) {
                setErrorForm(true);
                setMediaSelected("");
                setFormFile("");
                inputRef.current.value = null;
                setTimeout(function(){ 
                    setErrorForm(false);
                }, 10000)

            } else {
                setFormFile(e.target.files[0]);
                let typeFile = file.type;

                if(typeFile.includes("image")) {
                    setMediaSelected("image");
                } else {
                    setMediaSelected("video");
                }

                setPathMediaSelected(URL.createObjectURL(e.target.files[0]));
            }
        }
    }

    const deleteFileSelected = () => {
        setMediaSelected("");
        setPathMediaSelected("");
        setFormFile("");
        inputRef.current.value = null;
    }


    const handleSubmitComment = async event => {
        
        event.preventDefault();
                
        let trimStr = textComment.trim();
        if(trimStr.length < 1  && !formFile) {
            return;
        }

       setTextComment("");

        if(formFile) {
            setMediaSelected("");
            setSpinnerPostComment(true);
        }

        const formData = new FormData();
        formData.append('file', formFile);
        formData.append('id_activity', activityId);
        formData.append('content', textComment);

        axios.post(`${process.env.REACT_APP_API_URL}/api/comment`, 
        formData, 
        {
            headers: { 
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer "+ userToken 
            }
        }
        ).then (response => {
                let data = response.data;
                data.createdAtInArray.int_val = getHourMinuteOfNow();
                setSpinnerPostComment(false);
                setNewItems([data]);
                deleteFileSelected();
                setNbComments(data.nbCommentActivity);
            }).catch(err => {
                setSpinnerPostComment(false);
            });
    }
    

    useEffect(() => {
        if(newItems.length > 0) {
            let newComments = [...listComments, ...newItems]
            setListcomments(newComments);
        }

    }, [newItems]);

    const {t} = useTranslation();


    const [showComments, setShowComments] = useState(false);

    const toggleShowComments = () => {
        setShowComments(prevShowComments => !prevShowComments);
    }

    const showLikeComment = (id) => {
        if(!isAuthenticated) {
            navigate('/login');
            return;
        }
        
        setIdCommentShowLikes(id);
    }


    const likeComment = async (id) => {
        if(!isAuthenticated) {
            navigate('/login');
            return;
        }

        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        let newComments = []
        axios.post(`${process.env.REACT_APP_API_URL}/api/comment/${id}/like`, null, config)
            .then (response => {
                listComments.forEach(function callback(value, index) {
                    if(value.id == id) {
                        newComments[index] = response.data; 
                    } else {
                        newComments[index] = value;
                    }
                });

                setListcomments(newComments);
            })
    }

    return (
        <>
        <div className="comments">
            <div className="widget-title" onClick={toggleShowComments}>
                    <h4 style={showComments ? {color: "#f24570"} : {}}>
                        {t('comments')}: ({nbComments}) <i className="icofont-comment"></i>
                        &nbsp;<span style={{float: "right"}}><i className={showComments ? "icofont-circled-up" : "icofont-circled-down"}></i></span>
                    </h4>
            </div>
            {showComments ? (
                <>
                            <ul className="comment-list">
                {listComments.map((val, i) => (
                    <li className="comment" key={`comment${val.id}`} style={{borderBottom: "2px solid rgba(242, 69, 112, 0.3)"}}>
                        <div className="com-content">
                            <div className="com-title">
                                <div className="com-title-meta">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <Link to={`/profile/${val.user.username}`} className="url">
                                                <img src={`${val.user.publicPathCropedProfilePicture}`} alt={`${val.user.username}`} style={{maxWidth: "50px", borderRadius: "50%"}} />
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <h6><Link to={`/profile/${val.user.username}`} className="url">
                                                {getProfileName(val.user.username, val.user.firstName ?? '', val.user.lastName ?? '', true)}
                                                {val.user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }
                                                </Link></h6>
                                            <span>
                                                {val.createdAtInArray.str_val === "now" ? (getHourMinuteOfNow()) : (
                                                    <>{val.createdAtInArray.int_val}&nbsp;{t(val.createdAtInArray.str_val)}</>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {val.media ? (
                                    <MediaActivity media={val.media} maxHeightValue={"200px"} />
                                ) : null}
                            </div>

                            <div>
                                <p style={{
                                 borderRadius: "10px", 
                                 padding: "10px",
                                 margin: "10px 0",
                            }}>{val.content}</p>
                            </div>
                        </div>
                        <div>
                        <p>
                            <a onClick={() => likeComment(val.id)} style={val.likedByCurrentUser ? {cursor: "pointer", color: "#f24570"} : {cursor: "pointer"}}>
                                <i className="icofont-heart-alt" style={{fontSize: "25px"}}></i> 
                            </a>
                            {(() => {
                                    if (val.nbLike > 0){
                                        let likeTxt = `${val.nbLike} ${t('people')}`;
                                        return ( 
                                            <a onClick={() => showLikeComment(val.id)} style={val.likedByCurrentUser ? {cursor: "pointer", color: "#f24570"} : {cursor: "pointer"}}>
                                                <span>&nbsp;&nbsp;{likeTxt}</span>
                                            </a>
                                        )
                                    }
                                })()}

                                {currentUserName === val.user.username ? (<DeleteComment 
                                    idComment={val.id} 
                                    setIdCommentToDelete={setIdCommentToDelete}
                                    setNbComments={setNbComments}
                                />) : null}
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
            {isAuthenticated ? (
                            <>
                            <hr></hr>
                            <div className="comment-respond">
                                <div className="add-comment" style={{minHeight: "100px"}}>
                                { spinnerPostComment ? (<TevifSpinner />) : (
                                    <form className="comment-form" onSubmit={handleSubmitComment}>
                                        {mediaSelected ? (
                                            <div className="row" style={{marginBottom: "10px"}}>
                                                <div className="col-3"></div>
                                                <div className="col-6">
                                                    {mediaSelected === "image" ? (
                                                    <img src={pathMediaSelected} alt="img" />
                                                ) : (
                                                    <video width="100%" controls >
                                                        <source src={pathMediaSelected}  />
                                                    </video>
                                                )}
                                                </div>
                                                <div className="col-3"></div>
                                            </div>
                                        ) : null}
                                        <textarea 
                                            rows="1" 
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={textComment}
                                            onChange={(e)=>{setTextComment(e.target.value);}}
                                            placeholder={t('add_comment')}
                                        ></textarea>
                                        <div className="" style={{marginTop:"5px"}}>
                                        <label style={{cursor: "pointer"}}>
                                            {mediaSelected === "image" ? (
                                                <span style={{color: "#f24570"}}>
                                                    <i className="icofont-image"></i>&nbsp;{t('photo_selected')}
                                                </span>
                                            ) : null}
                
                                            {mediaSelected === "video" ? (
                                                <span style={{color: "#f24570"}}>
                                                    <i className="icofont-video-alt"></i>&nbsp;{t('video_selected')}
                                                </span>
                                            ) : null}
                
                                            {
                                                mediaSelected === "" && (<span><i className="icofont-camera"></i>&nbsp;{t('photo')}/{t('video')}</span>)
                                            }
                                            <input 
                                                type="file" 
                                                name="formFile" 
                                                accept="video/*, image/*" 
                                                style={{display: "none"}}
                                                onChange={fileSelected} 
                                                ref={inputRef}
                                            />
                                        </label>
                                        {mediaSelected ? (
                                            <a onClick={deleteFileSelected} style={{cursor: "pointer"}}>&nbsp;<i className="icofont-trash"></i>&nbsp;{t('cancel')}</a>
                                                
                                        ) : null}
                                        {errorForm ? (
                                            <span style={{"color": "#f24570"}}>&nbsp;{t('the_file_is_too_large_allowed_maximum_size_is_300_mb')}</span>
                                        ) : null}
                                        </div>
                                        <button type="submit" className="lab-btn">
                                            {t('send')}
                                        </button>
                                    </form>
                                )}    
                                </div>
                            </div>
                            </>
            ) : null}
                </>
            ) : null}
        </div>
    </>
    )
}

export default CommentActivity;