import { useEffect } from "react";
import { resumeBanner } from "./adMob";

export default function InitializeAndResumeBanner() {
    useEffect(() => {
		const initializeAndResumeBanner = async () => {
			await resumeBanner();
		};
	
		initializeAndResumeBanner();
	}, []);
}