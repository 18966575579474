import React from 'react';
import { Link } from 'react-router-dom';

const {REACT_APP_FRON_URL} = process.env;

function TextWithUrlDetection ({ text }) {
    if(!text) {
        return null;
    }
    // RegExp pour détecter les URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Fonction pour remplacer les URLs par des liens cliquables
    const detectAndRenderUrls = () => {
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                if (part.includes(REACT_APP_FRON_URL)) {
                    let newPath = part.replace(REACT_APP_FRON_URL, "");
                    return(<Link to={newPath} style={{cursor: "pointer", textDecoration: "underline"}} key={index}>{part}</Link>); 
                } else {
                    return (<a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{cursor: "pointer", textDecoration: "underline"}}>{part}</a>)    
                }
            }
            
            return part;
        });
    };

    return <>{detectAndRenderUrls()}</>;
};

export default TextWithUrlDetection;