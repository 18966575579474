import { Device } from '@capacitor/device';

export async function getDeviceInfo() {
    const id = await Device.getId();
    const info = await Device.getInfo();
    const languageCode = await Device.getLanguageCode();
    const languageTag = await Device.getLanguageTag();

    return {
        id: id.identifier,
        info: JSON.stringify(info),
        languageCode: languageCode.value,
        languageTag: languageTag.value
    };
}


export async function getDeviceId() {
    return await Device.getId();
}