import { useTranslation } from "react-i18next";

function SelectMonth({val, setVal}) {
    const {t} = useTranslation();
    return (
        <select value={val} onChange={(e)=>{setVal(e.target.value);}}>
            <option value="">{t('month')}</option>
            <option value="01">{t('january')}</option>
            <option value="02">{t('february')}</option>
            <option value="03">{t('march')}</option>
            <option value="04">{t('april')}</option>
            <option value="05">{t('may')}</option>
            <option value="06">{t('june')}</option>
            <option value="07">{t('july')}</option>
            <option value="08">{t('august')}</option>
            <option value="09">{t('september')}</option>
            <option value="10">{t('october')}</option>
            <option value="11">{t('november')}</option>
            <option value="12">{t('december')}</option>
        </select>
    );
}
 
export default SelectMonth;