import { Fragment, useState } from "react";
import HeaderTwo from "../component/layout/headertwo";
import { useTranslation } from "react-i18next";
import TevifSpinner from "../component/spinner/tevifSpinner";

const {REACT_APP_API_URL} = process.env;

export default function ForgetPassword () {
    const [forgetEmail, setForgetEmail] = useState("");
    const [formError, setFormError] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const [mailAlreadySent, setMailAlreadySent] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        
        let trimStr = forgetEmail.trim();
        if(trimStr.length < 1 ) {
            return;
        }

        setSpinner(true);
        const bodyRequest = {
            'email': forgetEmail
        };

        fetch(`${REACT_APP_API_URL}/api/public/reset-password`, {
            method: "POST",
            body: JSON.stringify(bodyRequest),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            }).then(
            (response) => (response.json())).then((response)=> {
            if(response.code === 200) {
                setFormError(false);
                setSpinner(false);
                setForgetEmail("");
                setSendSuccess(true);
            } else if (response.code === 409) {
                setMailAlreadySent(true);
                setSpinner(false);
                setSendSuccess(false);
            } else {
                setFormError(true);
                setSpinner(false);
                setSendSuccess(false);
            }
        })
    }

    const {t} = useTranslation();
    return (
        <Fragment>
            <HeaderTwo />
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{t('forgot_password')}</h3>
                        <p>{t('please_enter_your_email_address_to_search_for_your_account')}</p>
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    id="item01"
                                    value={forgetEmail}
                                    onChange={(e)=>{setForgetEmail(e.target.value)}}
                                    placeholder={`${t('email')} *`}
                                />
                            </div>
                            {formError ? (
                                <div className="form-group">
                                <p style={{color: "#f24570"}}>{t('account_not_found')}&nbsp; {t('please_try_another_email_address')}</p>
                            </div>) : null}
                            {mailAlreadySent ? (
                                <div className="form-group">
                                <p style={{color: "#f24570"}}>{t('the_email_is_already_sent')}</p>
                            </div>) : null}
                            {sendSuccess ? (
                                <div className="form-group">
                                <p style={{color: "#4bb543"}}>{t('an_email_has_been_sent_to_you')}</p>
                            </div>) : null}
                            {spinner ? (
                                <TevifSpinner/> 
                            ): null}
                            <div className="form-group mb-2">
                                <button className="d-block lab-btn mb-0"><span>{t('send_password')}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 