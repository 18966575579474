import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import HeaderTwo from "../component/layout/headertwo";
import { getHeadersApiListingUsers } from "../service/User";
import jwtDecode from 'jwt-decode';
import { getItem } from '../service/LocalStorage';
import { Link } from "react-router-dom";
import Search from "./conversation/search";
import Footer from '../component/layout/mobilefooter';
import { useWindowSize } from "../service/Window";
import { useTranslation } from "react-i18next";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import { getProfileName } from "../service/String";

const {REACT_APP_API_URL, REACT_APP_PUSH_SERVER_URL} = process.env;

function ConversationChatRequest() {
    let userToken = getItem('userToken');
    let tToken = jwtDecode(userToken).t_token;
    let currentUserId = null;
    if(userToken) {
        currentUserId = jwtDecode(userToken).user_id;
    }

    let apiHeaders = getHeadersApiListingUsers();

    const [conversations, setConversations] = useState([]);
    const [nbTotalConversations, setNbTotalConversations] = useState(0);
    const [unreadRequestChatConversations, setUnreadRequestChatConversations] = useState("0");

    const [page,setPage] = useState(2);
    const [spinner, setSpinner] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [width, height] = useWindowSize();

    const limit = 20;

    const fetchLiveConversations = (showSpinner, resetPage) => {  
        try {
            if(showSpinner) {
                setSpinner(true);
            }
            
            fetch(
                `${REACT_APP_API_URL}/api/conversations?order_field=updateDate&order_direction=DESC&chat_request=true&page=1&limit=${limit}`,
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            .then(response => response.json())
            .then((data) => {
                setSpinner(false);
                if(data.items) {
                    setConversations(data.items);
                }
                
                setNbTotalConversations(data.totalItems);
                if(resetPage) {
                    setPage(2);
                    setHasMore(true);
                }

                if (data && data.parameters && data.parameters.unreadRequestChatConversations) {
                    setUnreadRequestChatConversations(data.parameters.unreadRequestChatConversations);
                }
            })

        } catch (err) {}
      }

    useEffect(() => {      
        fetchLiveConversations(true, false);

        let subscribeURL = new URL(`${REACT_APP_PUSH_SERVER_URL}`);
        subscribeURL.searchParams.append("topic", 'topic/conversations/'+tToken);        

        let source = new EventSource(subscribeURL.href);
        
        source.addEventListener('open', () => {
        });
        source.addEventListener('message', (e) => {
            fetchLiveConversations(false, true);
        });
    
        source.addEventListener('error', (e) => {

        });

        return () => {
            source.close();
        };
    }, []);

    const getConversationsInfinityPagination = () => {
        setSpinner(true);
        fetch(`${REACT_APP_API_URL}/api/conversations?order_field=updateDate&order_direction=DESC&chat_request=true&page=${page}&limit=${limit}`, {
                method: "GET",
                headers: { 
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                }
            })
            .then(response => response.json())
            .then((result) => {
                if(result.items.length > 0) {
                    setConversations((oldItems) => [...oldItems, ...result.items]);
                    setPage(page + 1);
                }

                if(result.items.length < limit) {
                    setHasMore(false);
                }

                setSpinner(false);
            })
        ;
    }

    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (spinner) {
            return
        };
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
            getConversationsInfinityPagination();
          }
        })
        if (node) observer.current.observe(node)
      }, [spinner, hasMore, page]);

    const {t} = useTranslation();

    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    return (
        <Fragment>
            <HeaderTwo />
            <InitializeAndResumeBanner />
            <div className="shop-single padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-xl-8 col-12">
                            <article>
                                <div className="review">
                                    <ul className="review-nav lab-ul DescActive">
                                        <Link to="/conversations"><li className="" style={{marginBottom: "5px"}}>{t('main')}</li></Link>&ensp;
                                        <Link to="/conversations/chat-requests"><li className="desc" style={{backgroundColor: "#f689b4"}}>{t('invitation')} {unreadRequestChatConversations ? (<>({unreadRequestChatConversations})</>) : null}</li></Link>
                                    </ul>
                                    
                                    <div className="review-content description-show">
                                        {/* debut conversation */}
                                        <div className="description widget widget-post">
                                            <ul className="lab-ul widget-wrapper">
                                                {conversations.map((item, i) => {
                                                    if(conversations.length === i + 1) {
                                                        return (
                                                            <Link to={`/conversations/${item.otherParticipant.username}`} key={`link_${i}`}>
                                                                <li ref={lastElementRef} className="d-flex flex-wrap" key={`conversaation_${item.id}`} style={{cursor: "pointer"}}>
                                                                    <div className="post-thumb">
                                                                        <img 
                                                                            src={item.otherParticipant.publicPathCropedProfilePicture} 
                                                                            alt={item.otherParticipant.username} 
                                                                            className="rounded-circle"
                                                                        />
                                                                    </div>
                                                                    <div className="post-content ps-4" style={{wordWrap: "break-word", overflowWrap: "break-word"}}>
                                                                        {item.nbNewMessages > 0 ? (
                                                                            <>
                                                                                <h6 style={{color: "#f24570"}}>
                                                                                    {getProfileName(item.otherParticipant.username, item.otherParticipant.firstName ?? '', item.otherParticipant.lastName ?? '', true)}
                                                                                    {item.otherParticipant.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }&nbsp;<span style={{color: "#f24570"}}>{item.nbNewMessages}&nbsp;<i className="icofont-envelope"></i></span></h6>
                                                                                <p style={{color: "#f24570"}}>
                                                                                {item.lastMessagesDataArray.content ? (
                                                                                    <>
                                                                                        {item.lastMessagesDataArray.sender ? (<>{t(item.lastMessagesDataArray.sender)}&nbsp;:&nbsp;</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift === true ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift !== true  ? (<>{item.lastMessagesDataArray.content}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "video" || item.lastMessagesDataArray.type === "photo" ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        &nbsp;({item.lastMessagesDataArray.time.int_val}&nbsp;{t(item.lastMessagesDataArray.time.str_val)})
                                                                                    </>
                                                                                ) : null}
                                                                                </p>
                                                                            </>
                                                                                                                                                        
                                                                        ): (
                                                                            <>
                                                                                <h6>
                                                                                    {getProfileName(item.otherParticipant.username, item.otherParticipant.firstName ?? '', item.otherParticipant.lastName ?? '', true)}
                                                                                    {item.otherParticipant.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }</h6>
                                                                                <p className="conversation-list-p">
                                                                                {item.lastMessagesDataArray.content ? (
                                                                                    <>
                                                                                        {item.lastMessagesDataArray.sender ? (<>{t(item.lastMessagesDataArray.sender)}&nbsp;:&nbsp;</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift === true ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift !== true  ? (<>{item.lastMessagesDataArray.content}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "video" || item.lastMessagesDataArray.type === "photo" ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        &nbsp;({item.lastMessagesDataArray.time.int_val}&nbsp;{t(item.lastMessagesDataArray.time.str_val)})
                                                                                    </>
                                                                                ) : null}
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </li>
                                                            </Link>
                                                        )
                                                    } else {
                                                        return (
                                                            <Link to={`/conversations/${item.otherParticipant.username}`} key={`link_${i}`}>
                                                            <li className="d-flex flex-wrap" key={`conversaation_${item.id}`} style={{cursor: "pointer"}}>
                                                                <div className="post-thumb">
                                                                    <img 
                                                                        src={item.otherParticipant.publicPathCropedProfilePicture} 
                                                                        alt={item.otherParticipant.username} 
                                                                        className="rounded-circle"
                                                                    />
                                                                </div>
                                                                <div className="post-content ps-4" style={{wordWrap: "break-word", overflowWrap: "break-word"}}>
                                                                    {item.nbNewMessages > 0 ? (
                                                                        <>
                                                                            <h6 style={{color: "#f24570"}}>
                                                                                {getProfileName(item.otherParticipant.username, item.otherParticipant.firstName ?? '', item.otherParticipant.lastName ?? '', true)}
                                                                                {item.otherParticipant.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }&nbsp;<span style={{color: "#f24570"}}>{item.nbNewMessages}&nbsp;<i className="icofont-envelope"></i></span></h6>
                                                                            <p style={{color: "#f24570"}}>
                                                                                
                                                                            {item.lastMessagesDataArray.content ? (
                                                                                    <>
                                                                                        {item.lastMessagesDataArray.sender ? (<>{t(item.lastMessagesDataArray.sender)}&nbsp;:&nbsp;</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift === true ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift !== true  ? (<>{item.lastMessagesDataArray.content}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "video" || item.lastMessagesDataArray.type === "photo" ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        &nbsp;({item.lastMessagesDataArray.time.int_val}&nbsp;{t(item.lastMessagesDataArray.time.str_val)})
                                                                                    </>
                                                                                ) : null}
                                                                            </p>
                                                                        </>
                                                                                                                                                    
                                                                    ): (
                                                                        <>
                                                                            <h6>
                                                                                {getProfileName(item.otherParticipant.username, item.otherParticipant.firstName ?? '', item.otherParticipant.lastName ?? '', true)}
                                                                                {item.otherParticipant.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }</h6>
                                                                            <p className="conversation-list-p">
                                                                            {item.lastMessagesDataArray.content ? (
                                                                                    <>
                                                                                        {item.lastMessagesDataArray.sender ? (<>{t(item.lastMessagesDataArray.sender)}&nbsp;:&nbsp;</>) : null}
                                                                                        
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift === true ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "text" && item.lastMessagesDataArray.isGift !== true  ? (<>{item.lastMessagesDataArray.content}</>) : null}
                                                                                        {item.lastMessagesDataArray.type === "video" || item.lastMessagesDataArray.type === "photo" ? (<>{t(item.lastMessagesDataArray.content)}</>) : null}
                                                                                        &nbsp;({item.lastMessagesDataArray.time.int_val}&nbsp;{t(item.lastMessagesDataArray.time.str_val)})
                                                                                    </>
                                                                                ) : null}
                                                                            </p>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        </Link>
                                                        )
                                                    }
                                                })}
                                            </ul>

                                            {spinner ? (
                                                <TevifSpinner/> 
                                            ) : null}
                                        </div>

                                        {/* Fin conversation */}
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );

}
 
export default ConversationChatRequest;