import { useState, useContext } from "react";
import { getItem } from '../../service/LocalStorage';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const {REACT_APP_API_URL} = process.env;

function DeleteMedia({
    idMedia, 
    medias, 
    setMedias, 
    nbMedias, 
    setNbMedias, 
    totalMedia, 
    setTotalMedia
}) {
    let userToken = getItem('userToken')

    const [idToDelete, setIdToDelete] = useState(0);
    const [deleteSpinner, setDeleteSpinner] = useState(false);

    const changeIdToDelete = async (id) => {
        setIdToDelete(id)
    }

    const cancelDelete = async () => {
        setIdToDelete(0)
    }

    const confirmDelete = async (id) => {
        setDeleteSpinner(true)

        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        axios.delete(`${REACT_APP_API_URL}/api/medias/${idMedia}/delete`, config)
            .then (response => {
                if(response.status == 204) {
                    let newMedias = medias.filter(data => data.id != id);
                    setMedias(newMedias)
                    let newNbMedia = nbMedias - 1
                    setNbMedias(newNbMedia)
                    let newTotalMedia = totalMedia - 1
                    setTotalMedia(newTotalMedia)
                }
            }).catch(err => {

            })

            setIdToDelete(0)
            setDeleteSpinner(false)
    }

    const {t} = useTranslation();

        return (

            <div className="post-meta">
                <div className="post-meta-top">
                    {
                        deleteSpinner == false && idToDelete == 0 ? (
                            <p>
                                <a style={{cursor: "pointer"}} onClick={() => changeIdToDelete(idMedia)}>
                                    <i className="icofont-trash"></i> <span>{t('delete')}</span>
                                </a>
                            </p>
                        ) : (
                            <p>
                            <a  
                                style={{
                                    cursor: "pointer",
                                    color: "#f24570",
                                    fontWeight: "bold"
                                }}
                                onClick={() => confirmDelete(idMedia)}
                            >
                                &nbsp;{t('confirm')}&nbsp;
                            </a>
                            <a  style={{
                                    cursor: "pointer",
                                    color: "#f2aaaa",
                                    fontWeight: "bold"
                                }}
                                onClick={() => cancelDelete()}
                            >
                                &nbsp;{t('cancel')}&nbsp;
                            </a>
                        </p>
                        )
                    }
                </div>
            </div>
        );
}

export default DeleteMedia;