import { useState } from "react";
import { getHeadersApiListingUsers } from "../../service/User";
import { useTranslation } from "react-i18next";

const {REACT_APP_API_URL} = process.env;

export default function Search({
    setConversations,
    setSpinner,
    setPage, 
    setHasMore
}) {

    let apiHeaders = getHeadersApiListingUsers();

    const [search, setSearch] = useState("");


    const searchConversation = (e) => {
        let searchValue = e.target.value;
        let trimValue = searchValue.trim();
        setSearch(e.target.value);

        if(searchValue.length === 0) {
            setSpinner(true);
            fetch(
                `${REACT_APP_API_URL}/api/conversations?limit=20`,
                {
                    method: "GET",
                    headers: apiHeaders
                }
            )
            .then(response => response.json())
            .then(data => {
                if(data.items.length > 0) {
                    setConversations(data.items);
                    setPage(2);
                    setHasMore(true);
                } 

                setSpinner(false);
            });
        }

        if(!trimValue) {
            return;
        }


        setSpinner(true);
        fetch(
            `${REACT_APP_API_URL}/api/conversations/search?term=${searchValue}`,
            {
                method: "GET",
                headers: apiHeaders
            }
        )
        .then(response => response.json())
        .then(data => {
            setConversations(data.items);
            setSpinner(false);
        });    
    };


    
    const handleSubmit = async event => {
        event.preventDefault();
        return;
    }

    const {t} = useTranslation();
    return (
        <div className="widget widget-search" style={{boxShadow: "0px 0px 0px 0px #8888881a"}}>
            <div className="widget-header">
                <h5>{t('search_in_conversations')}</h5>
            </div>
            <form className="search-wrapper" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="search"
                    id="item01"
                    value={search}
                    onChange={searchConversation}
                    placeholder={`${t('username')} ...`}
                />
                <button type="submit"><i className="icofont-search-2"></i></button>
            </form>
        </div>
    );
}
 